import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../constants';
import { ServerError, serverErrorSchema } from '../../schema';

import { RegisterUserDTO, User } from 'unity-types';
import { registerUserDTOSchema, userSchema } from 'unity-types/schema';

interface RegisterState {
  status: AsyncStatus;
  error: string | null;
  newUser: User | null;
}

const initialState: RegisterState = {
  status: AsyncStatus.Idle,
  error: null,
  newUser: null
};

export const registerNewUser = createAsyncThunk<User, RegisterUserDTO, { rejectValue: ServerError }>(
  'users/register', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: RegisterUserDTO = registerUserDTOSchema.parse(payload);
      const response = await axios.post('/api/shared/users', dto);
      const user: User = userSchema.parse(response.data);
      return user;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const registerSlice = createSlice({
  name: 'register',
  initialState: initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(registerNewUser.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(registerNewUser.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.newUser = action.payload;
      })
      .addCase(registerNewUser.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
  }
});

export const { clearState } = registerSlice.actions;

export default registerSlice.reducer;
