import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../constants';
import { ServerError, serverErrorSchema } from '../../schema';

import {
  RequestResetPasswordDTO,
  SubmitResetPasswordDTO,
  GenericResponse,
} from 'unity-types';

import {
  requestResetPasswordDTOSchema,
  submitResetPasswordDTOSchema,
  genericResponseSchema,
} from 'unity-types/schema'

interface UserState {
  status: AsyncStatus;
  error: string | null;
}

const initialState: UserState = {
  status: AsyncStatus.Idle,
  error: null,
};

export const sendResetPasswordRequest = createAsyncThunk<GenericResponse, RequestResetPasswordDTO, { rejectValue: ServerError }>(
  'users/sendResetPasswordRequest', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: RequestResetPasswordDTO = requestResetPasswordDTOSchema.parse(payload);
      const response = await axios.post('/api/shared/users/current/password-reset/', dto);
      const genericResponse: GenericResponse = genericResponseSchema.parse(response.data);
      return genericResponse;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const submitResetPassword = createAsyncThunk<GenericResponse, SubmitResetPasswordDTO, { rejectValue: ServerError }>(
  'users/submitResetPassword', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: SubmitResetPasswordDTO = submitResetPasswordDTOSchema.parse(payload);
      const response = await axios.put(`/api/shared/users/password`, dto);
      const genericResponse: GenericResponse = genericResponseSchema.parse(response.data);
      return genericResponse;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(sendResetPasswordRequest.pending, (state, action) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(sendResetPasswordRequest.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
      })
      .addCase(sendResetPasswordRequest.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(submitResetPassword.pending, (state, action) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(submitResetPassword.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
      })
      .addCase(submitResetPassword.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
  }
});

export const { 
  clearState,
} = usersSlice.actions;

export default usersSlice.reducer;