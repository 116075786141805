import { useStyletron } from 'baseui';
import { font_extra_lg, font_lg, font_med, font_sm, SIZE } from '../../../shared/styles';

export type TitleProps = {
  title: string;
  size?: SIZE;
  color?: string;
  overrides?: any;
}

const Title = ({ title, size = SIZE.EXTRA_LARGE, color = '', overrides = {} }: TitleProps) => {
  const [css, $theme] = useStyletron();
  if(color === '') color = $theme.colors.white;

  const sizeToFont = {
    [SIZE.SMALL]: font_sm,
    [SIZE.MEDIUM]: font_med,
    [SIZE.LARGE]: font_lg,
    [SIZE.EXTRA_LARGE]: font_extra_lg
  }
  const font = sizeToFont[size];

  return (
    <div className={css({ ...font, color: color, textAlign: 'left', ...overrides })}>{title}</div>
  );
}

export default Title;
