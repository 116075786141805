"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminPortalUserFilterSchema = exports.adminPortalUserSchema = exports.digPortalUserFilterSchema = exports.digPortalUserSchema = exports.reentryPortalUserFilterSchema = exports.reentryPortalUserSchema = exports.submitResetPasswordDTOSchema = exports.requestResetPasswordDTOSchema = exports.assignRoleDTOSchema = exports.updateUserDTOSchema = exports.registerUserDTOSchema = exports.unityUserQueryFilterSchema = exports.unityUserSchema = exports.userPermissionSchema = exports.userRoleSchema = exports.roleSchema = exports.userSchema = void 0;
const zod_1 = require("zod");
const constants_js_1 = require("../constants.js");
exports.userSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    password: zod_1.z.string(),
    username: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    agencyId: zod_1.z.coerce.string().nullable(),
}).strict();
exports.roleSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    name: zod_1.z.string(),
}).strict();
exports.userRoleSchema = zod_1.z.object({
    userId: zod_1.z.coerce.string(),
    roleId: zod_1.z.coerce.string(),
}).strict();
exports.userPermissionSchema = zod_1.z.object({
    userId: zod_1.z.coerce.string(),
    permissionSet: zod_1.z.string(),
    isActive: zod_1.z.boolean(),
}).strict();
exports.unityUserSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    fullName: zod_1.z.string(),
    username: zod_1.z.string(),
    agencyId: zod_1.z.coerce.string().nullable(),
    agencyName: zod_1.z.string().nullable(),
    roles: zod_1.z.array(constants_js_1.portalNameSchema),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
}).strict();
exports.unityUserQueryFilterSchema = exports.unityUserSchema.partial().omit({ roles: true });
exports.registerUserDTOSchema = zod_1.z.object({
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    agencyId: zod_1.z.string(),
    username: zod_1.z.string(),
    password: zod_1.z.string(),
}).strict();
exports.updateUserDTOSchema = exports.userSchema.pick({
    password: true,
    agencyId: true,
}).partial().strict();
exports.assignRoleDTOSchema = zod_1.z.object({
    portalId: zod_1.z.coerce.string(),
}).strict();
exports.requestResetPasswordDTOSchema = zod_1.z.object({
    username: zod_1.z.string(),
}).strict();
exports.submitResetPasswordDTOSchema = zod_1.z.object({
    username: zod_1.z.string(),
    secret: zod_1.z.string(),
    newPassword: zod_1.z.string(),
}).strict();
exports.reentryPortalUserSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    username: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    fullName: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    agencyId: zod_1.z.coerce.string(),
    agencyName: zod_1.z.string(),
    assignedClients: zod_1.z.number(),
    createdPayments: zod_1.z.number(),
    permissionSet: zod_1.z.string().nullable(),
    isActive: zod_1.z.boolean(),
}).strict();
exports.reentryPortalUserFilterSchema = exports.reentryPortalUserSchema.partial();
exports.digPortalUserSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    username: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    fullName: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    agencyId: zod_1.z.coerce.string(),
    agencyName: zod_1.z.string(),
    assignedClients: zod_1.z.number(),
    createdPayments: zod_1.z.number(),
    permissionSet: zod_1.z.string().nullable(),
    isActive: zod_1.z.boolean(),
}).strict();
exports.digPortalUserFilterSchema = exports.digPortalUserSchema.partial();
exports.adminPortalUserSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    username: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    fullName: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    agencyId: zod_1.z.coerce.string(),
    agencyName: zod_1.z.string(),
    permissionSet: zod_1.z.string().nullable(),
    isActive: zod_1.z.boolean(),
}).strict();
exports.adminPortalUserFilterSchema = exports.adminPortalUserSchema.partial();
