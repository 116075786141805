import { withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';

const ExtraLargeSpinner = withStyle(Spinner, ({ $theme }) => ({
  width: '96px',
  height: '96px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: $theme.colors.accent,
}));

export default ExtraLargeSpinner;

export const TinySpinner = withStyle(Spinner, ({ $theme }) => ({
  width: '20px',
  height: '20px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: $theme.colors.grey,
}));

export const SmallSpinner = withStyle(Spinner, ({ $theme }) => ({
  width: '49px',
  height: '49px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: $theme.colors.accent,
}));

export const MediumSpinner = withStyle(Spinner, ({ $theme }) => ({
  width: '60px',
  height: '49px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: $theme.colors.accent,
}));