import { AuthenticatedUser } from "unity-types";

export const getUserDetailPageUrl = (recordId: string): string => '/admin-portal/users/detail/' + recordId;

export const formatDateString = (str: string): string => {
  const dateObj = new Date(str);
  return dateObj.toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'});
}

export const createDownloadLink = (csv: string): string  => {
  const blob = new Blob([csv], { type: 'text/csv' });
  return URL.createObjectURL(blob);
}

export const hasPortalAccess = (authenticatedUser: AuthenticatedUser | null): boolean => {

  // check authenticated user exists
  if(authenticatedUser === null) return false;

  // check if user has admin portal role
  if(authenticatedUser.admin_portal === undefined) return false;
  
  // check if user has active admin portal role
  if(authenticatedUser.admin_portal.isActive === false) return false;

  return true;
}
