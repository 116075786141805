import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { BlankContainer } from '../../../shared/components/layout/StyledContainer';
import { ClientList } from '../lists/clients/ClientList';
import { UserList } from '../lists/users/UserList';
import StandAlonePageContainer from '../../../shared/components/layout/StandAlonePageContainer';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import MyUserDashboard from '../dashboards/MyUserDashboard';
import AgencyDashboard from '../dashboards/AgencyDashboard';
import SlotsDashboard from '../dashboards/SlotsDashboard';
import ExtraLargeSpinner from '../../../shared/components/elements/Spinner';
import { type DigPortalUser, type DigAgencyExtendedInfo } from 'unity-types';

const HomePage = () => {
  const myUser: DigPortalUser | null = useSelector(state => state.dig_portal.users.myUser);
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allUsers: DigPortalUser[] | null = useSelector(state => state.dig_portal.users.allUsers);

  return (
    <BlankContainer>
      <StandAlonePageContainer>
        {
          (myUser && userAgency && allUsers) ?
          <FlexGridItem>
            <FlexGrid overrides={{ justifyContent: 'space-between' }}>
              <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-start', width: '560px' }} >
                <MyUserDashboard />
                <AgencyDashboard />
                <FlexGrid>
                  <UserList />
                  <SlotsDashboard />
                </FlexGrid>
              </FlexGrid>
              <FlexGridItem overrides={{ width: `${window.innerWidth - 600}px` }} >
                <ClientList />
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem> : <ExtraLargeSpinner/>
        }
      </StandAlonePageContainer>
    </BlankContainer>
  );

}

export default HomePage;
