"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserPermissionsDTOSchema = exports.assignPermissionSetDTOSchema = exports.getAllPermissionsDTOSchema = exports.assignPermissionDTOSchema = exports.createPermissionSetDTOSchema = exports.createPermissionDTOSchema = exports.permissionSetsExtendedInfoSchema = exports.permissionSetAssignmentSchema = exports.permissionSetSchema = exports.permissionSchema = void 0;
const zod_1 = require("zod");
const constants_js_1 = require("../constants.js");
exports.permissionSchema = zod_1.z.object({
    name: zod_1.z.string(),
}).strict();
exports.permissionSetSchema = zod_1.z.object({
    name: zod_1.z.string(),
}).strict();
exports.permissionSetAssignmentSchema = zod_1.z.object({
    permission: zod_1.z.string(),
    permissionSet: zod_1.z.string(),
}).strict();
exports.permissionSetsExtendedInfoSchema = zod_1.z.object({
    permissionSet: zod_1.z.string(),
    permissions: zod_1.z.array(zod_1.z.string()),
}).strict();
exports.createPermissionDTOSchema = zod_1.z.object({
    name: zod_1.z.string(),
    schema: constants_js_1.portalNameSchema,
}).strict();
exports.createPermissionSetDTOSchema = zod_1.z.object({
    name: zod_1.z.string(),
    schema: constants_js_1.portalNameSchema,
}).strict();
exports.assignPermissionDTOSchema = zod_1.z.object({
    permission: zod_1.z.string(),
    permissionSet: zod_1.z.string(),
    schema: constants_js_1.portalNameSchema,
}).strict();
exports.getAllPermissionsDTOSchema = zod_1.z.object({
    schema: constants_js_1.portalNameSchema,
}).strict();
exports.assignPermissionSetDTOSchema = zod_1.z.object({
    schema: constants_js_1.portalNameSchema,
    permissionSet: zod_1.z.string(),
    userId: zod_1.z.coerce.string(),
}).strict();
exports.updateUserPermissionsDTOSchema = zod_1.z.object({
    permissionSet: zod_1.z.string().optional(),
    isActive: zod_1.z.boolean().optional(),
}).strict();
