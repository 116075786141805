"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePaymentDTOSchema = exports.createRefundDTOSchema = exports.createPaymentDTOSchema = exports.paymentQueryFilterSchema = exports.paymentSchema = void 0;
const zod_1 = require("zod");
exports.paymentSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    paymentCode: zod_1.z.coerce.string(),
    category: zod_1.z.string(),
    type: zod_1.z.string(),
    clientId: zod_1.z.coerce.string(),
    agencyId: zod_1.z.coerce.string(),
    datePaid: zod_1.z.coerce.date().transform(d => d.toISOString()),
    notes: zod_1.z.string().nullable(),
    refundPaymentId: zod_1.z.coerce.string().nullable(),
    originalPaymentId: zod_1.z.coerce.string().nullable(),
    createdByUserId: zod_1.z.coerce.string(),
    amount: zod_1.z.number(),
    payeeVendorId: zod_1.z.coerce.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    clientName: zod_1.z.string(),
    payeeName: zod_1.z.string(),
    receiptAttachmentId: zod_1.z.coerce.string().nullable().optional(),
}).strict();
exports.paymentQueryFilterSchema = exports.paymentSchema.partial();
exports.createPaymentDTOSchema = zod_1.z.object({
    amount: zod_1.z.number(),
    category: zod_1.z.string(),
    clientId: zod_1.z.coerce.string(),
    vendorId: zod_1.z.coerce.string(),
    notes: zod_1.z.string().optional(),
    receiptAttachmentId: zod_1.z.coerce.string().optional(),
}).strict();
exports.createRefundDTOSchema = zod_1.z.object({
    originalPaymentId: zod_1.z.coerce.string(),
    notes: zod_1.z.string(),
}).strict();
exports.updatePaymentDTOSchema = zod_1.z.object({
    refundPaymentId: zod_1.z.coerce.string(),
    receiptAttachmentId: zod_1.z.coerce.string(),
}).strict().partial();
