import { useState, useEffect } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { createSession, clearState, submitLogout } from '../../slices/auth/auth.slice';
import { sendResetPasswordRequest, clearState as clearUserState } from '../../../shared/slices/user/user.slice';
import { AsyncStatus, FieldValidationMessage } from '../../constants';
import { notification_font, font_sm_giant } from '../../styles'
import { RotatingImageStyledContainer } from '../layout/StyledContainer';
import Input from '../elements/Input';
import Button, { WarningButton, LargeButton } from '../elements/Button';
import Card from '../elements/Card';
import { SmallWhiteLogo } from '../elements/Logo';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import { ResetPasswordModal, NotificationModal } from '../elements/Modal';
import { type AuthenticatedUser } from 'unity-types';

const LoginPage =  () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitLoginStatus: AsyncStatus = useSelector(state => state.shared.auth.submitLoginStatus);
  const submitLoginError: string | null = useSelector(state => state.shared.auth.submitLoginError);
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const resetPasswordStatus: AsyncStatus = useSelector(state => state.shared.users.status);
  const resetPasswordError: string | null = useSelector(state => state.shared.users.error);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<string>();
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] = useState(false);
  const [noUserRoles, setNoUserRoles] = useState(false);

  useEffect(() => {
    if (authenticatedUser) {
      const hasReentryPortalRole: boolean = authenticatedUser.reentry_portal !== undefined;
      const canAccessReentryPortal: boolean = !hasReentryPortalRole ? false : authenticatedUser.reentry_portal!.isActive;
      const hasDigPortalRole: boolean = authenticatedUser.dig_portal !== undefined;
      const canAccessDigPortal: boolean = !hasDigPortalRole ? false : authenticatedUser.dig_portal!.isActive;
      const hasAdminPortalRole: boolean = authenticatedUser.admin_portal !== undefined;
      const canAccessAdminPortal: boolean = !hasAdminPortalRole ? false : authenticatedUser.admin_portal!.isActive;

      // If the user has access to multiple portals, redirect to the master unity home page
      const hasMultiplePortals = (canAccessReentryPortal && canAccessAdminPortal) || (canAccessReentryPortal && canAccessDigPortal) || (canAccessAdminPortal && canAccessDigPortal);
      if(hasMultiplePortals) return navigate('/');

      // If the user can access the reentry portal, redirect to the reentry portal
      if(canAccessReentryPortal) return navigate('/reentry-portal');

      // If the user can access the dig portal, redirect to the dig portal
      if(canAccessDigPortal) return navigate('/dig-portal');

      // If the user can access the admin portal, redirect to the admin portal
      if(canAccessAdminPortal) return navigate('/admin-portal');

      // Otherwise the user does not have a role for any portal
      setNoUserRoles(true);
    }
  }, [authenticatedUser, navigate]);

  const validateAndSubmit = () => {
    if([username, password].includes('')) {
      return setValidationError(FieldValidationMessage.AllFieldsRequired);
    }
    dispatch(createSession({ username, password }));
  }

  return (
    <RotatingImageStyledContainer fullscreen={true}>
      <FlexGridItem overrides={{ marginTop: '20px' }}>
        <Card overrides={{ backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderColor: $theme.colors.white, width: '600px', padding: '10px', height: 'auto' }}>
          <FlexGrid>
            <SmallWhiteLogo />
            <FlexGridItem overrides={{ textAlign: 'right', alignItems: 'center' }}>
              <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Welcome to</div>
              <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Unity</div>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem>
            <FlexGridItem overrides={{ marginTop: '40px', width: '400px' }}>
              <Input value={username} label={'Username'} onChange={e => setUsername(e.target.value)} />
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '400px' }}>
              <Input value={password} label={'Password'} onChange={e => setPassword(e.target.value)} type={'password'}/>
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '400px', marginTop: '10px' }}>
              <LargeButton onClick={() => validateAndSubmit()} label={'Sign In'} isLoading={submitLoginStatus === AsyncStatus.Loading} disabled={validationError != null}/>
            </FlexGridItem>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '300px', marginTop: '10px' }}>
              <WarningButton onClick={() => setResetPasswordModalIsOpen(true)} label={'Forgot Password'} isLoading={resetPasswordStatus === AsyncStatus.Loading}/>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '300px', justifyContent: 'right', marginTop: '90px'}}>
              <Button onClick={() => navigate('/register')} label={'Register for an account'} />
            </FlexGridItem>
          </FlexGrid>
        </Card>
      </FlexGridItem>
      <NotificationModal 
        isOpen={noUserRoles} 
        message={`You're account does not have access to any portals. Please contact your administrator for assistance.`} 
        onClose={() => {
          dispatch(submitLogout());
          setUsername('');
          setPassword('');
          setNoUserRoles(false);
        }}
      />
      <NotificationModal 
        isOpen={resetPasswordStatus === AsyncStatus.Succeeded} 
        message={`We've sent you an email to reset your password. Please check your inbox and follow the instructions provided.`} 
        onClose={() => dispatch(clearUserState())}
      />
      <NotificationModal 
        isOpen={!!validationError} 
        message={validationError} 
        onClose={() => setValidationError(undefined)}
      />
      <NotificationModal 
        isOpen={!!resetPasswordError} 
        message={resetPasswordError} 
        onClose={() => dispatch(clearUserState())}
      />
      <NotificationModal 
        isOpen={!!submitLoginError} 
        message={submitLoginError} 
        onClose={() => {
          dispatch(clearState());
          setPassword('');
          setUsername('');
        }}
      />
      <ResetPasswordModal 
        isOpen={resetPasswordModalIsOpen} 
        setIsOpen={setResetPasswordModalIsOpen} 
        onConfirmPasswordReset={(value) => dispatch(sendResetPasswordRequest( { username: value }))} 
      />
    </RotatingImageStyledContainer>
  );
}

export default LoginPage;
