import { Badge, SHAPE } from 'baseui/badge';
import { Delete } from "baseui/icon";
import { useStyletron } from 'baseui';
import { font_med } from '../../../shared/styles';

export type CustomBadgeProps = {
  content: React.ReactNode,
  onDeleteClick?: () => void
}

const CustomBadge = ({ content, onDeleteClick = () => {} }: CustomBadgeProps) => {
  const [css, $theme] = useStyletron();

  return (
    <Badge
      content={
        <div className={css({ display: 'flex' })}>
          {
          // @ts-ignore can remove once baseui supports React 18
          <Delete className={css({ color: $theme.colors.accent, ':hover': { color: $theme.colors.black } })} size={38} onClick={onDeleteClick}/>
          }
          <div className={css({ lineHeight: '38px', marginLeft: '10px' })}>{content}</div>
        </div>
      }
      shape={SHAPE.pill}
      overrides={{
        Badge: {
          style: () => ({
            ...font_med,
            color: $theme.colors.accent,
            backgroundColor: $theme.colors.white,
            margin: '10px 10px 0px 10px',
            padding: '0px',
            height: 'auto'
          })
        }
      }}
    />
  );
}

export default CustomBadge;

export type BasicBadgeProps = {
  text: string,
  backgroundColor: string,
  color: string,
  overrides?: any
}

export const BasicBadge = ({ text, backgroundColor, color, overrides = {} }: BasicBadgeProps) => {
  const [css] = useStyletron();

  return (
    <Badge
    content={
      <div className={css({ display: 'flex' })}>
        <div className={css({  })}>{text}</div>
      </div>
    }
      shape={SHAPE.pill}
      overrides={{
        Badge: {
          style: () => ({
            ...font_med,
            color: color,
            backgroundColor: backgroundColor,
            ...overrides
          })
        }
      }}
    />
  );
}