import React from "react";
import { useStyletron } from "baseui";
import InputMask from "@mona-health/react-input-mask";
import { FormControl } from 'baseui/form-control';
import { input_font, font_med, font_extra_sm } from '../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';

type MaskedInputProps = {
  mask: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  label: string,
  caption?: string | null,
  error?: string | null
}

const CustomMaskedInput = ({ mask, value, onChange, onBlur = () => {}, label, caption = null, error = null }: MaskedInputProps) => {
  const [css, $theme] = useStyletron();
  const errorStyles = error ? { borderStyle: 'solid', borderColor: $theme.colors.negative, borderWidth: '3px' } : {};
  const divErrorStyles = error ? { borderStyle: 'none' } : {};

  return (
    // @ts-ignore can remove once baseui supports React 18
    <FormControl 
      label={label}
      caption={
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          { (caption || error) && <FlexGridItem>{caption}</FlexGridItem> }
          { error && <FlexGridItem overrides={{ backgroundColor: $theme.colors.negative, color: $theme.colors.white, padding: '2px', borderRadius: '10px' }}>{error}</FlexGridItem> }
        </FlexGrid>
      }
      overrides={{
        Label: { style: () => ({ ...font_med, color: $theme.colors.white }) },
        Caption: { style: () => ({ ...font_extra_sm, color: $theme.colors.white }) },
        ControlContainer: { style: () => ({ marginBottom: '0px' }) }
      }}
    >
      <div
        className={css({
          boxSizing: 'border-box',
          display: 'flex',
          overflow: 'hidden',
          width: '100%',
          borderWidth: '2px',
          borderStyle: 'solid',
          transitionProperty: 'border',
          transitionDuration: '200ms',
          transitionTimingFunction: 'cubic-bezier(.2, .8, .4, 1)',
          borderRadius: '8px',
          lineHeight: '20px',
          borderColor: '#EEEEEE',
          backgroundColor: '#EEEEEE',
          paddingLeft: '0px',
          paddingRight: '0px',
          ...divErrorStyles
        })}
      >
        <div 
          className={css({
            display: 'flex',
            width: '100%',
            transitionProperty: 'background-color',
            transitionDuration: '200ms',
            transitionTimingFunction: 'cubic-bezier(.2, .8, .4, 1)',
            color: '#000000',
            backgroundColor: '#EEEEEE',
          })}
        >
          <InputMask
            mask={mask}
            maskPlaceholder={''}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            className={css({ 
              boxSizing: 'border-box',
              backgroundColor: '#FFFFFF',
              borderWidth: '0px',
              borderStyle: 'none',
              outline: 'none',
              width: '100%',
              padding: '6px 14px 6px 14px',
              borderRadius: '8px',
              ...input_font,
              ...errorStyles
            })}
          />
        </div>
      </div>
    </FormControl>
  );
}

export default CustomMaskedInput;