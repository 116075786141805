import { useStyletron } from 'baseui';
import { DeleteAlt } from 'baseui/icon';
import { ICON_QUESTION_MARK_URL } from '../../constants';

export const DeleteIcon = ({ onClick }: { onClick: () => unknown }) => {
  const [css, $theme] = useStyletron();
  return (
    <div className={css({ float: 'left', color: $theme.colors.light_grey, cursor: 'pointer' })}>
      {
      // @ts-ignore can remove once baseui supports React 18
      <DeleteAlt onClick={onClick} size={30}/>
      }
    </div>
  )
}

export const InfoIcon = ({ onClick }: { onClick: () => unknown }) => {
  const [css] = useStyletron();
  return <img src={ICON_QUESTION_MARK_URL} alt='info icon' className={css({ width: '50px', height: '50px' })} onClick={onClick}/>;
}