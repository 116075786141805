"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadDocumentResponseDTOSchema = exports.uploadDocumentRequestDTOSchema = exports.documentQueryFilterSchema = exports.documentSchema = void 0;
const zod_1 = require("zod");
exports.documentSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    filename: zod_1.z.string(),
    size: zod_1.z.number(),
    fileType: zod_1.z.string(),
    base64: zod_1.z.string(),
    link: zod_1.z.string().optional(),
    createdByUserId: zod_1.z.coerce.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
}).strict();
exports.documentQueryFilterSchema = exports.documentSchema.partial();
exports.uploadDocumentRequestDTOSchema = zod_1.z.object({
    base64: zod_1.z.string(),
    filename: zod_1.z.string(),
    size: zod_1.z.number(),
    filetype: zod_1.z.string(),
    type: zod_1.z.string(),
}).strict();
exports.uploadDocumentResponseDTOSchema = zod_1.z.object({
    filename: zod_1.z.string(),
    id: zod_1.z.coerce.string(),
}).strict();
