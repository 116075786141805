import { useStyletron } from 'baseui';
import { ProgressSteps, Step } from 'baseui/progress-steps';
import { font_sm } from '../../../shared/styles';
import Card from './Card';
import Button from './Button';
import { type ProgressStep } from '../../types';

export type ProgressStepsProps = {
  steps: ProgressStep[],
  activeStep: number,
  setPreviousStep: () => void,
  setNextStep: () => void,
  isValidStep?: boolean
}

const CustomProgressSteps = ({ steps, activeStep, setPreviousStep, setNextStep, isValidStep = true }: ProgressStepsProps) => {
  const [css, $theme] = useStyletron();

  return (
    <Card overrides={{ padding: '10px' }}>
      <ProgressSteps
        current={activeStep}
        overrides={{
          Root: {
            style: () => ({
              paddingBottom: '0px',
              marginLeft: '-5px',
              marginBottom: '-15px',
            })
          },
          Icon: {
            style: () => ({
              outline: `${$theme.colors.accent} solid`,
              backgroundColor: $theme.colors.accent
            })
          },
          Title: {
            style: () => ({
              ...font_sm,
              color: $theme.colors.accent
            })
          },
        }}
      >
        {steps.map((step, i) => {
          return <Step key={i} title={step.title} />
        })}
      </ProgressSteps>
    <div className={css({ display: 'flex', justifyContent: 'center', paddingBottom: '5px', paddingTop: '10px' })}>
      <div className={css({ width: '50%' })}>
        <Button label={'Back'} onClick={() => setPreviousStep()} disabled={activeStep <= 0} />
      </div>
      <div className={css({ width: '50%' })}>
        <Button label={'Next'} onClick={() => setNextStep()} disabled={activeStep >= steps.length - 1 || !isValidStep}/>
      </div>
    </div>
    </Card>
  );
}

export default CustomProgressSteps;