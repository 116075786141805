import { useEffect, useState } from 'react';
import { useStyletron } from "baseui";
import { font_med } from '../../../shared/styles';
import { FlexGrid, FlexGridItem } from "../layout/FlexGrid";
import Card from "./Card";

export type ProgressGraphItem = {
  id: string;
  label: string;
  percent: number;
  color: string;
  amount: string;
}

export type ProgressGraphProps = {
  data: ProgressGraphItem[];
  opacity: number;
  font: { fontSize: string };
  labelSpacing: 'space-between' | 'flex-start';
}

type HoverState = { [key: string]: boolean };

const ProgressGraph = ({ data, opacity, font, labelSpacing }: ProgressGraphProps) => {
  const [hoverState, setHoverState] = useState<HoverState | null>(null);

  useEffect(() => {
    if (hoverState === null && data) {
      const hoverState: HoverState = {};
      data.forEach((item, i) => {
        hoverState[item.id] = false;
        if (i === 0) hoverState[item.id] = true;
      });
      setHoverState(hoverState);
    }
  }, [data, hoverState]);

  const onHover = (id: string) => {
    const nextHoverState: HoverState = {};
    for (const attr in hoverState) {
      nextHoverState[attr] = attr === id ? true : false;
    }
    setHoverState(nextHoverState);
  }

  return (
    <Card overrides={{ padding: '10px', justifyContent: 'center', border: 'none', ...font_med }}>
      {
        (data && hoverState !== null) &&
        <>
          <Labels data={data} onHover={onHover} hoverState={hoverState} opacity={opacity} font={font} labelSpacing={labelSpacing} />
          <Graph data={data} hoverState={hoverState} opacity={opacity} />
        </>
      }
    </Card>
  )
}

export default ProgressGraph;

type LabelsProps = {
  onHover: (id: string) => void;
  hoverState: HoverState;
} & ProgressGraphProps;

const Labels = ({ data, onHover, hoverState, opacity, font: defaultFont, labelSpacing }: LabelsProps) => {
  const [css, $theme] = useStyletron();
  return (
    <FlexGrid overrides={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: labelSpacing }}>
      {
        data.map(({ id, label, percent, color, amount }, i) => {
          const isHovered = hoverState[id];
          const font = { ...defaultFont };
          const originalFontSize = font.fontSize.slice(0, -2);
          if (isHovered) font.fontSize = (parseInt(originalFontSize) + 2).toString() + 'px';
          return (
            <div
              key={i}
              className={css({
                ...font,
                color: $theme.colors.white,
                backgroundColor: color,
                padding: '0px 5px',
                borderRadius: '5px',
                opacity: isHovered ? 1 : opacity
              })}
              onMouseEnter={() => onHover(id)}
            >
              <FlexGrid overrides={{ flexDirection: 'row', rowGap: '0px' }}>
                <FlexGridItem>{`${label}: `}</FlexGridItem>
                <FlexGridItem>{`${amount} (${percent}%)`}</FlexGridItem>
              </FlexGrid>
            </div>
          )
        })
      }
    </FlexGrid>
  )
}

type GraphProps = {
  data: ProgressGraphItem[];
  hoverState: HoverState;
  opacity: number;
}

const Graph = ({ data, hoverState, opacity }: GraphProps) => {
  return (
    <FlexGrid overrides={{ alignItems: 'center', columnGap: '0px', marginTop: '10px' }}>
      {
        data.map(({ id, percent, color }, i) => {
          const isHovered = hoverState[id];
          let borderRadius = '0px';
          if (i === 0) borderRadius = '5px 0px 0px 5px';
          if (i === data.length - 1) borderRadius = '0px 5px 5px 0px';
          return (
            <FlexGridItem
              key={i}
              overrides={{
                borderRadius: borderRadius,
                height: isHovered ? '12px' : '10px',
                backgroundColor: color,
                width: `${percent}%`,
                textAlign: 'left',
                opacity: isHovered ? 1 : opacity
              }}></FlexGridItem>
          )
        })
      }
    </FlexGrid>
  )
}
