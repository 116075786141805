import { createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from '../../../shared/constants';
import { createAsyncThunkByType } from '../../thunk';

import { Agency } from 'unity-types';
import { agencySchema } from 'unity-types/schema';

interface AgencyState {
  allAgenciesStatus: AsyncStatus;
  allAgenciesError: string | null;
  allAgencies: Agency[] | null;
}

const initialState: AgencyState = {
  allAgenciesStatus: AsyncStatus.Idle,
  allAgenciesError: null,
  allAgencies: null,
};

export const getAllAgencies = createAsyncThunkByType<Agency[], undefined>(
  "GET", 'agencies/', 
  agencySchema, true, 
  undefined);

export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getAllAgencies.pending, (state) => {
        state.allAgenciesStatus = AsyncStatus.Loading;
      })
      .addCase(getAllAgencies.fulfilled, (state, action) => {
        state.allAgenciesStatus = AsyncStatus.Succeeded;
        state.allAgencies = action.payload;
      })
      .addCase(getAllAgencies.rejected, (state) => {
        state.allAgenciesStatus = AsyncStatus.Failed;
      })
  }
});

export const { 
  clearState,
} = agenciesSlice.actions;

export default agenciesSlice.reducer;
