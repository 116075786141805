import { FieldValidator, SelectFieldValidator, SelectOption } from "../../../../shared/types";
import { FieldValidationMessage } from "../../../../shared/constants";

export const validateNameField: FieldValidator = (value: string) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[a-z .&A-Z0-9]+$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateSelectField: SelectFieldValidator = (value: SelectOption[]) => {
  if(value.length === 0) return [false, FieldValidationMessage.RequiredField];
  return [true, undefined];
}