import { Outlet } from 'react-router-dom';
// import { VendorsNavBar } from '../navbars/SubNavBar';

const Vendors_Index = () => {
  return (
    // <>
    //   <VendorsNavBar />
    //   <Outlet/>
    // </>
    <Outlet/>
  );
}

export default Vendors_Index;
