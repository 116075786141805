import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTheme, Theme } from 'baseui';
import { defaultAdminPortalTheme, defaultDigPortalTheme, defaultReentryPortalTheme, defaultGlobalTheme, staticColors } from '../../styles/theme';

interface ThemeState {
  admin: Theme;
  reentry: Theme;
  dig: Theme;
  global: Theme;
};

interface ChangeThemePayload { 
  program: 'reentry' | 'dig', 
  theme: Theme 
};

const initialState: ThemeState = {
  admin: defaultAdminPortalTheme,
  reentry: defaultReentryPortalTheme,
  dig: defaultDigPortalTheme,
  global: defaultGlobalTheme
};

const buildAdminPortalTheme = (payload: ChangeThemePayload): Theme => {
  const theme = createTheme({ 
    colors: { 
      accent: defaultAdminPortalTheme.colors.accent,
      ...staticColors,
      reentry_accent: defaultReentryPortalTheme.colors.accent,
      dig_accent: defaultDigPortalTheme.colors.dig_accent,
      [`${payload.program}_accent`]: payload.theme.colors.accent,
      logo_url: defaultAdminPortalTheme.colors.logo_url,
      background_mountains_url: defaultAdminPortalTheme.colors.background_mountains_url,
    },
  });
  return theme;
}

const buildGlobalTheme = (payload: ChangeThemePayload): Theme => {
  const theme = createTheme({ 
    colors: { 
      accent: defaultGlobalTheme.colors.accent,
      ...staticColors,
      reentry_accent: defaultReentryPortalTheme.colors.accent,
      dig_accent: defaultDigPortalTheme.colors.accent,
      admin_accent: defaultAdminPortalTheme.colors.accent,
      [`${payload.program}_accent`]: payload.theme.colors.accent,
    },
  });
  return theme;
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    setProgramPortalTheme: (state, action: PayloadAction<ChangeThemePayload>) => {
      const payload = action.payload;
      state[payload.program] = payload.theme;
      state.global = buildGlobalTheme(payload);
      state.admin = buildAdminPortalTheme(payload);
    },
  },
});

export const { setProgramPortalTheme } = themeSlice.actions;

export default themeSlice.reducer;
