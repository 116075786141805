import PORTAL_INDEX from '.';
import SEARCH_INDEX from './components/pages/Search_Index';
import CLIENTS_INDEX from './components/pages/Clients_Index';
import PAYMENTS_INDEX from './components/pages/Payments_Index';
import VENDORS_INDEX from './components/pages/Vendors_Index';
import HomePage from './components/pages/HomePage';
import UserDetailPage from './components/pages/users/UserDetailPage';
import AgencyDetailPage from './components/pages/agencies/AgencyDetailPage';
import ChangeStandardAllocationPage from './components/pages/agencies/ChangeStandardAllocationPage';
import SearchClientPage from './components/pages/clients/SearchClientPage';
import NewClientPage from './components/pages/clients/NewClientPage';
import UpdateClientPage from './components/pages/clients/UpdateClientPage';
import UpdateClientBudgetPage from './components/pages/clients/UpdateClientBudgetPage';
import UpdateClientStatusPage from './components/pages/clients/UpdateClientStatusPage';
import ClientDetailPage from './components/pages/clients/ClientDetailPage';
import SearchPaymentPage from './components/pages/payments/SearchPaymentPage';
import InputPaymentPage from './components/pages/payments/InputPaymentPage';
import IssueRefundPage from './components/pages/payments/IssueRefundPage';
import PaymentDetailPage from './components/pages/payments/PaymentDetailPage';
import UploadReceiptPage from './components/pages/payments/UploadReceiptPage';
import SearchVendorPage from './components/pages/vendors/SearchVendorPage';
import NewVendorPage from './components/pages/vendors/NewVendorPage';
import VendorDetailPage from './components/pages/vendors/VendorDetailPage';

const routes = {
  path: 'dig-portal', element: <PORTAL_INDEX />,
  children: [
    { index: true, element: <HomePage /> },
    {
      path: 'search', element: <SEARCH_INDEX />,
      children: [
        { index: true, element: <SearchClientPage /> },
        { path: 'search-payments', element: <SearchPaymentPage /> },
        { path: 'search-vendors', element: <SearchVendorPage /> }
      ]
    },
    {
      path: 'clients', element: <CLIENTS_INDEX />,
      children: [
        { index: true, element: <NewClientPage /> },
        { path: 'update-client', element: <UpdateClientPage /> },
        { path: 'adjust-budget', element: <UpdateClientBudgetPage /> },
        { path: 'client-status', element: <UpdateClientStatusPage /> },
        { path: 'detail/:recordId', element: <ClientDetailPage /> }
      ]
    },
    {
      path: 'payments', element: <PAYMENTS_INDEX />,
      children: [
        { index: true, element: <InputPaymentPage /> },
        { path: 'issue-refund', element: <IssueRefundPage /> },
        { path: 'detail/:recordId', element: <PaymentDetailPage /> },
        { path: 'upload-receipt', element: <UploadReceiptPage /> }
      ]
    },
    {
      path: 'vendors', element: <VENDORS_INDEX />,
      children: [
        { index: true, element: <NewVendorPage /> },
        { path: 'detail/:recordId', element: <VendorDetailPage /> }
      ]
    },
    { path: 'users/detail/:recordId', element: <UserDetailPage /> },
    { path: 'agencies/detail/:recordId', element: <AgencyDetailPage /> },
    { path: 'agencies/change-standard-allocation', element: <ChangeStandardAllocationPage /> }
  ]
};

export default routes;