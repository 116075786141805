import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { find } from 'lodash';
import { SIZE } from '../../../../shared/styles';
import { buildSelectOptions } from '../../../utils';
import { capitalize } from '../../../../shared/utils';
import { validateNameField, validateSelectField } from './validate';
import { setFieldOnNewVendor } from '../../../slices/vendor/vendor.slice';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Select from '../../../../shared/components/elements/Select';
import Title from '../../../../shared/components/elements/Title';
import { type PgEnum } from 'unity-types';
import { type NewVendor } from '../../../slices/vendor/types';
import { type SelectOption, type FieldValidator, type SelectFieldValidator } from '../../../../shared/types';

type VendorDetailsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

const VendorDetailsForm = ({ setIsValidStep }: VendorDetailsFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const newVendor: NewVendor = useSelector(state => state.dig_portal.vendors.newVendor!);
  const selectFields: PgEnum[] | null = useSelector(state => state.dig_portal.schema.selectFields);
  const [name, setName] = useState<string>(newVendor.name);
  const [category, setCategory] = useState<SelectOption[]>(newVendor.category);
  const [nameError, setNameError] = useState<string>();
  const [categoryError, setCategoryError] = useState<string>();
  const [categorySelectOptions, setCategorySelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const hasError = nameError || categoryError;
    const emptyFields = (name === undefined || name === '') || category.length === 0;
    const isValid = !hasError && !emptyFields;
    setIsValidStep(isValid);
  }, [setIsValidStep, name, category, nameError, categoryError]);

  useEffect(() => {
    if(selectFields) {
      const categoryField = find(selectFields, (field) => field.name === 'vendor_category')!;
      setCategorySelectOptions(buildSelectOptions(categoryField.values));
    }
  }, [selectFields]);

  const onBlurInput = (key: keyof NewVendor, value: string, validator: FieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    dispatch(setFieldOnNewVendor({ key, value }));
  }

  const onChangeInput = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value);
  }

  const onBlurSelect = (key: keyof NewVendor, value: SelectOption[], validator: SelectFieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    dispatch(setFieldOnNewVendor({ key, value }));
  }

  const onChangeSelect = (value: any, setValue: React.Dispatch<React.SetStateAction<SelectOption[]>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value as SelectOption[]);
  }

  return (
    <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
      <Title title={'Vendor Details'} size={SIZE.LARGE}/>
      <Input
        value={name}
        onChange={(e) => onChangeInput(e.target.value, setName, setNameError)}
        label={'Name'}
        onBlur={() => onBlurInput('name', capitalize(name.trim()), validateNameField, setNameError)}
        error={nameError}
      />
      <Select
        value={category}
        label={'Category'}
        onChange={(params) => onChangeSelect(params.value, setCategory, setCategoryError)}
        options={categorySelectOptions}
        isLoading={categorySelectOptions.length === 0}
        onBlur={() => onBlurSelect('category', category, validateSelectField, setCategoryError)}
        error={categoryError}
      />
    </Card>
  );
}

export default VendorDetailsForm;

