import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../../redux/hooks';
import { font_med } from '../../../../../shared/styles';
import Card from '../../../../../shared/components/elements/Card';
import ProgressGraph, { type ProgressGraphItem } from '../../../../../shared/components/elements/ProgressGraph';
import { formatCurrencyAmount, calculateObligatedFundsBreakdownByClient, getRandomColor, type FundsBreakdownByClient } from '../../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../../shared/components/layout/FlexGrid';
import { type DigAgencyExtendedInfo, type Client } from 'unity-types';

const ClientBreakdown = () => {
  const [, $theme] = useStyletron();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allClients: Client[] | null = useSelector(state => state.dig_portal.clients.allClients);
  const [data, setData] = useState<ProgressGraphItem[]>();
  
  useEffect(() => {
    if(userAgency && allClients.length > 0) {
      const obligationByClient: FundsBreakdownByClient = calculateObligatedFundsBreakdownByClient(userAgency.totalObligatedFunds, allClients);
      const data: ProgressGraphItem[] = allClients.map((client) => {
        const clientId = client.id;
        const { percent, obligatedFunds } = obligationByClient[clientId];
        return { 
          id: clientId,
          label: client.fullName, 
          percent: percent, 
          color: getRandomColor(),
          amount: `$${formatCurrencyAmount(obligatedFunds)}`
        }
      });
      setData(data);
    }
  }, [userAgency, allClients]);

  return (
    userAgency &&
    <Card overrides={{ padding: '10px 10px 20px 10px', justifyContent: 'center' }}>
      { data && <ProgressGraph data={data} font={font_med} opacity={.4} labelSpacing={'flex-start'} /> }
      <FlexGrid overrides={{ justifyContent: 'center' }}>
        <FlexGridItem overrides={{ ...font_med, backgroundColor: $theme.colors.white, color: $theme.colors.black, padding: '0px 5px', borderRadius: '5px' }}>
          % Client of Total Obligated (${formatCurrencyAmount(userAgency.totalObligatedFunds)})
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default ClientBreakdown;
