import { useStyletron } from 'baseui';
import { font_med } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { 
  AuthenticatedUser
} from 'unity-types';

export type MyUserHeaderCardProps = {
  myUser: AuthenticatedUser;
};

export const MyUserHeaderCard = ({ myUser }: MyUserHeaderCardProps) => {
  const [css, $theme] = useStyletron();

  return (
    myUser &&
    <Card overrides={{ float: 'right', backgroundColor: $theme.colors.white, border: 'none', width: 'auto', padding: '5px' }} >
      <div className={css({...font_med, textAlign: 'right', color: $theme.colors.black })}>
        Welcome, {myUser.firstName}!
      </div>
      <div className={css({...font_med, textAlign: 'right', color: $theme.colors.black })}>
        {myUser.agencyName}
      </div>
    </Card>
  );
}