import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';
import { PortalUser } from "./types";

import {
  UnityUser,
  UnityUserQueryFilter,
  PortalName,
  GenericResponse,
  AssignRoleDTO,
  UserPermission,
  UpdateUserPermissionsDTO,
  AdminPortalUser
} from 'unity-types';

import { unityUserSchema, unityUserQueryFilterSchema, adminPortalUserSchema } from 'unity-types/schema';

interface UserState {
  getAllUnityUsersStatus: AsyncStatus;
  getAllUnityUsersError: string | null;
  allUnityUsers: UnityUser[] | null;
  getCurrentUserStatus: AsyncStatus;
  getCurrentUserError: string | null;
  myUser: AdminPortalUser | null;
  getUnityUserStatus: AsyncStatus;
  getUnityUserError: string | null;
  viewUnityUser: UnityUser | null;
  getPortalUserStatus: AsyncStatus;
  getPortalUserError: string | null
  viewPortalUser: { portal: PortalName, user: PortalUser } | null;
  assignRoleStatus: AsyncStatus;
  assignRoleError: string | null;
  updateUserPermissionsStatus: AsyncStatus;
  updateUserPermissionsError: string | null;
  updateUserPermissionSetStatus: AsyncStatus;
  updateUserPermissionSetError: string | null;
  updateUserActivationStatus: AsyncStatus;
  updateUserActivationError: string | null;
}

const initialState: UserState = {
  getAllUnityUsersStatus: AsyncStatus.Idle,
  getAllUnityUsersError: null,
  allUnityUsers: null,
  getCurrentUserStatus: AsyncStatus.Idle,
  getCurrentUserError: null,
  myUser: null,
  getUnityUserStatus: AsyncStatus.Idle,
  getUnityUserError: null,
  viewUnityUser: null,
  getPortalUserStatus: AsyncStatus.Idle,
  getPortalUserError: null,
  viewPortalUser: null,
  assignRoleStatus: AsyncStatus.Idle,
  assignRoleError: null,
  updateUserPermissionsStatus: AsyncStatus.Idle,
  updateUserPermissionsError: null,
  updateUserPermissionSetStatus: AsyncStatus.Idle,
  updateUserPermissionSetError: null,
  updateUserActivationStatus: AsyncStatus.Idle,
  updateUserActivationError: null,
};

export const getCurrentUser = createAsyncThunk<AdminPortalUser, { userId: string }, { rejectValue: ServerError }>(
  'admin_portal/users/getCurrentUser', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/admin_portal/users/${payload.userId}`);
      const user: AdminPortalUser = adminPortalUserSchema.parse(response.data);
      return user;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAllUnityUsers = createAsyncThunk<UnityUser[], UnityUserQueryFilter, { rejectValue: ServerError }>(
  'admin_portal/users/getAllUnityUsers', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: UnityUserQueryFilter = unityUserQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/shared/users?${query}`);
      const users: UnityUser[] = response.data.map((user: any) => unityUserSchema.parse(user));
      return users;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getUnityUser = createAsyncThunk<UnityUser, { userId: string }, { rejectValue: ServerError }>(
  'admin_portal/users/getUnityUser', 
  async (payload, { rejectWithValue }) => {
    const { userId } = payload;
    try {
      const response = await axios.get(`/api/shared/users/${userId}`);
      const user: UnityUser = unityUserSchema.parse(response.data);
      return user;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const assignRole = createAsyncThunk<GenericResponse, { userId: string, dto: AssignRoleDTO }, { rejectValue: ServerError }>(
  'admin_portal/users/assignRole', 
  async (payload, { rejectWithValue }) => {
    try {
      const userId = payload.userId;
      const dto: AssignRoleDTO = payload.dto;
      const response = await axios.post(`/api/admin_portal/users/${userId}/roles`, dto);
      const parsed: GenericResponse = response.data;
      return parsed;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const updateUserPermissionSet = createAsyncThunk<UserPermission, { userId: string, roleName: PortalName, dto: UpdateUserPermissionsDTO }, { rejectValue: ServerError }>(
  'admin_portal/users/updateUserPermissionSet',
  async (payload, { rejectWithValue }) => {
    try {
      const { userId, roleName, dto } = payload;
      const response = await axios.patch(`/api/admin_portal/users/${userId}/roles/${roleName}`, dto);
      const parsed: UserPermission = response.data;
      return parsed;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const updateUserActivation = createAsyncThunk<UserPermission, { userId: string, roleName: PortalName, dto: UpdateUserPermissionsDTO }, { rejectValue: ServerError }>(
  'admin_portal/users/updateUserActivation',
  async (payload, { rejectWithValue }) => {
    try {
      const { userId, roleName, dto } = payload;
      const response = await axios.patch(`/api/admin_portal/users/${userId}/roles/${roleName}`, dto);
      const parsed: UserPermission = response.data;
      return parsed;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getPortalUser = createAsyncThunk<{ portal: PortalName, user: PortalUser }, { portal: PortalName, userId: string }, { rejectValue: ServerError }>(
  'admin_portal/users/getPortalUser', 
  async (payload, { rejectWithValue }) => {
    const { portal, userId } = payload;
    try {
      const response = await axios.get(`/api/${portal}/users/${userId}`);
      const user: PortalUser = response.data;
      return { portal, user };
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    clearViewUnityUser: (state) => {
      state.viewUnityUser = initialState.viewUnityUser;
    },
    resetAssignRoleAsyncState: (state) => {
      state.assignRoleStatus = initialState.assignRoleStatus;
      state.assignRoleError = initialState.assignRoleError;
    },
    clearViewPortalUserState: (state) => {
      state.viewPortalUser = initialState.viewPortalUser;
      state.getPortalUserStatus = initialState.getPortalUserStatus;
      state.getPortalUserError = initialState.getPortalUserError;
    },
    resetUpdateUserPermissionSetAsyncState: (state) => {
      state.updateUserPermissionSetStatus = initialState.updateUserPermissionSetStatus;
      state.updateUserPermissionSetError = initialState.updateUserPermissionSetError;
    },
    resetUpdateUserActivationAsyncState: (state) => {
      state.updateUserActivationStatus = initialState.updateUserActivationStatus;
      state.updateUserActivationError = initialState.updateUserActivationError;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.getCurrentUserStatus = AsyncStatus.Loading;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.getCurrentUserStatus = AsyncStatus.Succeeded;
        state.myUser = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.getCurrentUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getCurrentUserError = message;
      })
        .addCase(getAllUnityUsers.pending, (state) => {
        state.getAllUnityUsersStatus = AsyncStatus.Loading;
      })
      .addCase(getAllUnityUsers.fulfilled, (state, action) => {
        state.getAllUnityUsersStatus = AsyncStatus.Succeeded;
        state.allUnityUsers = action.payload;
      })
      .addCase(getAllUnityUsers.rejected, (state, action) => {
        state.getAllUnityUsersStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAllUnityUsersError = message;
      })
      .addCase(getUnityUser.pending, (state, action) => {
        state.getUnityUserStatus = AsyncStatus.Loading;
      })
      .addCase(getUnityUser.fulfilled, (state, action) => {
        state.getUnityUserStatus = AsyncStatus.Succeeded;
        state.viewUnityUser = action.payload;
      })
      .addCase(getUnityUser.rejected, (state, action) => {
        state.getUnityUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getUnityUserError = message;
      })
      .addCase(assignRole.pending, (state) => {
        state.assignRoleStatus = AsyncStatus.Loading;
      })
      .addCase(assignRole.fulfilled, (state, action) => {
        state.assignRoleStatus = AsyncStatus.Succeeded;
      })
      .addCase(assignRole.rejected, (state, action) => {
        state.assignRoleStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.assignRoleError = message;
      })
      .addCase(updateUserPermissionSet.pending, (state) => {
        state.updateUserPermissionSetStatus = AsyncStatus.Loading;
      })
      .addCase(updateUserPermissionSet.fulfilled, (state, action) => {
        state.updateUserPermissionSetStatus = AsyncStatus.Succeeded;
      })
      .addCase(updateUserPermissionSet.rejected, (state, action) => {
        state.updateUserPermissionSetStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.updateUserPermissionSetError = message;
      })
      .addCase(updateUserActivation.pending, (state) => {
        state.updateUserActivationStatus = AsyncStatus.Loading;
      })
      .addCase(updateUserActivation.fulfilled, (state, action) => {
        state.updateUserActivationStatus = AsyncStatus.Succeeded;
      })
      .addCase(updateUserActivation.rejected, (state, action) => {
        state.updateUserActivationStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.updateUserActivationError = message;
      })
      .addCase(getPortalUser.pending, (state) => {
        state.getPortalUserStatus = AsyncStatus.Loading;
      })
      .addCase(getPortalUser.fulfilled, (state, action) => {
        state.getPortalUserStatus = AsyncStatus.Succeeded;
        state.viewPortalUser = action.payload;
      })
      .addCase(getPortalUser.rejected, (state, action) => {
        state.getPortalUserStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getPortalUserError = message;
      })
  }
});

export const { 
  clearState,
  clearViewUnityUser,
  resetAssignRoleAsyncState,
  clearViewPortalUserState,
  resetUpdateUserPermissionSetAsyncState,
  resetUpdateUserActivationAsyncState,
} = usersSlice.actions;

export default usersSlice.reducer;