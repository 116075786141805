import { useEffect, useState } from "react";
import { useAppSelector as useSelector } from "../../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useStyletron } from "baseui";
import { font_med, font_lg, font_med_lg } from "../../../../shared/styles";
import { getClientDetailPageUrl } from "../../../utils";
import { AsyncStatus } from "../../../../shared/constants";
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import CustomButtonGroup from '../../../../shared/components/elements/CustomButtonGroup';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type ReentryPortalUser, type Client } from 'unity-types';
import { type FilterOption } from "../../../../shared/types";

export const ClientList = () => {
  const [css, $theme] = useStyletron();
  const myUser: ReentryPortalUser | null = useSelector(state => state.reentry_portal.users.myUser);
  const allClients: Client[] | null = useSelector(state => state.reentry_portal.clients.allClients);
  const getAllClientsStatus: AsyncStatus = useSelector(state => state.reentry_portal.clients.getAllClientsStatus);
  const [visibleClients, setVisibleClients] = useState<Client[]>(allClients);
  const [userClientsFilter, setUserClientsFilter] = useState<string>('my_clients');
  const [activeClientsFilter, setActiveClientsFilter] = useState<string>('active_clients');

  const userClientsFilterOptions: FilterOption[] = [
    { label: `Show Everyone's clients`, value: 'all_clients', onClick: () => setUserClientsFilter('all_clients') },
    { label: 'Show only my clients', value: 'my_clients', onClick: () => setUserClientsFilter('my_clients') },
  ];

  const activeClientsFilterOptions: FilterOption[] = [
    { label: 'Show both active and inactive clients', value: 'all_clients', onClick: () => setActiveClientsFilter('all_clients') },
    { label: 'Show only active clients', value: 'active_clients', onClick: () => setActiveClientsFilter('active_clients') },
  ];

  useEffect(() => {
    if(allClients.length > 0 && myUser) {
      let filtered = allClients;
      if(userClientsFilter === 'my_clients') {
        filtered = filtered.filter(client => client.navigatorId === myUser.id)
      }
      if(activeClientsFilter === 'active_clients') {
        filtered = filtered.filter(client => client.programStatus === 'Active');
      }
      setVisibleClients(filtered);
    }
  }, [userClientsFilter, activeClientsFilter, allClients, myUser]);

  return (
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>

          <div className={css({...font_med_lg, color: $theme.colors.white, textAlign: 'center' })}>Clients</div>
          <FlexGridItem overrides={{  }}>
            <CustomButtonGroup value={userClientsFilter} options={userClientsFilterOptions}/>
            <CustomButtonGroup value={activeClientsFilter} options={activeClientsFilterOptions}/>
          </FlexGridItem>

        </FlexGrid>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 450}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
          <FlexGrid overrides={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {
              getAllClientsStatus === AsyncStatus.Loading ?
              <ExtraLargeSpinner /> :
              myUser && visibleClients.map((client, i) => <ClientListItem client={client} myUser={myUser} key={i} />)
            }
          </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export type ClientListItemProps = {
  client: Client;
  myUser: ReentryPortalUser;
}

const ClientListItem = ({ client, myUser }: ClientListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const isActive = client.programStatus === 'Active';
  const isMine = client.navigatorId === myUser.id;

  return (
    <Card 
      overrides={{ 
        padding:'10px 20px', 
        ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
        backgroundColor: $theme.colors.white,
        borderColor: `${isMine ? isActive ? $theme.colors.accent : $theme.colors.negative : $theme.colors.grey}`,
      }} 
      onClick={() => navigate(getClientDetailPageUrl(client.id))}
    >
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        <FlexGridItem overrides={{...font_lg, color: isMine ? isActive ? $theme.colors.accent : $theme.colors.negative : $theme.colors.grey }}>{client.firstName} {client.lastName}</FlexGridItem>
        <FlexGridItem overrides={{ ...font_med, color: isMine ? $theme.colors.black : $theme.colors.grey }}>{client.confirmationCode}</FlexGridItem>
        <FlexGrid overrides={{ justifyContent: 'space-between', alignItems: 'center' }}>
          {
            <FlexGrid overrides={{ ...font_med }}>
              <FlexGridItem overrides={{ color: isMine ? isActive ? $theme.colors.accent : $theme.colors.negative : $theme.colors.grey }}>${client.obligatedFunds}</FlexGridItem>
            </FlexGrid>
          }
          <FlexGridItem overrides={{ ...font_med, color: isMine ? isActive ? $theme.colors.positive : $theme.colors.negative : $theme.colors.grey }}>{client.programStatus}</FlexGridItem>
        </FlexGrid>
      </FlexGrid>
    </Card>
  )
}