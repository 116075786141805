import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { Textarea, SIZE } from "baseui/textarea";
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import { input_font, font_med, font_extra_sm } from '../../../shared/styles';

export type CustomTextAreaProps = {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void,
  label: string,
  caption?: string | null,
  width?: string,
  error?: string | null,
}

const CustomTextArea = ({ value, onChange, onBlur = () => {}, label, caption = null, width = `${(window.innerWidth * .3) - 27}px`, error = null }: CustomTextAreaProps) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <FormControl 
      label={label}
      caption={
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          { (caption || error) && <FlexGridItem>{caption}</FlexGridItem> }
          { error && <FlexGridItem overrides={{ backgroundColor: $theme.colors.negative, color: $theme.colors.white, padding: '2px', borderRadius: '10px' }}>{error}</FlexGridItem> }
        </FlexGrid>
      }
      overrides={{
        Label: { style: () => ({ ...font_med, color: $theme.colors.white }) },
        Caption: { style: () => ({ ...font_extra_sm, color: $theme.colors.white}) }
      }}
    >
      {
      // @ts-ignore can remove once baseui supports React 18
      <Textarea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        clearOnEscape
        size={SIZE.compact}
        resize={'both'}
        error={error !== null}
        overrides={{
          Input: { style: () => ({ ...input_font }) },
          InputContainer: { style: () => ({ width, maxWidth: width }) },
        }}
      />
      }
    </FormControl>
  );
}

export default CustomTextArea;
