import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-monolithic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider, createTheme } from 'baseui';
import store from './redux/store';
import App from './App';

const engine = new Styletron();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={createTheme({})}>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </BaseProvider>
    </StyletronProvider>
  // </React.StrictMode>
);
