import { useStyletron } from 'baseui';
import { font_med, type Font } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { type NewVendor } from '../../../slices/vendor/types';

export type VendorReviewCardProps = {
  vendor: NewVendor;
  font?: Font;
};

export const VendorReviewCard = ({ vendor, font = font_med }: VendorReviewCardProps) => {
  const [css, $theme] = useStyletron();
  return (
    <Card overrides={{ padding: '10px' }} >
      <div className={css({...font, textAlign: 'left' })}>
        Name: <span className={css({ color: $theme.colors.accent })}>{vendor.name}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Category: <span className={css({ color: $theme.colors.accent })}>{vendor.category && vendor.category[0].label}</span>
      </div>
    </Card>
  )
}