import { useStyletron } from "baseui";
import { font_sm } from "../../../shared/styles";
import { FlexGrid, FlexGridItem } from "../layout/FlexGrid";
import BaseButton from "./Button";
import { type FilterOption } from "../../types";

export type CustomButtonGroupProps = {
  value: string;
  options: FilterOption[];
}

const CustomButtonGroup = ({ value, options }: CustomButtonGroupProps) => { 
  const [, $theme] = useStyletron();
  return (
    <FlexGrid overrides={{ justifyContent: 'flex-end' }}>
      {
        options.map((option, i) => {
          const isSelected = option.value === value;
          const overrides = isSelected ? { backgroundColor: $theme.colors.white, color: $theme.colors.accent } : { backgroundColor: $theme.colors.accent, color: $theme.colors.white }
          return (
            <FlexGridItem key={i} overrides={{ marginTop: '10px' }}>
              <BaseButton onClick={option.onClick} label={option.label} overrides={{ ...font_sm, ':focus': { opacity: 1 }, ...overrides }}/>
            </FlexGridItem>
          )
        })
      }
    </FlexGrid>
  );
}

export default CustomButtonGroup;