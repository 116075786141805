import { useStyletron } from 'baseui';
import { Button, SIZE } from 'baseui/button';
import { base_button, sm_warning_button, large_button, medium_button } from '../../../shared/styles';

export type ButtonProps = {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  overrides?: any;
};

const BaseButton = ({ label, onClick, isLoading = false, disabled = false, overrides = {} }: ButtonProps) => {
  const [, $theme] = useStyletron();
  const disabledStyles = disabled ? { ':hover': {} } : {}; 
  return (
    <Button
      onClick={onClick}
      size={SIZE.compact}
      isLoading={isLoading}
      disabled={disabled}
      overrides={{ 
        BaseButton: { 
          style: () => ({ 
            ...base_button,
            borderColor: $theme.colors.white, 
            backgroundColor: $theme.colors.accent, 
            color: $theme.colors.white, 
            ':hover': {
              borderColor: $theme.colors.accent,
              color: $theme.colors.accent,
              backgroundColor: $theme.colors.white
            },
            ...disabledStyles,
            ...overrides, 
          }) 
        } 
      }}
    >{label}</Button>
  )
}

export default BaseButton;

export const WarningButton = ({ label, onClick, isLoading = false, disabled, overrides = {} }: ButtonProps) => {
  const [, $theme] = useStyletron();
  return (
    <BaseButton 
      label={label}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      overrides={{ 
        backgroundColor: $theme.colors.white,
        color: $theme.colors.accent,
        borderColor: $theme.colors.accent, 
        ':hover': {
          borderColor: $theme.colors.white,
          color: $theme.colors.white,
          backgroundColor: $theme.colors.negative
        },
        ...overrides 
      }}
    />
  )
}

export const SmallWarningButton = ({ label, onClick, isLoading = false, disabled = false, overrides }: ButtonProps) => {
  const [, $theme] = useStyletron();
  return (
    <BaseButton 
      label={label}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      overrides={{ 
        ...sm_warning_button,
        backgroundColor: $theme.colors.white,
        color: $theme.colors.black,
        ':hover': {
          borderColor: $theme.colors.white,
          color: $theme.colors.white,
          backgroundColor: $theme.colors.negative
        },
        ...overrides 
      }}
    />
  )
}

export const LargeButton = ({ label, onClick, isLoading = false, disabled = false, overrides }: ButtonProps) => {
  const [, $theme] = useStyletron();
  return (
    <BaseButton 
      label={label}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      overrides={{ 
        ...large_button,
        color: $theme.colors.white,
        ...overrides 
      }}
    />
  )
}

export const MediumButton = ({ label, onClick, isLoading = false, disabled = false, overrides }: ButtonProps) => {
  const [, $theme] = useStyletron();
  return (
    <BaseButton 
      label={label}
      onClick={onClick}
      isLoading={isLoading}
      disabled={disabled}
      overrides={{ 
        ...medium_button,
        color: $theme.colors.white,
        ...overrides 
      }}
    />
  )
}
