import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { formatDateString, getCategoryColor, type CategoryColor } from '../../../utils';
import { font_lg, font_extra_lg } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import PaymentAmountCard from './PaymentAmountCard';

export const PaymentInfoCard = () => {
  const [, $theme] = useStyletron();
  const viewPayment = useSelector(state => state.reentry_portal.payments.viewPayment);

  return (
    viewPayment && 
    <Card overrides={{ padding: '10px' }} >
      <FlexGrid overrides={{ justifyContent: 'space-between', rowGap: '10px'}}>
        <FlexGridItem overrides={{ ...font_extra_lg, color: $theme.colors.white, backgroundColor: getCategoryColor(viewPayment.category as keyof typeof CategoryColor), padding: '0px 5px', borderRadius: '5px' }}>
          {viewPayment.category}
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_extra_lg, backgroundColor: $theme.colors.accent, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px' }}>{formatDateString(viewPayment.datePaid)}</FlexGridItem> 
      </FlexGrid>
      <FlexGrid>
        <PaymentAmountCard />
      </FlexGrid>
      <FlexGrid overrides={{ ...font_lg, justifyContent: 'flex-start', columnGap: '5px' }}>
        <FlexGridItem>Notes:</FlexGridItem>
        <FlexGridItem overrides={{ color: $theme.colors.accent }}>{viewPayment.notes}</FlexGridItem>
      </FlexGrid>
    </Card>
  )
}