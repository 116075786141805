import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { clearNewVendor, createNewVendor, resetCreateNewVendorState } from '../../../slices/vendor/vendor.slice';
import { deleteAttachment, clearState as clearAttachmentState } from '../../../slices/attachment/attachment.slice';
import { font_med } from '../../../../shared/styles';
import { AsyncStatus } from '../../../../shared/constants';
import { getVendorDetailPageUrl } from '../../../utils';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import { VendorReviewCard } from '../../cards/vendors/VendorReviewCard';
import Button, { SmallWarningButton } from '../../../../shared/components/elements/Button';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Title from '../../../../shared/components/elements/Title';
import Badge from '../../../../shared/components/elements/Badge';
import { Spinner } from 'baseui/spinner';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { CreateAttachmentResponseDTO, type Vendor } from 'unity-types';
import { type NewVendor } from '../../../slices/vendor/types';

type ReviewFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

export const ReviewForm = ({ setActiveStep }: ReviewFormProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendorStatus: AsyncStatus = useSelector(state => state.reentry_portal.vendors.status);
  const vendorsError: string | null = useSelector(state => state.reentry_portal.vendors.error);
  const newVendor: NewVendor = useSelector(state => state.reentry_portal.vendors.newVendor!);
  const matchingVendors: Vendor[] | null = useSelector(state => state.reentry_portal.vendors.matches);
  const newVendorRecordId: string | null = useSelector(state => state.reentry_portal.vendors.newVendorRecordId);
  const createAttachmentResponse: CreateAttachmentResponseDTO | null = useSelector(state => state.reentry_portal.attachments.createAttachmentResponse);
  const attachmentStatus: AsyncStatus = useSelector(state => state.reentry_portal.attachments.status);

  const submit = () => {
    const payload = {
      ...newVendor,
      category: newVendor.category[0].id
    }
    if(createAttachmentResponse) payload.w9AttachmentId = createAttachmentResponse.id;
    dispatch(createNewVendor(payload));
  }

  const onDeleteClick = () => {
    dispatch(deleteAttachment(createAttachmentResponse!));
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          <Title title={'Review new vendor'} overrides={{ marginRight: '10px' }}/>
          <FlexGridItem>
            <SmallWarningButton
              onClick={() => {
                dispatch(clearNewVendor());
                setActiveStep(0);
              }}
              label={'Start Over'}
            />
          </FlexGridItem>
        </FlexGrid>
        <VendorReviewCard vendor={newVendor} />
        {
          (createAttachmentResponse && createAttachmentResponse.filename) ? 
          <Badge content={createAttachmentResponse.filename} onDeleteClick={onDeleteClick}/> : 
          <FlexGrid>
            <FlexGridItem overrides={{ ...font_med, marginTop: '10px', padding: '5px', borderRadius: '5px', backgroundColor: $theme.colors.white, color: $theme.colors.negative }}>
              No W-9 Attached
            </FlexGridItem>
          </FlexGrid>
        }
        <FlexGrid overrides={{ justifyContent: 'space-between' }} >
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            {attachmentStatus === AsyncStatus.Loading && <Spinner />}
          </FlexGridItem>
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button
              onClick={() => submit()}
              label={'Submit'}
              isLoading={vendorStatus === AsyncStatus.Loading}
            />
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={newVendorRecordId !== null} 
        message={'Vendor added successfully.'}
        onClose={() => {
          navigate(getVendorDetailPageUrl(newVendorRecordId!));
          dispatch(clearAttachmentState());
        }}
      />
      {
        (matchingVendors && vendorsError !== null) &&
        <NotificationModal 
          isOpen={matchingVendors.length > 0 && vendorsError !== null} 
          message={
            <FlexGrid overrides={{ flexDirection: 'column' }}>
              <FlexGridItem>We found similar records in the system. Use one of the following existing vendors instead:</FlexGridItem>
              {matchingVendors.map((match, i) => {
                return <div key={i}>{i + 1}. {match.name}</div>
              })}
              <FlexGridItem>If none of these are correct, please contact us for assistance.</FlexGridItem>
            </FlexGrid>
          }
          onClose={() => {
            dispatch(resetCreateNewVendorState());
            setActiveStep(0);
          }}
        />
      }
      <NotificationModal 
        isOpen={matchingVendors! && vendorsError !== null} 
        message={vendorsError}
        onClose={() => {
          dispatch(resetCreateNewVendorState());
          setActiveStep(0);
        }}
      />
    </>
  );
}

export default ReviewForm;