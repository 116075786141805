import { Outlet } from 'react-router-dom';
// import { ClientsNavBar } from '../navbars/SubNavBar';

const Clients_Index = () => {
  return (
    // <>
    //   <ClientsNavBar />
    //   <Outlet/>
    // </>
    <Outlet/>
  );
}

export default Clients_Index;
