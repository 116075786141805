import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { buildSelectOptions, getClientDetailPageUrl } from '../../../utils';
import { updateClient, resetUpdateClientAsyncState, clearViewClient, getAllClients, clearAllClients } from '../../../slices/client/client.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { InfoCard as Card } from '../../../../shared/components/elements/Card';
import Select from '../../../../shared/components/elements/Select';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { LargeButton } from '../../../../shared/components/elements/Button';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type DigAgencyExtendedInfo, type Client, type UpdateClientDTO, type PgEnum } from 'unity-types';
import { type SelectOption } from '../../../../shared/types';
import { font_med_lg, font_sm_giant, notification_font } from '../../../../shared/styles';
import { InfoIcon } from '../../../../shared/components/elements/Icon';
import { HelpTextModal } from '../../../../shared/components/elements/Modal';

const SetClientStatusForm = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);
  const selectFields: PgEnum[] | null = useSelector(state => state.dig_portal.schema.selectFields);
  const updateClientStatus: AsyncStatus = useSelector(state => state.dig_portal.clients.updateClientStatus);
  const updateClientError: string | null = useSelector(state => state.dig_portal.clients.updateClientError);
  const [programStatus, setProgramStatus] = useState<SelectOption[]>([]);
  const [exitStatus, setExitStatus] = useState<SelectOption[]>([]);
  const [programStatusOptions, setProgramStatusOptions] = useState<SelectOption[]>([]);
  const [exitStatusOptions, setExitStatusOptions] = useState<SelectOption[]>([]);
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [helpTextModalIsOpen, setHelpTextModalIsOpen] = useState(false);

  useEffect(() => {
    if(updateClientStatus === AsyncStatus.Succeeded) {
      setSuccessModalIsOpen(true);
    }
  }, [updateClientStatus]);

  useEffect(() => {
    if(viewClient && viewClient.programStatus) setProgramStatus([{ id: viewClient.programStatus, label: viewClient.programStatus }]);
    if(viewClient && viewClient.exitStatus) setExitStatus([{ id: viewClient.exitStatus, label: viewClient.exitStatus }])
  }, [viewClient]);

  useEffect(() => {
    if(selectFields) {
      const programStatusField = find(selectFields, (field) => field.name === 'client_program_status')!;
      setProgramStatusOptions(buildSelectOptions(programStatusField.values));
      const exitStatusField = find(selectFields, (field) => field.name === 'client_exit_status')!;
      setExitStatusOptions(buildSelectOptions(exitStatusField.values));
    }
  }, [selectFields]);

  const validateAndSubmit = () => {
    if(!viewClient) return;

    if(exitStatus.length === 0) {
      return setValidationError('You must choose a reason why the client is exiting the program.');
    }

    const dto: UpdateClientDTO = {
      programStatus: programStatus[0].id,
    };

    if(exitStatus.length > 0) {
      dto.exitStatus = exitStatus[0].id;
      dto.programExitDate = new Date().toLocaleDateString();
      dto.obligatedFunds = viewClient.totalPaid;
    }

    const payload = {
      id: viewClient.id,
      dto: dto
    }

    dispatch(updateClient(payload));
  }

  return (
    (viewClient && userAgency) &&
    <FlexGridItem>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', height: 'auto', width: '600px' }}>
        <FlexGrid>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>Update {viewClient.fullName}'s</div>
            <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>status</div>
          </FlexGridItem>
          <InfoIcon onClick={() => setHelpTextModalIsOpen(true)} />
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          <FlexGridItem>
            <FlexGridItem overrides={{ marginTop: '40px', width: '400px' }}>
              <Select
                value={programStatus}
                label={'Set Program Status'}
                onChange={params => setProgramStatus(params.value as SelectOption[])}
                options={programStatusOptions}
                isLoading={programStatusOptions.length === 0}
              />
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '400px' }}>
              {
                (programStatus.length > 0 && programStatus[0].id === 'Exited') &&
                <Select
                  value={exitStatus}
                  label={'Exit Status'}
                  onChange={params => setExitStatus(params.value as SelectOption[])}
                  options={exitStatusOptions}
                  isLoading={exitStatusOptions.length === 0}
                />
              }
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '400px', marginTop: '10px', marginBottom: '40px' }}>
              <LargeButton label={'Submit'} onClick={() => validateAndSubmit()}  isLoading={updateClientStatus === AsyncStatus.Loading} disabled={(programStatus.length === 0) || (programStatus.length > 0 && programStatus[0].id === 'Active')}/>
            </FlexGridItem>
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <FlexGridItem overrides={{ width: '420px' }}>
      <NotificationModal 
        isOpen={!!validationError} 
        message={validationError} 
        onClose={() => setValidationError(undefined)}
      />
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={'Client updated successfully.'} 
        onClose={() => {
          dispatch(resetUpdateClientAsyncState());
          navigate(getClientDetailPageUrl(viewClient.id));
          dispatch(clearAllClients());
          dispatch(getAllClients({ agencyId: userAgency.id }));
          dispatch(clearViewClient());
        }}
      />
      <NotificationModal 
        isOpen={!!updateClientError} 
        message={updateClientError} 
        onClose={() => dispatch(resetUpdateClientAsyncState())}
      />
      <HelpTextModal isOpen={helpTextModalIsOpen} setIsOpen={setHelpTextModalIsOpen}>
        <FlexGridItem>
          <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.white }}>Changing a client's Program Status:</FlexGridItem>
          <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
            When you change a client's status to "exited", it indicates that the client has completed their program participation. 
            This action will release any remaining unspent funds allocated to the client, effectively ending their financial support from the program.
            Once a client is marked as "exited", no further payments can be made for that client.
          </FlexGridItem>
          <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
            WARNING: No further changes can be made to a client's status through the application once they have exited the program. If you need to make changes, please contact us for assistance.
          </FlexGridItem>
        </FlexGridItem>
      </HelpTextModal>
      </FlexGridItem>
    </FlexGridItem>
  );
}

export default SetClientStatusForm;