import { useEffect } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import UploadReceiptForm from '../../forms/payments/UploadReceiptForm';
import { type Payment } from 'unity-types';

const UploadReceiptPage = () => {
  const navigate = useNavigate();
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);

  useEffect(() => {
    // redirect to home if user landed on this page without a payment
    if(!viewPayment) {
      navigate('/');
    }
  }, [viewPayment, navigate]);

  return (
    viewPayment &&
    <StyledContainer>
      <FlexGrid overrides={{ marginTop: '20px', justifyContent: 'center' }}>
          <UploadReceiptForm />
        </FlexGrid>
    </StyledContainer>
  );
}


export default UploadReceiptPage;
