import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { getVendor } from '../../../slices/vendor/vendor.slice';
import { getAllPaymentsByVendor } from '../../../slices/payment/payment.slice';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import VendorHeaderCard from '../../cards/vendors/VendorHeaderCard';
import FinancialBreakdownCard from '../../cards/vendors/FinancialBreakdownCard';
import FinancialOverviewCard from '../../cards/vendors/FinancialOverviewCard';
import ViewAttachmentCard from '../../cards/attachments/ViewAttachmentCard';
import { VendorPaymentList } from '../../lists/payments/VendorPaymentList';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type ReentryAgencyExtendedInfo, type Vendor, type Payment } from 'unity-types';

const VendorDetailPage = () => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.agencies.userAgency);
  const viewVendor: Vendor | null = useSelector(state => state.reentry_portal.vendors.viewVendor);
  const allPaymentsByVendor: Payment[] | null = useSelector(state => state.reentry_portal.payments.allPaymentsByVendor);

  useEffect(() => {
    if(!viewVendor) dispatch(getVendor({ id: recordId }));
  }, [viewVendor, recordId, dispatch]);

  useEffect(() => {
    if(!allPaymentsByVendor && viewVendor && userAgency) dispatch(getAllPaymentsByVendor({ payeeVendorId: viewVendor.id, agencyId: userAgency.id }))
  }, [allPaymentsByVendor, viewVendor, userAgency, dispatch]);

  return (
    <BlankContainer>
    {
      viewVendor ?
      <FlexGrid overrides={{ justifyContent: 'flex-start', margin: '10px' }}>
        <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
          <VendorHeaderCard />
          <FlexGrid>
            { viewVendor.w9AttachmentId != null && <ViewAttachmentCard id={viewVendor.w9AttachmentId} title={'W-9'} />}
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
          <FinancialOverviewCard />
          <FinancialBreakdownCard />
        </FlexGridItem>
        <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '40%' }}>
          <VendorPaymentList />
        </FlexGridItem>
      </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
    }
    </BlankContainer>
  );
}

export default VendorDetailPage;
