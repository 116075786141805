import { useStyletron } from 'baseui';
import { Input, SIZE } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { input_font, font_med, font_extra_sm } from '../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';

type InputProps = {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  label: string,
  caption?: string | null,
  type?: string,
  error?: string | null
}

const CustomInput = ({ value, onChange, onBlur = () => {}, label, caption = null, type = '', error = null }: InputProps): React.ReactNode => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <FormControl 
      label={label}
      caption={
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          { (caption || error) && <FlexGridItem>{caption}</FlexGridItem> }
          { error && <FlexGridItem overrides={{ backgroundColor: $theme.colors.negative, color: $theme.colors.white, padding: '2px', borderRadius: '10px' }}>{error}</FlexGridItem> }
        </FlexGrid>
      }
      overrides={{
        Label: { style: () => ({ ...font_med, color: $theme.colors.white }) },
        Caption: { style: () => ({ ...font_extra_sm, color: $theme.colors.white }) },
        ControlContainer: { style: () => ({ marginBottom: '0px' }) }
      }}
    >
    {
      // @ts-ignore can remove once baseui supports React 18
      <Input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        clearOnEscape
        size={SIZE.compact}
        type={type}
        error={error !== null}
        overrides={{
          Input: { style: () => ({ backgroundColor: $theme.colors.white, ...input_font }) },
          Root: { style: ({ $error }) => $error ? { borderColor: $theme.colors.negative, borderWidth: '3px' } : {} },
        }}
      />
    }
    </FormControl>
  );
}

export default CustomInput;
