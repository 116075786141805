import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getPayment, resetAsyncPaymentState } from '../../../slices/payment/payment.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import Card from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Button from '../../../../shared/components/elements/Button';
import Title from '../../../../shared/components/elements/Title';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type DigAgencyExtendedInfo, type Payment } from 'unity-types';

const SearchPaymentForm = ({ title }: { title: string }) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);
  const paymentsStatus: AsyncStatus = useSelector(state => state.dig_portal.payments.status);
  const error: string | null = useSelector(state => state.dig_portal.payments.error);
  const [paymentCode, setPaymentCode] = useState<string>('');
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    if(viewPayment || error) {
      setPaymentCode('');
    }
  }, [viewPayment, error, navigate]);

  const validateAndSubmit = () => {
    if(!userAgency) return;
    if(paymentCode === '') {
      return setValidationError('Provide a Payment Code first.');
    }
    dispatch(getPayment({ paymentCode: paymentCode }));
  }

  return (
    <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '420px' }}>
          <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
          <Title title={title}/>
            <Input
              value={paymentCode}
              onChange={(e) => setPaymentCode(e.target.value)}
              label={'Enter a Payment Id:'}
              caption={'You can only view payments that are associated with your agency'}
            />
            <Button
              onClick={() => validateAndSubmit()}
              label={'Search'}
              isLoading={paymentsStatus === AsyncStatus.Loading}
              disabled={!!error}
            />
          </Card>
          <NotificationModal 
            isOpen={!!error} 
            message={error} 
            onClose={() => dispatch(resetAsyncPaymentState())}
          />
          <NotificationModal 
            isOpen={!!validationError} 
            message={validationError} 
            onClose={() => setValidationError('')}
          />
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
  );
}

export default SearchPaymentForm;
