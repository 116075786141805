import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import { setViewClient } from '../../../slices/client/client.slice';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import ClientHeaderCard from '../../cards/clients/ClientHeaderCard';
import ClientInfoCard from '../../cards/clients/ClientInfoCard';
import ProgramStatusCard from '../../cards/clients/ProgramStatusCard';
import FinancialStatusCard from '../../cards/clients/FinancialStatusCard';
import FinancialBreakdownCard from '../../cards/clients/FinancialBreakdownCard';
import { ClientPaymentList } from '../../lists/payments/ClientPaymentList';
import { type Client } from 'unity-types';

const ClientDetailPage = () => {
  const dispatch = useDispatch();
  const { recordId } = useParams();
  const allClients: Client[] | null = useSelector(state => state.dig_portal.clients.allClients);
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);

  useEffect(() => {
    if(!viewClient && allClients.length > 0) {
      const targetClient = find(allClients, (client) => client.id === recordId);
      if(targetClient) dispatch(setViewClient(targetClient));
    }
  }, [viewClient, allClients, recordId, dispatch]);

  return (
    <BlankContainer>
      {
        viewClient ?
        <FlexGrid overrides={{ justifyContent: 'space-between', margin: '10px' }}>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '25%' }}>
            <ClientHeaderCard />
            <ProgramStatusCard />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '30%' }}>
            <FinancialStatusCard />
            <FinancialBreakdownCard />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '25%' }}>
            <ClientPaymentList />
          </FlexGridItem>
          <FlexGridItem overrides={{ display: 'flex', flexDirection: 'column', rowGap: '10px', width: '20%' }}>
            <ClientInfoCard />
          </FlexGridItem>
        </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
      }
    </BlankContainer>
  );
}

export default ClientDetailPage;
