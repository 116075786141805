import { BACKGROUND_IMAGE_URLS } from './constants';
import { SelectOption } from './types';

export const getTodaysImage = () => {
  const dateObj = new Date();
  const dayOfWeek = dateObj.getDay();
  return BACKGROUND_IMAGE_URLS[dayOfWeek];
}

export const capitalize = (str: string): string => {
  const arr = str.split(' ');
  let res = '';
  arr.forEach((segment, i) => {
    const lower = segment.toLowerCase();
    res += segment.charAt(0).toUpperCase() + lower.slice(1) + ' ';
  })
  return res.slice(0, -1);
}

export const buildSelectOptions = (options: string[]): SelectOption[] => {
  const selectOptions = options.map((value: any) => {
    return {
      id: value,
      label: value
    }
  });
  return selectOptions;
}

export const generateDateString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}