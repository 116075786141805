import { useStyletron } from 'baseui';
import { font_med, type Font } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { formatCurrencyAmount } from '../../../utils';
import { type NewPayment } from '../../../slices/payment/types';

export type PaymentReviewCardProps = {
  payment: NewPayment;
  font?: Font;
  overrides?: any;
}

export const PaymentReviewCard = ({ payment, font = font_med, overrides = {} }: PaymentReviewCardProps) => {
  const [css, $theme] = useStyletron();
  return (
    <Card overrides={{ width: 'none', padding: '10px', ...overrides }} >
      <div className={css({...font, textAlign: 'left' })}>
        Client: <span className={css({ color: $theme.colors.accent })}>{payment.clientSelectOption[0] && payment.clientSelectOption[0].label}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Payee: <span className={css({ color: $theme.colors.accent })}>{payment.payeeSelectOption[0] && payment.payeeSelectOption[0].label}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Category: <span className={css({ color: $theme.colors.accent })}>{payment.categorySelectOption && payment.categorySelectOption[0].label}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Amount: <span className={css({ color: $theme.colors.negative })}>${formatCurrencyAmount(parseFloat(payment.amount))}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Notes: <span className={css({ color: $theme.colors.accent })}>{payment.notes}</span>
      </div>
    </Card>
  )
}