"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerErrorTypes = void 0;
var ServerErrorTypes;
(function (ServerErrorTypes) {
    ServerErrorTypes["Unauthorized"] = "UNAUTHORIZED";
    ServerErrorTypes["Internal_Server_Error"] = "INTERNAL_SERVER_ERROR";
    ServerErrorTypes["Airtable_Error"] = "AIRTABLE_ERROR";
    ServerErrorTypes["Postgres_Error"] = "POSTGRES_ERROR";
    ServerErrorTypes["SendGrid_Error"] = "SENDGRID_ERROR";
    ServerErrorTypes["Invalid_Request"] = "INVALID_REQUEST";
    ServerErrorTypes["Invalid_Username"] = "INVALID_USERNAME";
    ServerErrorTypes["Invalid_Token"] = "INVALID_TOKEN";
    ServerErrorTypes["Invalid_Refund"] = "INVALID_REFUND";
    ServerErrorTypes["Invalid_Filename"] = "INVALID_FILENAME";
    ServerErrorTypes["Invalid_Email"] = "INVALID_EMAIL";
    ServerErrorTypes["Invalid_Password"] = "INVALID_PASSWORD";
    ServerErrorTypes["Not_Found"] = "NOT_FOUND";
    ServerErrorTypes["No_Agencies"] = "NO_AGENCIES";
    ServerErrorTypes["No_Attachments"] = "NO_ATTACHMENTS";
    ServerErrorTypes["No_Clients"] = "NO_CLIENTS";
    ServerErrorTypes["No_Vendors"] = "NO_VENDORS";
    ServerErrorTypes["No_Payments"] = "NO_PAYMENTS";
    ServerErrorTypes["No_Documents"] = "NO_DOCUMENTS";
    ServerErrorTypes["No_Users"] = "NO_USERS";
    ServerErrorTypes["Duplicate_Username"] = "DUPLICATE_USERNAME";
    ServerErrorTypes["Duplicate_Record"] = "DUPLICATE_RECORD";
    ServerErrorTypes["Insufficient_Funds"] = "INSUFFICIENT_FUNDS";
    ServerErrorTypes["Insufficient_Slots"] = "INSUFFICIENT_SLOTS";
    ServerErrorTypes["Inactive_Client"] = "INACTIVE_CLIENT";
    ServerErrorTypes["Payload_Too_Large"] = "PAYLOAD_TOO_LARGE";
})(ServerErrorTypes || (exports.ServerErrorTypes = ServerErrorTypes = {}));
