import { ServerErrorTypes } from "unity-types";
import { z } from 'zod';

export const emptySchema = z.object({});

export const zodErrorSchema = z.object({ issues: z.array(z.unknown()) });
export type ZodError = z.infer<typeof zodErrorSchema>;

export const idOnlySchema = z.object({ id: z.string() });
export type IdOnlyType = z.infer<typeof idOnlySchema>;

export const serverErrorSchema = z.object({
  code: z.number(),
  type: z.nativeEnum(ServerErrorTypes),
  data: z.any().optional(),
  
  name: z.string().default(""),
  message: z.string().default("")
});
export type ServerError = z.infer<typeof serverErrorSchema>;