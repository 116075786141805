import { useStyletron } from 'baseui';
import { getTodaysImage } from '../../utils';
import React from 'react';

export const BlankContainer = ({ children }: { children: React.ReactNode }) => {
  const [css, $theme] = useStyletron();
  return (
    <div 
      className={css({
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%',
        backgroundColor: $theme.colors.white, 
      })}
    >{children}</div>
  );
}

export const RotatingImageStyledContainer = ({ children, fullscreen = false }: { children: React.ReactNode, fullscreen?: boolean; }) => {
  const [css] = useStyletron();
  const height = `${fullscreen ? window.innerHeight : window.innerHeight - 260}px`;
  return (
    <div 
      className={css({ 
        backgroundImage: `url('${getTodaysImage()}')`,
        opacity: 1,
        height: height,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%' 
      })}
    >{children}</div>
  );
}

export const StyledContainer = ({ children, fullscreen = false }: { children: React.ReactNode, fullscreen?: boolean }) => {
  const [css, $theme] = useStyletron();
  const height = `${fullscreen ? window.innerHeight : window.innerHeight - 260}px`;
  return (
    <div 
      className={css({ 
        backgroundImage: `url('${$theme.colors.background_mountains_url}')`,
        opacity: 1,
        height: height,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover', 
        display: 'flex', 
        justifyContent: 'center', 
        width: '100%' 
      })}
    >{children}</div>
  );
}
