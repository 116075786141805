import { useStyletron } from 'baseui';
import { font_med, type Font } from '../../../../../shared/styles';
import Card from '../../../../../shared/components/elements/Card';
import { formatCurrencyAmount } from '../../../../utils';
import { type NewRefund } from '../../../../slices/payment/types';

type ReviewReviewCardProps = {
  newRefund: NewRefund,
  font?: Font,
  overrides?: any,
}

export const RefundReviewCard = ({ newRefund, font = font_med, overrides = {} }: ReviewReviewCardProps) => {
  const [css, $theme] = useStyletron();
  return (
    <Card overrides={{width: 'none', padding: '10px', ...overrides }} >
      <div className={css({...font, textAlign: 'left' })}>
        Client: <span className={css({ color: $theme.colors.accent })}>{newRefund.clientName}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Payee: <span className={css({ color: $theme.colors.accent })}>{newRefund.payeeName}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Category: <span className={css({ color: $theme.colors.accent })}>{newRefund.category}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Refund Amount: <span className={css({ color: $theme.colors.positive })}>${formatCurrencyAmount(newRefund.amount * -1)}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Refund Notes: <span className={css({ color: $theme.colors.accent })}>{newRefund.notes}</span>
      </div>
    </Card>
  )
}