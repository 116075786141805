"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnumsDTOSchema = exports.pgEnumSchema = void 0;
const zod_1 = require("zod");
exports.pgEnumSchema = zod_1.z.object({
    schema: zod_1.z.string(),
    name: zod_1.z.string(),
    values: zod_1.z.string().array(),
}).strict();
exports.getEnumsDTOSchema = zod_1.z.object({
    schema: zod_1.z.string(),
}).strict();
