import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { clearNewClient, createNewClient, resetCreateClientAsyncState, getAllClients } from '../../../slices/client/client.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { getClientDetailPageUrl } from '../../../utils';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import { ClientReviewCard } from '../../cards/clients/ClientReviewCard';
import Button, { SmallWarningButton } from '../../../../shared/components/elements/Button';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import Title from '../../../../shared/components/elements/Title';
import { type ReentryAgencyExtendedInfo, type Client, type CreateClientDTO } from 'unity-types';
import { type NewClient } from '../../../slices/client/types';

type ReviewFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
};

export const ReviewForm = ({ setActiveStep }: ReviewFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: ReentryAgencyExtendedInfo | null = useSelector(state => state.reentry_portal.agencies.userAgency);
  const createClientStatus: AsyncStatus = useSelector(state => state.reentry_portal.clients.createClientStatus);
  const createClientError: string | null = useSelector(state => state.reentry_portal.clients.createClientError);
  const getAllClientsStatus: string | null = useSelector(state => state.reentry_portal.clients.getAllClientsStatus);
  const newClient: NewClient = useSelector(state => state.reentry_portal.clients.newClient!);
  const viewClient: Client | null = useSelector(state => state.reentry_portal.clients.viewClient);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);

  useEffect(() => {
    if(viewClient) {
      setSuccessModalIsOpen(true);
    }
  }, [viewClient]);

  const submit = () => {
  
    const payload: CreateClientDTO = {
      ...newClient,
      race: newClient.race[0].id,
      ethnicity: newClient.ethnicity[0].id,
      gender: newClient.gender[0].id,
      householdMembers: newClient.householdMembers[0].id,
      householdMinors: newClient.householdMinors[0].id,
    }
    dispatch(createNewClient(payload));
  }

  const OnSuccessModalClose = () => {
    if(getAllClientsStatus === AsyncStatus.Idle && userAgency) dispatch(getAllClients({ agencyId: userAgency.id }));
    if(viewClient) navigate(getClientDetailPageUrl(viewClient.id));
    setSuccessModalIsOpen(false);
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          <Title title={'Review new Client'}/>
          <FlexGridItem>
            <SmallWarningButton
              onClick={() => {
                dispatch(clearNewClient());
                setActiveStep(0);
              }}
              label={'Start Over'}
            />
          </FlexGridItem>
        </FlexGrid>
        <ClientReviewCard client={newClient} />
        <FlexGrid overrides={{ justifyContent: 'flex-end' }} >
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button
              onClick={() => submit()}
              label={'Submit'}
              isLoading={createClientStatus === AsyncStatus.Loading}
            />
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={'Client added successfully.'} 
        onClose={OnSuccessModalClose}
      />
      <NotificationModal 
        isOpen={!!createClientError} 
        message={createClientError} 
        onClose={() => dispatch(resetCreateClientAsyncState())}
      />
    </>
  );
}

export default ReviewForm;