import { useAppSelector as useSelector, useAppDispatch as useDispatch } from "../../../../../redux/hooks";
import { useStyletron } from "baseui";
import { useNavigate } from "react-router-dom";
import { clearViewPayment, resetNewRefund } from "../../../slices/payment/payment.slice";
import { font_med, font_lg, font_sm } from "../../../../shared/styles";
import { getPaymentDetailPageUrl, formatCurrencyAmount, formatDateString, getCategoryColor, CategoryColor } from "../../../utils";
import { AsyncStatus } from "../../../../shared/constants";
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type DigAgencyExtendedInfo, type Payment } from "unity-types";

export const VendorPaymentList = () => {
  const [, $theme] = useStyletron();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allPaymentsByVendor: Payment[] | null = useSelector(state => state.dig_portal.payments.allPaymentsByVendor);
  const getAllPaymentsByVendorStatus: AsyncStatus = useSelector(state => state.dig_portal.payments.getAllPaymentsByVendorStatus);

  return (
    <Card overrides={{ padding: '10px 7.5px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGridItem overrides={{ ...font_med, color: $theme.colors.white, textAlign: 'center', margin: '-10px' }}>All payments from</FlexGridItem>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          {
            userAgency && <FlexGridItem overrides={{ ...font_lg, backgroundColor: $theme.colors.white, color: $theme.colors.accent, textAlign: 'center', borderRadius: '5px', padding: '0px 5px' }}>{userAgency.name}</FlexGridItem>
          }
        </FlexGrid>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 390}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
          <FlexGrid overrides={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {
              getAllPaymentsByVendorStatus === AsyncStatus.Failed ?
              <></> : !allPaymentsByVendor ?
              <ExtraLargeSpinner /> : 
              allPaymentsByVendor.map((payment, i) => <VendorPaymentListItem payment={payment} key={i} />)
            }
          </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export type VendorPaymentListItemProps = {
  payment: Payment;
}

const VendorPaymentListItem = ({ payment }: VendorPaymentListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRefund = payment.type === 'Refund';

  return (
    <Card 
      overrides={{ 
        padding:'5px', 
        ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
        backgroundColor: $theme.colors.white,
        width: '100%'
      }} 
      onClick={() => {
        dispatch(clearViewPayment());
        dispatch(resetNewRefund());
        navigate(getPaymentDetailPageUrl(payment.id));
      }}
    >
      <FlexGrid overrides={{ ...font_sm, justifyContent: 'space-between', alignItems: 'center' }}>
        <FlexGrid overrides={{ justifyContent: 'flex-start' }}>
          <FlexGridItem>{formatDateString(payment.datePaid)}</FlexGridItem>
          <FlexGridItem overrides={{ color: $theme.colors.accent }}>{payment.clientName}</FlexGridItem>
          <FlexGridItem overrides={{ backgroundColor: `${getCategoryColor(payment.category as keyof typeof CategoryColor)}`, color: $theme.colors.white, width: 'auto', padding: '0px 5px', borderRadius: '5px' }}>{payment.category}</FlexGridItem>
        </FlexGrid>
        <FlexGridItem>
          <FlexGridItem overrides={{ ...font_med, color: isRefund ? $theme.colors.positive : $theme.colors.negative }}>${formatCurrencyAmount(payment.amount)}</FlexGridItem>
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}