import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { StyledContainer } from '../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import MyUserDashboard from '../dashboards/MyUserDashboard';
import ExtraLargeSpinner from '../../../shared/components/elements/Spinner';
import { type UnityUser } from 'unity-types';

const HomePage = () => {
  const allUnityUsers: UnityUser[] | null = useSelector(state => state.admin_portal.users.allUnityUsers);

  return (
    <StyledContainer>
        {
          allUnityUsers ?
          <FlexGridItem>
            <FlexGrid>
              <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }} >
                <MyUserDashboard />
              </FlexGrid>
            </FlexGrid>
          </FlexGridItem> : <ExtraLargeSpinner/>
        }
    </StyledContainer>
  );

}

export default HomePage;
