import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { authSlice } from './auth/auth.slice';
import registerReducer, { registerSlice } from './register/register.slice';
import usersReducer, { usersSlice } from './user/user.slice';
import agencyReducer, { agenciesSlice } from './agency/agency.slice';
import rolesReducer, { rolesSlice } from './role/role.slice';
import themeReducer, { themeSlice } from './theme/theme.slice';

export default combineReducers({
  [authSlice.name]: authReducer,
  [usersSlice.name]: usersReducer,
  [rolesSlice.name]: rolesReducer,
  [registerSlice.name]: registerReducer,
  [agenciesSlice.name]: agencyReducer,
  [themeSlice.name]: themeReducer
});