import { BLACK } from './colors';

export type Font = { 
  fontSize: string, 
  [key: string]: any,
};

export const font_extra_sm: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '200',
  fontSize: '17px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_sm: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '20px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_sm_reg: Font = {
  ...font_sm,
  textTransform: 'none',
  fontStretch: 'condensed',
};

export const font_med: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '24px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_med_reg: Font = {
  ...font_med,
  textTransform: 'none',
  fontStretch: 'condensed',
};

export const font_lg: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '32px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_med_lg: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '42px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_extra_lg: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '48px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_sm_giant: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '84px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
}

export const font_giant: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '500',
  fontSize: '120px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const font_giant_reg: Font = {
  ...font_giant,
  textTransform: 'none',
  fontStretch: 'condensed',
}

export const input_font: Font = {
  fontFamily: 'Franklin Gothic Light, sans-serif',
  fontWeight: '50',
  fontSize: '20px',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
};

export const special_font: Font = {
  fontFamily: 'Georgia, serif',
  fontWeight: '500',
  fontSize: '38px',
  textTransform: 'uppercase',
  textDecoration: 'none',
  color: BLACK,
  fontStretch: 'condensed'
}

export const notification_font: Font = { 
  fontFamily: 'Georgia, serif',
  fontSize: '26px',
  lineHeight: '32px',
  textTransform: 'none'
}