import { useEffect, useState } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { getPayment } from '../../../slices/payment/payment.slice';
import { getVendor } from '../../../slices/vendor/vendor.slice';
import { escapeCharactersForFormulaRegex } from '../../../utils';
import { BlankContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import PaymentHeaderCard from '../../cards/payments/PaymentHeaderCard';
import ClientLinkCard from '../../cards/payments/ClientLinkCard';
import { PaymentInfoCard } from '../../cards/payments/PaymentInfoCard';
import SiblingPaymentCard from '../../cards/payments/SiblingPaymentCard';
import PaymentControls from '../../cards/payments/PaymentControls';
import PayeeLinkCard from '../../cards/payments/PayeeLinkCard';
import ViewAttachmentCard from '../../cards/attachments/ViewAttachmentCard';
import Button from '../../../../shared/components/elements/Button';
import { type DigAgencyExtendedInfo, type Payment, type Vendor } from 'unity-types';

const PaymentDetailPage = () => {
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);
  const viewVendor: Vendor | null = useSelector(state => state.dig_portal.vendors.viewVendor);
  const [hasSibling, setHasSibling] = useState<boolean>(false);
  const [hasReceipt, setHasReceipt] = useState<boolean>(false);

  useEffect(() => {
    if(!viewPayment && userAgency) dispatch(getPayment({ id: recordId, agencyId: userAgency.id }));
  }, [viewPayment, userAgency, dispatch, recordId]);

  useEffect(() => {
    if(viewPayment && !viewVendor) dispatch(getVendor({ name: escapeCharactersForFormulaRegex(viewPayment.payeeName) }));
  }, [viewPayment, viewVendor, dispatch]);

  useEffect(() => {
    if(viewPayment) {
      if(viewPayment.originalPaymentId !== null || viewPayment.refundPaymentId !== null) setHasSibling(true);
      if(viewPayment.receiptAttachmentId != null) setHasReceipt(true);
    }
  }, [viewPayment])

  return (
    <BlankContainer>
      {
        (viewPayment && viewVendor) ?
        <FlexGrid overrides={{ justifyContent: 'flex-start', margin: '10px' }}>
          <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-start', rowGap: '10px', width: '500px' }}>
            <PaymentHeaderCard />
            <FlexGrid overrides={{ columnGap: '10px'}}>
              { hasSibling ? <SiblingPaymentCard setHasReceipt={setHasReceipt} /> : <PaymentControls /> }
            </FlexGrid>
            <FlexGrid><ClientLinkCard/></FlexGrid>
          </FlexGrid>
          <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-start', rowGap: '10px', width: '600px' }}>
            <PaymentInfoCard />
            <FlexGrid overrides={{ flexDirection: 'column' }}>
              { hasReceipt && <ViewAttachmentCard id={viewPayment.receiptAttachmentId!} title={'View Receipt on file'} /> }
              <Button label={hasReceipt ? 'Upload New Receipt (will replace existing one)' : 'Upload Receipt'} onClick={() => navigate('/dig-portal/payments/upload-receipt')} />
            </FlexGrid>
          </FlexGrid>
          <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'flex-start', rowGap: '10px', width: 'auto' }}>
            <PayeeLinkCard />
          </FlexGrid>
        </FlexGrid> : <FlexGrid overrides={{ margin: '10px' }}><ExtraLargeSpinner/></FlexGrid>
      }
    </BlankContainer>
  );
}

export default PaymentDetailPage;
