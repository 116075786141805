import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ChangeStandardAllocationForm from '../../forms/agencies/ChangeStandardAllocationForm';
import StandAlonePageContainer from '../../../../shared/components/layout/StandAlonePageContainer';

const ChangeStandardAllocationPage = () => {

  return (
    <StyledContainer>
      <StandAlonePageContainer>
        <FlexGrid overrides={{ marginTop: '10px', justifyContent: 'center' }}>
          <FlexGridItem>
            <ChangeStandardAllocationForm />
          </FlexGridItem>
        </FlexGrid>
      </StandAlonePageContainer>
    </StyledContainer>
  )
}

export default ChangeStandardAllocationPage;
