"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reportTypesSchema = exports.generateReportDTOSchema = void 0;
const zod_1 = require("zod");
const constants_js_1 = require("../constants.js");
const constants_js_2 = require("../../constants.js");
exports.generateReportDTOSchema = zod_1.z.object({
    schema: constants_js_1.portalNameSchema,
    view: zod_1.z.string(),
}).strict();
exports.reportTypesSchema = zod_1.z.record(zod_1.z.nativeEnum(constants_js_2.PortalName), zod_1.z.array(zod_1.z.string()));
