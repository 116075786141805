import React from 'react';
import { useStyletron } from 'baseui';
import { FlexGrid, FlexGridItem } from './FlexGrid';

const StandAlonePageContainer = ({ children }: { children: React.ReactNode }) => {
  const [, $theme] = useStyletron();
  return (
    <FlexGrid overrides={{ margin: '-37.5px 10px 10px 10px', flexDirection: 'column', justifyContent: 'space-left' }}>
      <FlexGridItem overrides={{ backgroundColor: $theme.colors.light_grey, height: '42px', marginLeft: '-10px', marginRight: '-10px' }}></FlexGridItem>
      {children}
    </FlexGrid>
  )
}

export default StandAlonePageContainer;