
import { ServerErrorType, ServerErrorTypes } from "unity-types";

// statusus for async actions
export enum AsyncStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeeded',
  Failed = 'failed',
}

// human-readable server error messages
export const ERROR_MESSAGES: { [key in ServerErrorType]: string } = {
  [ServerErrorTypes.Unauthorized]: 'You are not authorized to make this request.',
  [ServerErrorTypes.Internal_Server_Error]: 'An internal server error occurred. Please try again later.',
  [ServerErrorTypes.Airtable_Error]: 'An error occurred while communicating with the database. Please try again later.',
  [ServerErrorTypes.Postgres_Error]: 'An error occurred while communicating with the database. Please try again later.',
  [ServerErrorTypes.SendGrid_Error]: 'An error occurred while sending an email. Please try again later.',
  [ServerErrorTypes.Invalid_Request]: 'The request you made was invalid. Please try again.',
  [ServerErrorTypes.Invalid_Username]: 'The username you provided does not exist.',
  [ServerErrorTypes.Invalid_Token]: 'Invalid token, you are not authorized to make this request.',
  [ServerErrorTypes.Invalid_Refund]: 'The refund you requested is invalid. Please try again.',
  [ServerErrorTypes.Invalid_Filename]: 'The filename you provided is invalid. Please try again.',
  [ServerErrorTypes.Invalid_Email]: 'The username you provided is not a valid email address.',
  [ServerErrorTypes.Invalid_Password]: 'The password you provided was invalid, please try again.',
  [ServerErrorTypes.Not_Found]: 'The resource you requested was not found.',
  [ServerErrorTypes.No_Agencies]: 'You are not currently assigned to an agency. Please contact us for assistance.',
  [ServerErrorTypes.No_Attachments]: 'No attachments found.',
  [ServerErrorTypes.No_Clients]: `No client found. Either they don't exist or aren't assigned to your agency.`,
  [ServerErrorTypes.No_Vendors]: 'We are unable to find a vendor with that name.',
  [ServerErrorTypes.No_Payments]: `No payment found. Either it doesn't exist, or isn't assigned to your agency.`,
  [ServerErrorTypes.No_Documents]: 'No document found.',
  [ServerErrorTypes.No_Users]: 'There are no users currently assigned to this agency.',
  [ServerErrorTypes.Duplicate_Username]: 'A user with that username already exists.',
  [ServerErrorTypes.Duplicate_Record]: 'A record already exists with the properties submitted.',
  [ServerErrorTypes.Insufficient_Funds]: 'There are not enough client funds remaining to create this payment.',
  [ServerErrorTypes.Insufficient_Slots]: 'There are no slots remaining, unable to create a new client.',
  [ServerErrorTypes.Inactive_Client]: 'Cannot issue a payment against an inactive client.',
  [ServerErrorTypes.Payload_Too_Large]: 'The file you are trying to upload is too large. Please try again with a smaller file.',
}

// field validation messages
export enum FieldValidationMessage {
  RequiredField = 'Required Field',
  InvalidInput = 'Invalid Input',
  AllFieldsRequired = 'All fields are required',
}

// static image file urls
export const LOGO_BLUE_URL = '/images/logos/logo.png';
export const LOGO_MOUNTAINS_BACKGROUND_BLUE_URL = '/images/logos/logo_mountains_background.png';
export const LOGO_PURPLE_URL = '/images/logos/logo_purple.png';
export const LOGO_MOUNTAINS_BACKGROUND_PURPLE_URL = '/images/logos/logo_mountains_background_purple.png';
export const LOGO_PINK_URL = '/images/logos/logo_pink.png';
export const LOGO_MOUNTAINS_BACKGROUND_PINK_URL = '/images/logos/logo_mountains_background_pink.png';
export const LOGO_MOCHA_URL = '/images/logos/logo_mocha.png';
export const LOGO_MOUNTAINS_BACKGROUND_MOCHA_URL = '/images/logos/logo_mountains_background_mocha.png';
export const LOGO_WHITE_SM_URL = '/images/logos/logo_white_sm.png';
export const BACKGROUND_IMAGE_URLS = [
  '/images/alaska_life/alaska_state_capitol.jpg',
  '/images/alaska_life/berries.jpg',
  '/images/alaska_life/castner_ice_cavern.jpg',
  '/images/alaska_life/fireweed.jpg',
  '/images/alaska_life/juneau.jpg',
  '/images/alaska_life/ketchikan.jpg',
  '/images/alaska_life/portage_rd.jpg',
];
export const ICON_QUESTION_MARK_URL = '/images/icons/question_mark_icon.png';
