import { combineReducers } from '@reduxjs/toolkit';
import agenciesReducer, { agenciesSlice } from './agency/agency.slice';
import clientsReducer, { clientsSlice } from './client/client.slice';
import schemaReducer, { schemaSlice } from './schema/schema.slice';
import vendorsReducer, { vendorsSlice } from './vendor/vendor.slice';
import paymentsReducer, { paymentsSlice } from './payment/payment.slice';
import usersReducer, { usersSlice } from './user/user.slice';
import attachmentsReducer, { attachmentsSlice } from './attachment/attachment.slice';

export default combineReducers({
  [agenciesSlice.name]: agenciesReducer,
  [clientsSlice.name]: clientsReducer,
  [schemaSlice.name]: schemaReducer,
  [vendorsSlice.name]: vendorsReducer,
  [paymentsSlice.name]: paymentsReducer,
  [usersSlice.name]: usersReducer,
  [attachmentsSlice.name]: attachmentsReducer,
});
