import { useEffect } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getClientDetailPageUrl } from '../../../utils';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import SearchClientForm from '../../forms/clients/SearchClientForm';
import { type Client } from 'unity-types';

const SearchClientPage = () => {
  const navigate = useNavigate();
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);

  useEffect(() => {
    if(viewClient) {
      navigate(getClientDetailPageUrl(viewClient.id));
    }
  }, [viewClient, navigate]);

  return (
    <StyledContainer>
      <SearchClientForm title={'Search Clients'} />
    </StyledContainer>
  )
}

export default SearchClientPage;
