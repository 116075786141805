import { GREY, BLACK, BROWN } from '../shared/styles';
import { type SelectOption } from '../shared/types';

import {
  Payment,
  Client,
  DigAgencyExtendedInfo,
  AuthenticatedUser
} from 'unity-types';

export const convertToCamelCase = (str: string): string => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const formatCurrencyAmount = (number: number): string => {
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const calculatePaymentBreakdownByCategory = (payments: Payment[]): {
  totalDeposits: number,
  totalRent: number,
  totalStabilization: number,
  percentDeposits: number,
  percentRent: number,
  percentStabilization: number,
  totalPaid: number
} => {
  let totalPaid = 0;
  const totals = { totalDeposits: 0, totalRent: 0, totalStabilization: 0 };
  payments.forEach(payment => {
    const amount = payment.amount;
    if(payment.category === 'Deposit') totals.totalDeposits += amount;
    if(payment.category === 'Rent') totals.totalRent += amount;
    if(payment.category === 'Stabilization') totals.totalStabilization += amount;
    totalPaid += amount;
  });

  type Percents = { percentDeposits: number, percentRent: number, percentStabilization: number };
  const percents: Percents = {
    percentDeposits: totalPaid === 0 ? 0 : (totals.totalDeposits / totalPaid) * 100,
    percentRent: totalPaid === 0 ? 0 : (totals.totalRent / totalPaid) * 100,
    percentStabilization: totalPaid === 0 ? 0 : (totals.totalStabilization / totalPaid) * 100
  }
  const roundUp = percents.percentDeposits !== 0 ? 'percentDeposits' : percents.percentRent !== 0 ? 'percentRent' : 'percentStabilization';
  for(const key in percents) {
    const index = key as keyof Percents;
    if(index === roundUp) { 
      percents[index] = Math.ceil(percents[index]);
    } else {
      percents[index] = Math.floor(percents[index]);
    }
  }

  return { ...totals, ...percents, totalPaid };
}

export enum CategoryColor {
  // @ts-ignore
  Deposit = GREY,
  // @ts-ignore
  Rent = BROWN,
  // @ts-ignore
  Stabilization = BLACK
}
export const getCategoryColor = (category: keyof typeof CategoryColor): CategoryColor => {
  return CategoryColor[category];
}

export type FundsBreakdownByClient = {
  [key: string]: {
    obligatedFunds: number,
    percent: number
  }
}

export const calculateObligatedFundsBreakdownByClient = (totalObligatedFunds: number, clients: Client[]): FundsBreakdownByClient => {
  const total = totalObligatedFunds;
  const result: FundsBreakdownByClient = {};

  let totalCalculatedPoints = 0;
  clients.forEach(client => {
    const obligatedFunds =  client.obligatedFunds;
    const percent = Math.floor((obligatedFunds/total) * 100);
    result[client.id] = { 
      obligatedFunds: obligatedFunds,
      percent: percent
    };
    totalCalculatedPoints += percent;
  });

  // Due to rounding there will usually be points left over
  const remainder = 100 - totalCalculatedPoints;

  if(remainder > 0) {
    // Find the client with the smallest non-zero share and tack em on.
    let targetClientId: string | undefined;
    let minimum: number | undefined;
    for(const clientId in result) {
      const percent = result[clientId].percent;
      if(percent === 0) continue;
      if(minimum === undefined || percent < minimum) {
        minimum = result[clientId].percent;
        targetClientId = clientId;
      }
    }
    if (targetClientId) result[targetClientId].percent += remainder;
  }

  return result;
}

export const getRandomColor = () => {
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * 10);
  }
  return color;
}

export const calculateRequiredFundsForNextSlot = (agency: DigAgencyExtendedInfo): string => {
  const requiredFunds = agency.standardAllocation - (agency.fundsRemaining % agency.standardAllocation)
  return requiredFunds.toLocaleString();
}

export const getClientDetailPageUrl = (recordId: string): string => '/dig-portal/clients/detail/' + recordId;

export const getVendorDetailPageUrl = (recordId: string): string => '/dig-portal/vendors/detail/' + recordId;

export const getPaymentDetailPageUrl = (recordId: string): string => '/dig-portal/payments/detail/' + recordId;

export const getUserDetailPageUrl = (recordId: string): string => '/dig-portal/users/detail/' + recordId;

export const getAgencyDetailPageUrl = (recordId: string): string => '/dig-portal/agencies/detail/' + recordId;

export const formatDateString = (str: string): string => {
  const dateObj = new Date(str);
  return dateObj.toLocaleDateString('en-US', {year:'2-digit', month:'2-digit', day:'2-digit'});
}

export const buildSelectOptions = (options: string[]): SelectOption[] => {
  const selectOptions = options.map((value: any) => {
    return {
      id: value,
      label: value
    }
  });
  return selectOptions;
}

export const escapeCharactersForFormulaRegex = (str: string): string => {
  const specialChars = ["'"]; // add more as they come up
  specialChars.forEach(char => {
    str = str.replaceAll(char, '\\' + char);
  });
  return str;
}

export const hasPortalAccess = (authenticatedUser: AuthenticatedUser | null): boolean => {

  // check authenticated user exists
  if(authenticatedUser === null) return false;

  // check if user has dig portal role
  if(authenticatedUser.dig_portal === undefined) return false;
  
  // check if user has active dig portal role
  if(authenticatedUser.dig_portal.isActive === false) return false;

  return true;
}
