import { font_lg } from './fonts';

export * from './colors';
export * from './fonts';
export * from './button';
export * from './link';

export enum SIZE {
  SMALL = 'sm',
  MEDIUM = 'med',
  LARGE = 'lg',
  EXTRA_LARGE = 'extra_lg'
}

export const flex_grid = {
  display: 'flex', 
  width: '100%', 
  justifyContent: 'space-between', 
  columnGap: '10px',
  rowGap: '10px'
}

export const logo_sm = {
  width: '150px',
  borderRadius: '10px',
  padding: '8px'
};

export const logo_med = {
  width: '200px',
  borderRadius: '20px', 
  padding: '10px'
};

 export const info_modal = {
  ...font_lg,
  padding: '15px',
  borderRadius: '7px',
  align: 'center'
 }

 export const warning_modal = {
  ...font_lg,
  padding: '15px',
  borderRadius: '7px',
  align: 'center'
}