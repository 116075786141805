import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom'
import { resetNewRefund, createNewRefund, resetAsyncPaymentState, clearViewPayment } from '../../../../slices/payment/payment.slice';
import { getPaymentDetailPageUrl } from '../../../../utils';
import { AsyncStatus } from '../../../../../shared/constants';
import { RefundReviewCard } from '../../../cards/payments/refunds/RefundReviewCard';
import { FlexGrid, FlexGridItem } from '../../../../../shared/components/layout/FlexGrid';
import Button, { SmallWarningButton } from '../../../../../shared/components/elements/Button';
import Title from '../../../../../shared/components/elements/Title';
import { FormCard as Card } from '../../../../../shared/components/elements/Card';
import { NotificationModal } from '../../../../../shared/components/elements/Modal';
import { type NewRefund } from '../../../../slices/payment/types';

type ReviewFormFormProps = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
};

export const ReviewForm = ({ setActiveStep }: ReviewFormFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newRefund: NewRefund | null = useSelector(state => state.reentry_portal.payments.newRefund);
  const newRefundRecordId: string | null = useSelector(state => state.reentry_portal.payments.newRefundRecordId);
  const refundStatus: AsyncStatus = useSelector(state => state.reentry_portal.payments.status);
  const error: string | null = useSelector(state => state.reentry_portal.payments.error);

  const submit = () => {
    if(!newRefund) return;
    const payload = {
      originalPaymentId: newRefund.originalPaymentId,
      notes: newRefund.notes
    }
    dispatch(createNewRefund(payload));
  }

  return (
    newRefund &&
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', maxWidth: '420px' }} >
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          <Title title={'Review new Refund'}/>
          <FlexGridItem>
            <SmallWarningButton
              onClick={() => {
                dispatch(resetNewRefund());
                setActiveStep(0);
              }}
              label={'Start Over'}
            />
          </FlexGridItem>
        </FlexGrid>
        <RefundReviewCard newRefund={newRefund} />
        <FlexGrid overrides={{ justifyContent: 'flex-end' }} >
          <FlexGridItem overrides={{ marginTop: '10px' }}>
            <Button
              onClick={() => submit()}
              label={'Submit'}
              isLoading={refundStatus === AsyncStatus.Loading}
            />
          </FlexGridItem>
        </FlexGrid>
      </Card>
      <NotificationModal 
        isOpen={!!newRefundRecordId} 
        message={`Refund processed successfully.`} 
        onClose={() => {
          dispatch(resetNewRefund());
          dispatch(clearViewPayment());
          navigate(getPaymentDetailPageUrl(newRefundRecordId!))
        }}
      />
      <NotificationModal 
        isOpen={!!error} 
        message={error} 
        onClose={() => dispatch(resetAsyncPaymentState())}
      />
    </>
  );
}

export default ReviewForm;