import { useEffect } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getPaymentDetailPageUrl } from '../../../utils';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import SearchPaymentForm from '../../forms/payments/SearchPaymentForm';
import { type Payment } from 'unity-types';

const SearchPaymentPage = () => {
  const navigate = useNavigate();
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);

  useEffect(() => {
    if(viewPayment) {
      navigate(getPaymentDetailPageUrl(viewPayment.id));
    }
  }, [viewPayment, navigate]);

  return (
    <StyledContainer>
      <SearchPaymentForm title={'Search Payments'} />
    </StyledContainer>
  )
}

export default SearchPaymentPage;
