import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { assignRole, getAllUnityUsers, resetAssignRoleAsyncState, clearState } from '../../../slices/user/user.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import Button from '../../../../shared/components/elements/Button';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import Select from '../../../../shared/components/elements/Select';
import { getAllRoles } from '../../../../shared/slices/role/role.slice'
import { SelectOption } from '../../../../shared/types';
import { AssignRoleDTO, PortalName, Role, type UnityUser } from 'unity-types';
import { useNavigate } from 'react-router-dom';

const AssignRoleForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewUser: UnityUser = useSelector(state => state.admin_portal.users.viewUnityUser!);
  const [role, setRole] = useState<SelectOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);
  const assignRoleStatus: AsyncStatus = useSelector(state => state.admin_portal.users.assignRoleStatus);
  const assignRoleError: string | null = useSelector(state => state.admin_portal.users.assignRoleError);
  const allRoles: Role[] | null = useSelector(state => state.shared.roles.allRoles);
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (allRoles.length === 0) {
      dispatch(getAllRoles());
    }
    else {
      const options: SelectOption[] = allRoles!.map(role => {
        return {
          id: role.id,
          label: role.name
        };
      });
      setRoleOptions(options);
    }
  }, [dispatch, allRoles]);

  useEffect(() => {
    if (assignRoleStatus === AsyncStatus.Succeeded) {
      setSuccessModalIsOpen(true);
    }
  }, [assignRoleStatus]);

  const validateAndSubmit = () => {
  
    if (viewUser.roles.find((currentRole: PortalName) => currentRole === role[0].id)) {
      setValidationError(`${viewUser.username} already has the role "${role[0].label}".`);
      return;
    }

    const dto: AssignRoleDTO = {
      portalId: role[0].id as PortalName,
    };
    const payload = {
      userId: viewUser.id,
      dto: dto
    }

    dispatch(assignRole(payload));
  }

  return (
    <FlexGrid overrides={{ width: '400px'}}>
      <FlexGrid overrides={{ flexDirection: 'column' }}>
        <Select
          value={role}
          label={'Choose a role'}
          onChange={params => setRole(params.value as SelectOption[])}
          options={roleOptions}
          isLoading={false}
          error={null}
        />
        <Button 
          label={'Add Role'} 
          onClick={() => validateAndSubmit()} 
          isLoading={assignRoleStatus === AsyncStatus.Loading} 
          disabled={
            validationError !== undefined ||
            role.length === 0
          }
        />
      </FlexGrid>
    <NotificationModal 
      isOpen={!!validationError} 
      message={validationError} 
      onClose={() => setValidationError(undefined)}
    />
    {
      role.length > 0 &&
      <NotificationModal 
        isOpen={successModalIsOpen} 
        message={`Successfully assigned the "${role[0].label}" role to ${viewUser.username}.`} 
        onClose={() => {
          dispatch(clearState());
          dispatch(getAllUnityUsers({}));
          navigate('/admin-portal')
        }}
      />
    }
    <NotificationModal 
      isOpen={!!assignRoleError} 
      message={assignRoleError} 
      onClose={() => dispatch(resetAssignRoleAsyncState())}
    />
    </FlexGrid>
  );
}

export default AssignRoleForm;