import { useState, useEffect } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { registerNewUser, clearState as ClearRegisterState } from '../../slices/register/register.slice';
import { getAllAgencies } from '../../slices/agency/agency.slice';
import { AsyncStatus, FieldValidationMessage } from '../../constants';
import { capitalize } from '../../utils';
import { notification_font } from '../../styles';
import { RotatingImageStyledContainer } from '../layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import Card from '../elements/Card';
import { SmallWhiteLogo } from '../elements/Logo';
import Input from '../elements/Input';
import Button, { LargeButton } from '../elements/Button';
import Select from '../elements/Select';
import { NotificationModal } from '../elements/Modal';
import { type AuthenticatedUser, type User, type Agency, type RegisterUserDTO } from 'unity-types';
import { type SelectOption } from '../../types';

const RegisterPage =  () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitRegisterStatus: AsyncStatus = useSelector(state => state.shared.register.status);
  const submitRegisterError: string | null = useSelector(state => state.shared.register.error);
  const newUser: User | null = useSelector(state => state.shared.register.newUser);
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const getAllAgenciesStatus: AsyncStatus = useSelector(state => state.shared.agencies.allAgenciesStatus);
  const getAllAgenciesError: string | null = useSelector(state => state.shared.agencies.allAgenciesError);
  const allAgencies: Agency[] | null = useSelector(state => state.shared.agencies.allAgencies);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [agency, setAgency] = useState<SelectOption[]>([]);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [agencySelectOptions, setAgencySelectOptions] = useState<SelectOption[]>([]);
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    if (authenticatedUser) {
      navigate('/');
    }
  }, [authenticatedUser, navigate]);

  useEffect(() => {
    if (getAllAgenciesStatus === AsyncStatus.Idle) {
      dispatch(getAllAgencies());
    }
      
    if (allAgencies) {
      const options = allAgencies.map(agency => ({ id: agency.id, label: agency.name }));
      setAgencySelectOptions(options);
    }
  }, [getAllAgenciesStatus, getAllAgenciesError, allAgencies, dispatch]);

  const validateAndSubmit = () => {
    if ([firstName, lastName, agency, username, password].includes('')) {
      return setValidationError(FieldValidationMessage.AllFieldsRequired);
    }

    if (username.includes(';') || username.includes("'") || username.includes("--")) {
      return setValidationError('Username includes invalid characters.')
    }

    const dto: RegisterUserDTO = {
      firstName: capitalize(firstName.trim()),
      lastName: capitalize(lastName.trim()),
      agencyId: agency[0].id,
      username: username.trim(),
      password: password.trim()
    }

    dispatch(registerNewUser(dto));
  }

  return (
    <RotatingImageStyledContainer fullscreen={true}>
      <FlexGridItem overrides={{ marginTop: '20px' }}>
        <Card overrides={{ backgroundColor: $theme.colors.reentry_accent, color: $theme.colors.white, borderColor: $theme.colors.white, width: '600px', padding: '10px', height: 'auto' }}>
          <FlexGrid>
            <SmallWhiteLogo />
            <FlexGridItem overrides={{ textAlign: 'right', alignItems: 'center' }}>
              <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Create an Account</div>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px', marginTop: '40px'}}>
              <Input value={firstName} label={'First Name'} onChange={e => setFirstName(e.target.value)} />
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px' }}>
              <Input value={lastName} label={'Last Name'} onChange={e => setLastName(e.target.value)} />
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px' }}>
              <Select value={agency} label={'Agency'} onChange={params => setAgency(params.value as SelectOption[])} options={agencySelectOptions} isLoading={agencySelectOptions.length === 0} />
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px' }}>
              <Input value={username} label={'Username'} caption={'Your username must be a valid email address'} onChange={e => setUsername(e.target.value)} />
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px' }}>
              <Input value={password} label={'Password'} onChange={e => setPassword(e.target.value)} type={'password'}/>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px', marginTop: '10px' }}>
              <LargeButton 
                onClick={() => validateAndSubmit()} 
                label={'Register'} 
                isLoading={submitRegisterStatus === AsyncStatus.Loading} 
                disabled={validationError != null} 
                overrides={{ 
                  backgroundColor: $theme.colors.reentry_accent,
                  ':hover': {
                    borderColor: $theme.colors.reentry_accent,
                    color: $theme.colors.reentry_accent,
                    backgroundColor: $theme.colors.white
                  },
                }}/>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '300px', marginTop: '10px', marginBottom: '40px' }}>
              <Button 
                onClick={() => navigate('/login')} 
                label={'Back'} 
                overrides={{ 
                  backgroundColor: $theme.colors.reentry_accent,
                  ':hover': {
                    borderColor: $theme.colors.reentry_accent,
                    color: $theme.colors.reentry_accent,
                    backgroundColor: $theme.colors.white
                  },
                }}/>
            </FlexGridItem>
          </FlexGrid>
        </Card>
      </FlexGridItem>
      { 
        newUser && 
        <NotificationModal 
          isOpen={submitRegisterStatus === AsyncStatus.Succeeded} 
          message={`${newUser.firstName}, your account has been created successfully.`} 
          onClose={() => {
            dispatch(ClearRegisterState());
            navigate('/login');
          }}
        />
      }
      <NotificationModal 
        isOpen={!!submitRegisterError} 
        message={submitRegisterError} 
        onClose={() => dispatch(ClearRegisterState())}
      />
      <NotificationModal 
        isOpen={!!validationError} 
        message={validationError} 
        onClose={() => setValidationError(undefined)}
      />
    </RotatingImageStyledContainer>
  );
}

export default RegisterPage;
