import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getAgencyDetailPageUrl, formatCurrencyAmount } from '../../../utils';
import { updateCurrentAgency, clearUserAgency, resetUpdateAgencyAsyncState, getCurrentAgency } from '../../../slices/agency/agency.slice';
import { AsyncStatus } from '../../../../shared/constants';
import { validateAmountField } from './validate';
import { InfoCard as Card } from '../../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { LargeButton } from '../../../../shared/components/elements/Button';
import { HelpTextModal, NotificationModal } from '../../../../shared/components/elements/Modal';
import Input from '../../../../shared/components/elements/Input';
import { type DigAgencyExtendedInfo } from 'unity-types';
import { InfoIcon } from '../../../../shared/components/elements/Icon';
import { notification_font, font_extra_lg, font_med_lg } from '../../../../shared/styles';

const ChangeStandardAllocationForm = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const updateAgencyStatus: AsyncStatus = useSelector(state => state.dig_portal.agencies.updateAgencyStatus);
  const updateAgencyError: string | null = useSelector(state => state.dig_portal.agencies.updateAgencyError);
  const [standardAllocationValue, setStandardAllocationValue] = useState('');
  const [standardAllocationError, setStandardAllocationError] = useState<string>();
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);
  const [helpTextModalIsOpen, setHelpTextModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userAgency) {
      setStandardAllocationValue(userAgency.standardAllocation.toString());
    }
  }, [userAgency]);

  useEffect(() => {
    if (updateAgencyStatus === AsyncStatus.Succeeded) {
      setSuccessModalIsOpen(true);
    }
  }, [updateAgencyStatus]);

  const validateAndSubmit = () => {
    if (!standardAllocationValue || !userAgency) return;
    const standardAllocation = parseFloat(standardAllocationValue);
    if (standardAllocation > userAgency.fundsRemaining) {
      return setValidationError(`You cannot set the Standard Allocation to be larger than the funds remaining. The
      maximum you can set the Standard Allocation is $${formatCurrencyAmount(userAgency.fundsRemaining)}.`)
    }
    const payload = {
      standardAllocation: standardAllocation,
    }
    dispatch(updateCurrentAgency(payload));
  }

  return (
    userAgency &&
    <FlexGrid overrides={{ width: '100%' }}>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', height: 'auto', width: '600px' }}>
        <FlexGrid>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>set the</div>
            <div className={css({ ...font_extra_lg, color: $theme.colors.white, textAlign: 'left', alignItems: 'center' })}>standard allocation</div>
          </FlexGridItem>
          <InfoIcon onClick={() => setHelpTextModalIsOpen(true)} />
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px', marginTop: '40px', width: '400px' }}>
            <Input
              value={standardAllocationValue}
              label={'Standard Allocation'}
              onChange={(e) => {
                setStandardAllocationError(undefined);
                setStandardAllocationValue(e.target.value);
              }}
              onBlur={() => {
                setStandardAllocationError(undefined);
                const [isValid, error] = validateAmountField(standardAllocationValue);
                if (!isValid && error) return setStandardAllocationError(error);
              }}
              error={standardAllocationError}
            />
            <FlexGridItem overrides={{ marginTop: '20px', marginBottom: '40px' }}>
              <LargeButton
                label={'Submit'}
                onClick={validateAndSubmit}
                isLoading={updateAgencyStatus === AsyncStatus.Loading}
                disabled={
                  validationError !== undefined ||
                  standardAllocationError !== undefined ||
                  !standardAllocationValue ||
                  parseFloat(standardAllocationValue) === userAgency.standardAllocation
                }
              />
            </FlexGridItem>
          </FlexGrid>
        </FlexGrid>
      </Card>
      <NotificationModal
        isOpen={!!validationError}
        message={validationError}
        onClose={() => setValidationError('')}
      />
      <NotificationModal
        isOpen={successModalIsOpen}
        message={`Successfully changed the Standard Allocation to $${formatCurrencyAmount(parseFloat(standardAllocationValue))}.`}
        onClose={() => {
          dispatch(resetUpdateAgencyAsyncState());
          navigate(getAgencyDetailPageUrl(userAgency.id));
          dispatch(clearUserAgency());
          dispatch(getCurrentAgency());
        }}
      />
      <NotificationModal
        isOpen={!!updateAgencyError}
        message={updateAgencyError}
        onClose={() => dispatch(resetUpdateAgencyAsyncState())}
      />
      <HelpTextModal isOpen={helpTextModalIsOpen} setIsOpen={setHelpTextModalIsOpen}>
        <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.white }}>Changing the Standard Allocation:</FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>
          This form allows you to set the "standard allocation", which is the amount of funds that will be automatically assigned to a new client upon creation.
          Please note that this action will not change the "obligated Funds" for any existing clients; it will only affect clients created after this change is made.
          Additionally, changing the standard allocation will impact the number of available slots for new clients,based on the remaining funds in the program.
        </FlexGridItem>
        <FlexGridItem overrides={{ ...notification_font, marginTop: '10px', color: $theme.colors.white }}>NOTE: Requires "team_lead" role.</FlexGridItem>
      </HelpTextModal>
    </FlexGrid>
  );
}

export default ChangeStandardAllocationForm;