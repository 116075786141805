import { useState } from 'react';
import { Modal, SIZE } from "baseui/modal";
import { useStyletron } from 'baseui';
import { info_modal, warning_modal, notification_font, font_sm_giant } from "../../../shared/styles";
import Title from './Title';
import { FlexGrid, FlexGridItem } from "../layout/FlexGrid";
import Button, { WarningButton } from './Button';
import Input from "./Input";
import Card from './Card';
import { SmallWhiteLogo } from './Logo';

export type ModalBaseProps = {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const HelpTextModal = ({ isOpen, setIsOpen, children, overrides = {} }: ModalBaseProps & { children: React.ReactNode, overrides?: any }) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <Modal 
      onClose={() => setIsOpen(false)} 
      isOpen={isOpen}
      closeable={true}
      overrides={{
        Dialog: {
          style: () => ({
            ...info_modal,
            backgroundColor: $theme.colors.black,
            width: '800px',
            ...overrides
          })
        },
        Close: { style: () => ({ display: "none" }) }
      }}
    >{children}</Modal>
  );
}

export const InfoModal = ({ isOpen, setIsOpen, children, overrides = {} }: ModalBaseProps & { children: React.ReactNode, overrides?: any }) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <Modal 
      onClose={() => setIsOpen(false)} 
      isOpen={isOpen}
      closeable={true}
      overrides={{
        Dialog: {
          style: () => ({
            ...info_modal,
            backgroundColor: $theme.colors.accent,
            ...overrides
          })
        },
        Close: { style: () => ({ display: "none" }) }
      }}
    >{children}</Modal>
  );
}

export const WarningModal = ({ isOpen, setIsOpen, children, overrides }: ModalBaseProps & { children: React.ReactNode, overrides: any }) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <Modal 
      onClose={() => setIsOpen(false)} 
      isOpen={isOpen}
      closeable={true}
      overrides={{
        Dialog: {
          style: () => ({
            ...warning_modal,
            backgroundColor: $theme.colors.black,
            color: $theme.colors.white,
            ...overrides
          })
        },
        Close: { style: () => ({ display: "none" }) }
      }}
    >{children}</Modal>
  );
}

export const ContactsModal = ({ isOpen, setIsOpen }: ModalBaseProps) => {
  const [css] = useStyletron();
  return (
    <InfoModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={css({ textAlign: 'center' })}>
        <Title title={'Contacts'} />
      </div>
    </InfoModal>
  )
}

export const FaqsModal = ({ isOpen, setIsOpen }: ModalBaseProps) => {
  const [css] = useStyletron();
  return (
    <InfoModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={css({ textAlign: 'center' })}>
        <Title title={'FAQs'} />
      </div>
    </InfoModal>
  )
}

export const ResetPasswordModal = ({ isOpen, setIsOpen, onConfirmPasswordReset }: ModalBaseProps & { onConfirmPasswordReset: (value: string) => unknown }) => {
  const [css, $theme] = useStyletron();
  const [toggleView, setToggleView] = useState(true);
  const [username, setUsername] = useState('');

  return (
    <WarningModal isOpen={isOpen} setIsOpen={setIsOpen} overrides={{
      width: '600px',
      height: '450px'
    }}>

      <FlexGrid>
        <SmallWhiteLogo />
        <FlexGridItem overrides={{ textAlign: 'right', alignItems: 'center' }}>
          <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Reset</div>
          <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Password</div>
        </FlexGridItem>
      </FlexGrid>

        {
          toggleView &&
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem>
              <FlexGridItem overrides={{ marginTop: '40px', width: '400px', ...notification_font }}>
                Do you really want to reset your password?
              </FlexGridItem>
              <FlexGridItem overrides={{ width: '400px', marginTop: '20px' }}>
                <Button 
                  onClick={() => {
                    setIsOpen(false);
                    setUsername('');
                  }} 
                  label={'No'}
                  overrides={{ width: '400px' }}
                />
              </FlexGridItem>
              <FlexGridItem overrides={{ width: '400px', marginTop: '10px' }}>
              <WarningButton 
                onClick={() => {
                  setToggleView(false);
                }} 
                label={'Yes'}
              />
              </FlexGridItem>
            </FlexGridItem>
          </FlexGrid>
        }
        
        {
          !toggleView &&
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem>
              <FlexGridItem overrides={{ marginTop: '40px', marginBottom: '20px', width: '400px', ...notification_font }}>
                Please provide your username to continue:
              </FlexGridItem>
              <FlexGridItem>
                <Input label='' value={username} onChange={(e) => setUsername(e.target.value)} />
              </FlexGridItem>
              <WarningButton 
                onClick={() => {
                  onConfirmPasswordReset(username);
                  setIsOpen(false);
                  setToggleView(true);
                  setUsername('');
                }} 
                label={'Submit'}
              />
            </FlexGridItem>
          </FlexGrid>
        }
    </WarningModal>
  )
}

export type NotificationModalProps = {
  isOpen: boolean,
  onClose: () => unknown,
  message: string | React.ReactNode | undefined | null
}

export const NotificationModal = ({ isOpen, onClose, message }: NotificationModalProps) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <Modal 
      onClose={onClose} 
      isOpen={isOpen}
      closeable={true}
      overrides={{
        Close: { style: () => ({ display: "none" }) },
        Dialog: {
          style: () => ({ maxWidth: '500px' })
        }
      }}
      size={SIZE.auto}
    >
      <Card 
        overrides={{ 
          ...notification_font, 
          backgroundColor: $theme.colors.accent, 
          borderColor: $theme.colors.white, 
          padding: '5px' 
        }}
      >
        <Card 
          overrides={{ 
            padding: '10px', 
            backgroundColor: $theme.colors.black, 
            color: $theme.colors.white 
          }}
        >
          <FlexGrid overrides={{ flexDirection: 'row', alignItems: 'center' }}>
            <FlexGridItem overrides={{ alignItems: 'center' }}>{message}</FlexGridItem>
            <FlexGridItem><Button label={'ok'} onClick={onClose} /></FlexGridItem>
          </FlexGrid>
        </Card>
      </Card>
    </Modal>
  );
}

export type FormModalProps = {
  isOpen: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  content: React.ReactNode,
  overrides?: Record<string, any>
}

export const FormModal = ({ isOpen, setIsOpen, content, overrides = {} }: FormModalProps) => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <Modal 
      isOpen={isOpen}
      onClose={() => setIsOpen(false)} 
      closeable={true}
      overrides={{
        Close: { style: () => ({ display: "none" }) },
        Dialog: {
          style: () => ({ maxWidth: '500px' })
        }
      }}
      size={SIZE.auto}
    >
      <Card overrides={{ 
        backgroundColor: $theme.colors.accent, 
        borderColor: $theme.colors.white, 
        padding: '20px', 
        ...overrides }}
      >{content}</Card>
    </Modal>
  );
}
