import { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Theme, ThemeProvider } from 'baseui';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../redux/hooks';
import { AsyncStatus } from '../shared/constants';
import { getCurrentUser, getAllUnityUsers } from './slices/user/user.slice';
import NavBar from './components/navbars/NavBar';
import { hasPortalAccess } from './utils';

import { 
  AuthenticatedUser,
  UnityUser,
  AdminPortalUser,
} from 'unity-types';

const Portal_Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const getSessionStatus: AsyncStatus = useSelector(state => state.shared.auth.getSessionStatus);
  const getAllUnityUsersStatus: AsyncStatus = useSelector(state => state.admin_portal.users.getAllUnityUsersStatus);
  const allUnityUsers: UnityUser[] | null = useSelector(state => state.admin_portal.users.allUnityUsers);
  const getCurrentUserStatus: AsyncStatus = useSelector(state => state.admin_portal.users.getCurrentUserStatus);
  const myUser: AdminPortalUser | null = useSelector(state => state.admin_portal.users.myUser);
  const theme: Theme = useSelector(state => state.shared.theme.admin);

  // Redirect user to home page if they are not authorized to access the admin portal

  useEffect(() => {
    if(getSessionStatus !== AsyncStatus.Succeeded) return;
    if(!hasPortalAccess(authenticatedUser)) navigate('/');
  }, [authenticatedUser, navigate, getSessionStatus]);

  /* ---FETCH INITIAL DATA--- */

  useEffect(() => {
    if(!hasPortalAccess(authenticatedUser)) return;
    if(!allUnityUsers && getAllUnityUsersStatus === AsyncStatus.Idle) {
      dispatch(getAllUnityUsers({}));
    }
  }, [authenticatedUser, allUnityUsers, getAllUnityUsersStatus, dispatch]);

  useEffect(() => {
    if(!hasPortalAccess(authenticatedUser)) return;
    if(!myUser && getCurrentUserStatus === AsyncStatus.Idle) {
      dispatch(getCurrentUser({ userId: authenticatedUser!.id }));
    }
  }, [authenticatedUser, getCurrentUserStatus, myUser, dispatch]);

  /* ------ */

  return (
    <ThemeProvider theme={theme}>
      <NavBar />
      <Outlet />
    </ThemeProvider>
  );
}

export default Portal_Index;

