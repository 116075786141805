import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { find } from 'lodash';
import { validateAmountField, validateNotesField, validateSelectField } from './validate';
import { setFieldOnNewPayment } from '../../../slices/payment/payment.slice';
import { getAllVendors } from '../../../slices/vendor/vendor.slice';
import { SIZE } from '../../../../shared/styles';
import { buildSelectOptions } from '../../../utils';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Select from '../../../../shared/components/elements/Select';
import TextArea from '../../../../shared/components/elements/TextArea';
import Title from '../../../../shared/components/elements/Title';
import { type Vendor, type Client, type PgEnum } from 'unity-types';
import { FieldValidator, SelectFieldValidator, type SelectOption } from '../../../../shared/types';
import { type NewPayment } from '../../../slices/payment/types';

type PaymentDetailsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

const PaymentDetailsForm = ({ setIsValidStep }: PaymentDetailsFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const newPayment: NewPayment = useSelector(state => state.reentry_portal.payments.newPayment!);
  const allVendors: Vendor[] | null = useSelector(state => state.reentry_portal.vendors.allVendors);
  const selectFields: PgEnum[] | null = useSelector(state => state.reentry_portal.schema.selectFields);
  const allClients: Client[] | null = useSelector(state => state.reentry_portal.clients.allClients);
  const [amount, setAmount] = useState<string>(newPayment.amount);
  const [payee, setPayee] = useState<SelectOption[]>(newPayment.payeeSelectOption);
  const [category, setCategory] = useState<SelectOption[]>(newPayment.categorySelectOption);
  const [client, setClient] = useState<SelectOption[]>(newPayment.clientSelectOption);
  const [notes, setNotes] = useState<string>(newPayment.notes);
  const [amountError, setAmountError] = useState<string>();
  const [payeeError, setPayeeError] = useState<string>();
  const [categoryError, setCategoryError] = useState<string>();
  const [clientError, setClientError] = useState<string>();
  const [notesError, setNotesError] = useState<string>();
  const [vendorSelectOptions, setVendorSelectOptions] = useState<SelectOption[]>([]);
  const [categorySelectOptions, setCategorySelectOptions] = useState<SelectOption[]>([]);
  const [clientSelectOptions, setClientSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const hasError = amountError || payeeError || categoryError || clientError || notesError;
    const emptyFields = !amount || !payee || !category || !client || !notes;
    const isValid = !hasError && !emptyFields;
    setIsValidStep(isValid);
  }, [setIsValidStep, amountError, payeeError, categoryError, clientError, notesError, amount, payee, category, client, notes]);

  useEffect(() => {
    if(!allVendors) {
      dispatch(getAllVendors({}));
    } else {
      const options: SelectOption[] = allVendors.map(vendor => ({ id: vendor.id, label: vendor.name }));
      setVendorSelectOptions(options);
    }
  }, [allVendors, dispatch]);

  useEffect(() => {
    if(selectFields) {
      const categoryField = find(selectFields, (field) => field.name === 'payment_category')!;
      setCategorySelectOptions(buildSelectOptions(categoryField.values));
    }
  }, [selectFields]);

  useEffect(() => {
    if(allClients.length > 0) {
      const filtered: Client[] = allClients.filter((client) => {
        return client.programStatus === 'Active';
      });
      const options: SelectOption[] = filtered.map((client) => {
        return {
          id: client.id,
          label: client.fullName
        }
      });
      setClientSelectOptions(options);
    }
  }, [allClients]);

  const onBlurInput = (key: keyof NewPayment, value: string, validator: FieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    dispatch(setFieldOnNewPayment({ key, value }));
  }

  const onChangeInput = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value);
  }

  const onBlurSelect = (key: keyof NewPayment, value: SelectOption[], validator: SelectFieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    dispatch(setFieldOnNewPayment({ key, value }));
  }

  const onChangeSelect = (value: any, setValue: React.Dispatch<React.SetStateAction<SelectOption[]>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value as SelectOption[]);
  }

  return (
    <>
      <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px', minWidth: '500px' }}>
        <Title title={'Payment Details'} size={SIZE.LARGE}/>
        <Input
          value={amount}
          label={'Amount'}
          onChange={(e) => onChangeInput(e.target.value, setAmount, setAmountError)}
          onBlur={() => onBlurInput('amount', amount, validateAmountField, setAmountError)}
          error={amountError}
        />
        <Select
          value={payee}
          label={'Payee'}
          onChange={(params) => onChangeSelect(params.value, setPayee, setPayeeError)}
          options={vendorSelectOptions}
          isLoading={vendorSelectOptions.length === 0}
          onBlur={() => onBlurSelect('payeeSelectOption', payee, validateSelectField, setPayeeError)}
          error={payeeError}
        />
        <Select
          value={category}
          label={'Category'}
          onChange={(params) => onChangeSelect(params.value, setCategory, setCategoryError)}
          options={categorySelectOptions}
          isLoading={categorySelectOptions.length === 0}
          onBlur={() => onBlurSelect('categorySelectOption', category, validateSelectField, setCategoryError)}
          error={categoryError}
        />
        <Select
          value={client}
          label={'Client'}
          onChange={(params) => onChangeSelect(params.value, setClient, setClientError)}
          options={clientSelectOptions}
          isLoading={clientSelectOptions.length === 0}
          onBlur={() => onBlurSelect('clientSelectOption', client, validateSelectField, setClientError)}
          error={clientError}
        />
        <TextArea
          value={notes}
          label={'Notes'}
          onChange={(e) => onChangeInput(e.target.value, setNotes, setNotesError)}
          onBlur={() => onBlurInput('notes', notes, validateNotesField, setNotesError)}
          error={notesError}
        />
      </Card>
    </>
  );
}

export default PaymentDetailsForm;

