import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBase64 } from '../../utils';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';

import {
  Attachment,
  CreateAttachmentDTO,
  CreateAttachmentResponseDTO,
  GenericResponse,
  ServerErrorTypes,
} from 'unity-types';

import {
  attachmentSchema,
  createAttachmentDTOSchema,
  createAttachmentResponseDTOSchema,
  genericResponseSchema
} from 'unity-types/schema';

interface AttachmentState {
  status: AsyncStatus;
  error: string | null;
  createAttachmentResponse: CreateAttachmentResponseDTO | null;
  getAttachmentStatus: AsyncStatus;
  getAttachmentError: string | null;
  attachment: Attachment | null;
}

const initialState: AttachmentState = {
  status: AsyncStatus.Idle,
  error: null,
  createAttachmentResponse: null,
  getAttachmentStatus: AsyncStatus.Idle,
  getAttachmentError: null,
  attachment: null,
};

export const createAttachment = createAsyncThunk<CreateAttachmentResponseDTO, { file: File, type: string }, { rejectValue: ServerError }>(
  'dig_portal/attachments/createAttachment', 
  async (payload, { rejectWithValue }) => {
    try {
      const { file, type } = payload;
      const base64: string = await getBase64(file) as string;
      const dto: CreateAttachmentDTO = createAttachmentDTOSchema.parse({
        base64: base64,
        filename: file.name,
        size: file.size,
        filetype: file.type,
        type: type,
      });
      const response = await axios.post('/api/dig_portal/attachments', dto);
      const data: CreateAttachmentResponseDTO = createAttachmentResponseDTOSchema.parse(response.data);
      return data;
    }
    catch(e: any) {
      console.log(e);
      if(e.response.status === 413) {
        const serverError: ServerError = serverErrorSchema.parse({
          code: 413,
          type: ServerErrorTypes.Payload_Too_Large,
        });
        return rejectWithValue(serverError);
      }
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const deleteAttachment = createAsyncThunk<GenericResponse, CreateAttachmentResponseDTO, { rejectValue: ServerError }>(
  'dig_portal/attachments/deleteAttachment', 
  async (payload, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const response = await axios.delete(`/api/dig_portal/attachments/${id}`);
      const genericResponse: GenericResponse = genericResponseSchema.parse(response.data);
      return genericResponse;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getAttachment = createAsyncThunk<Attachment, { id: string }, { rejectValue: ServerError }>(
  'dig_portal/attachments/getAttachment', 
  async (payload, { rejectWithValue }) => {
    try {
      const { id } = payload;
      const response = await axios.get(`/api/dig_portal/attachments/${id}`);
      const attachment: Attachment = attachmentSchema.parse(response.data);
      return attachment;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(createAttachment.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(createAttachment.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.createAttachmentResponse = action.payload;
      })
      .addCase(createAttachment.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(deleteAttachment.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(deleteAttachment.fulfilled, (state) => {
        state.status = AsyncStatus.Succeeded;
        state.createAttachmentResponse = initialState.createAttachmentResponse;
      })
      .addCase(deleteAttachment.rejected, (state) => {
        state.status = AsyncStatus.Failed;
      })
      .addCase(getAttachment.pending, (state) => {
        state.getAttachmentStatus = AsyncStatus.Loading;
      })
      .addCase(getAttachment.fulfilled, (state, action) => {
        state.getAttachmentStatus = AsyncStatus.Succeeded;
        state.attachment = action.payload;
      })
      .addCase(getAttachment.rejected, (state, action) => {
        state.getAttachmentStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getAttachmentError = message;
      })
  }
});

export const { clearState } = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
