import { createTheme, Theme } from 'baseui';
import { WHITE, BLACK, GREY, LIGHT_GREY, GREEN, RED, AHFC_BLUE, PURPLE, BARBIE_PINK, MOCHA } from '.';
import { LOGO_BLUE_URL, LOGO_MOUNTAINS_BACKGROUND_BLUE_URL, LOGO_PINK_URL, LOGO_MOUNTAINS_BACKGROUND_PINK_URL, LOGO_PURPLE_URL, LOGO_MOUNTAINS_BACKGROUND_PURPLE_URL, 
  LOGO_MOCHA_URL, LOGO_MOUNTAINS_BACKGROUND_MOCHA_URL
 } from '../constants';

// these color tokens probably shouldn't ever change between themes
export const staticColors = {
  white: WHITE,
  black: BLACK,
  grey: GREY,
  light_grey: LIGHT_GREY,
  positive: GREEN,
  negative: RED,
};

// Themes
export const ahfcBlueTheme: Theme = createTheme({ 
  colors: { 
    accent: AHFC_BLUE,
    ...staticColors,
    logo_url: LOGO_BLUE_URL,
    background_mountains_url: LOGO_MOUNTAINS_BACKGROUND_BLUE_URL,
  },
});

export const barbiePinkTheme: Theme = createTheme({ 
  colors: { 
    accent: BARBIE_PINK,
    ...staticColors,
    logo_url: LOGO_PINK_URL,
    background_mountains_url: LOGO_MOUNTAINS_BACKGROUND_PINK_URL,
  }
});

export const mochaTheme: Theme = createTheme({ 
  colors: { 
    accent: MOCHA,
    ...staticColors,
    logo_url: LOGO_MOCHA_URL,
    background_mountains_url: LOGO_MOUNTAINS_BACKGROUND_MOCHA_URL,
  }
});

// Default Themes
export const defaultReentryPortalTheme = ahfcBlueTheme;
export const defaultDigPortalTheme = mochaTheme;
export const defaultAdminPortalTheme: Theme = createTheme({ 
  colors: { 
    accent: PURPLE,
    ...staticColors,
    reentry_accent: defaultReentryPortalTheme.colors.accent, // for referencing the reentry portal accent color from the admin portal
    dig_accent: defaultDigPortalTheme.colors.accent, // for referencing the dig portal accent color from the admin portal
    logo_url: LOGO_PURPLE_URL,
    background_mountains_url: LOGO_MOUNTAINS_BACKGROUND_PURPLE_URL,
  },
});
export const defaultGlobalTheme: Theme = createTheme({ 
  colors: { 
    accent: AHFC_BLUE,
    ...staticColors,
    reentry_accent: defaultReentryPortalTheme.colors.accent, // for referencing the reentry portal accent color from the top-level pages
    dig_accent: defaultDigPortalTheme.colors.accent, // for referencing the dig portal accent color from the top-level pages
    admin_accent: defaultAdminPortalTheme.colors.accent, // for referencing the admin portal accent color from the top-level pages
  },
});
