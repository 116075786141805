import { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { clearViewPayment, clearViewSiblingPayment, getSiblingPayment } from '../../../slices/payment/payment.slice';
import { font_sm, font_med, font_lg } from '../../../../shared/styles';
import { formatDateString, getPaymentDetailPageUrl } from '../../../utils';
import { RecordDetailLinkCard } from '../../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { SmallSpinner } from '../../../../shared/components/elements/Spinner';
import { type DigAgencyExtendedInfo, type DigPortalUser, type Payment } from 'unity-types';

export type SiblingPaymentCardProps = {
  setHasReceipt: React.Dispatch<React.SetStateAction<boolean>>
}

const SiblingPaymentCard = ({ setHasReceipt }: SiblingPaymentCardProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allUsers: DigPortalUser[] | null = useSelector(state => state.dig_portal.users.allUsers);
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);
  const viewSiblingPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewSiblingPayment);
  const allPaymentsByClient: Payment[] | null = useSelector(state => state.dig_portal.payments.allPaymentsByClient);
  const [siblingPayment, setSiblingPayment] = useState<Payment>();
  const [createdByUser, setCreatedByUser] = useState<DigPortalUser>();

  useEffect(() => {
    if(viewPayment && userAgency) {
      const siblingPaymentId = viewPayment.refundPaymentId ?? viewPayment.originalPaymentId;
      if(siblingPaymentId) dispatch(getSiblingPayment({ id: siblingPaymentId, agencyId: userAgency.id }));;
    }
  }, [viewPayment, allPaymentsByClient, userAgency, dispatch]);

  useEffect(() => {
    if(viewSiblingPayment && !siblingPayment) {
      setSiblingPayment(viewSiblingPayment);
    }
  }, [siblingPayment, viewSiblingPayment]);

  useEffect(() => {
    if(siblingPayment && !createdByUser && allUsers) {
      const createdByUser = find(allUsers, (user) => user.id === siblingPayment.createdByUserId);
      setCreatedByUser(createdByUser);
    }
  }, [allUsers, siblingPayment, createdByUser]);

  const onClickRecordLink = () => {
    dispatch(clearViewPayment());
    dispatch(clearViewSiblingPayment());
    setHasReceipt(false);
    if(siblingPayment) navigate(getPaymentDetailPageUrl(siblingPayment.id));
  }

  return (
    siblingPayment ? 
    <FlexGrid>
      <RecordDetailLinkCard overrides={{ width: 'auto', textAlign: 'left', padding:'10px', ...font_med }} onClick={onClickRecordLink} >
        <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
          {
            siblingPayment.type === 'Payment' ?
            <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.negative, marginTop: '-5px' }}>View Original Payment</FlexGridItem> :
            <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.positive, marginTop: '-5px' }}>View Refund</FlexGridItem>
          }
          <FlexGridItem overrides={{ ...font_med }}>Name: {siblingPayment.paymentCode}</FlexGridItem>
          {
            createdByUser && 
            <FlexGrid overrides={{ ...font_sm, justifyContent: 'flex-start', alignItems: 'center' }}>
              <FlexGridItem overrides={font_sm}>Created By:</FlexGridItem>
              <FlexGridItem overrides={{ color: $theme.colors.accent }}>{createdByUser.fullName}</FlexGridItem>
            </FlexGrid>
          }
          <FlexGrid overrides={{ ...font_sm, alignItems: 'center', justifyContent: 'flex-start' }}>
            <FlexGridItem overrides={font_sm}>Created Date:</FlexGridItem>
            <FlexGridItem overrides={{...font_sm, color: $theme.colors.accent }}>{formatDateString(siblingPayment.createdDt)}</FlexGridItem>
          </FlexGrid>
        </FlexGrid>
      </RecordDetailLinkCard>
    </FlexGrid> : <SmallSpinner />
  )
}

export default SiblingPaymentCard;