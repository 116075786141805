import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { find } from 'lodash';
import { setFieldOnNewClient, setFieldOnUpdateClient } from '../../../slices/client/client.slice';
import { SIZE } from '../../../../shared/styles';
import { buildSelectOptions } from '../../../utils';
import { validateSelectField } from './validate';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import Select from '../../../../shared/components/elements/Select';
import Title from '../../../../shared/components/elements/Title';
import { type StagedUpdateClient, type NewClient, type SetFieldNewClientPayloadAction, type SetFieldUpdateClientPayloadAction } from '../../../slices/client/types';
import { type SelectFieldValidator, type SelectOption } from '../../../../shared/types';

type DemographicsFields = Pick<NewClient, 'race' | 'ethnicity' | 'gender' | 'householdMembers' | 'householdMinors'>;

export type DemographicsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>,
  saveToGlobalState: <K extends keyof DemographicsFields>(payload: SetFieldNewClientPayloadAction<K> | SetFieldUpdateClientPayloadAction<K>) => void,
  race: SelectOption[],
  setRace: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  ethnicity: SelectOption[],
  setEthnicity: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  gender: SelectOption[],
  setGender: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  householdMembers: SelectOption[],
  setHouseholdMembers: React.Dispatch<React.SetStateAction<SelectOption[]>>,
  householdMinors: SelectOption[],
  setHouseholdMinors: React.Dispatch<React.SetStateAction<SelectOption[]>>
};

export const DemographicsForm = ({
    setIsValidStep,
    saveToGlobalState,
    race,
    setRace,
    ethnicity,
    setEthnicity,
    gender,
    setGender,
    householdMembers,
    setHouseholdMembers,
    householdMinors,
    setHouseholdMinors
  }: DemographicsFormProps) => {
    const [, $theme] = useStyletron();

  const selectFields = useSelector(state => state.dig_portal.schema.selectFields);
  const [raceSelectOptions, setRaceSelectOptions] = useState<SelectOption[]>([]);
  const [ethnicitySelectOptions, setEthnicitySelectOptions] = useState<SelectOption[]>([]);
  const [genderSelectOptions, setGenderSelectOptions] = useState<SelectOption[]>([]);
  const [householdMembersSelectOptions, setHouseholdMembersSelectOptions] = useState<SelectOption[]>([]);
  const [householdMinorsSelectOptions, setHouseholdMinorsSelectOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if(selectFields) {
      const raceField = find(selectFields, (field) => field.name === 'client_race')!;
      setRaceSelectOptions(buildSelectOptions(raceField.values));
      const ethnicityField = find(selectFields, (field) => field.name === 'client_ethnicity')!;
      setEthnicitySelectOptions(buildSelectOptions(ethnicityField.values));
      const genderField = find(selectFields, (field) => field.name === 'client_gender')!;
      setGenderSelectOptions(buildSelectOptions(genderField.values));
      const householdMembersField = find(selectFields, (field) => field.name === 'client_household_members')!;
      setHouseholdMembersSelectOptions(buildSelectOptions(householdMembersField.values));
      const householdMinorsField = find(selectFields, (field) => field.name === 'client_household_minors')!;
      setHouseholdMinorsSelectOptions(buildSelectOptions(householdMinorsField.values));
    }
  }, [selectFields]);

  const [raceError, setRaceError] = useState<string>();
  const [ethnicityError, setEthnicityError] = useState<string>();
  const [genderError, setGenderError] = useState<string>();
  const [householdMembersError, setHouseholdMembersError] = useState<string>();
  const [householdMinorsError, setHouseholdMinorsError] = useState<string>();

  useEffect(() => {
    setIsValidStep(!(raceError || ethnicityError || genderError || householdMembersError || householdMinorsError));
  }, [setIsValidStep, raceError, ethnicityError, genderError, householdMembersError, householdMinorsError])

  const onChange = <K extends keyof DemographicsFields>(
    value: DemographicsFields[K], 
    setValue: React.Dispatch<React.SetStateAction<SelectOption[]>>, 
    setError: React.Dispatch<React.SetStateAction<string | undefined>>, 
    validator: SelectFieldValidator, 
    key: K
  ) => {
    setValue(value);
    setError(undefined);
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    saveToGlobalState({key, value});
  }

  return (
    <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
      <Title title={'Demographics'} size={SIZE.LARGE} overrides={{ marginBottom: '10px' }}/>
      <Select
        value={race}
        label={'Race'}
        onChange={(params) => onChange(params.value as SelectOption[], setRace, setRaceError, validateSelectField, 'race')}
        options={raceSelectOptions}
        isLoading={raceSelectOptions.length === 0}
        error={raceError}
      />
      <Select
        value={ethnicity}
        label={'Ethnicity'}
        onChange={(params) => onChange(params.value as SelectOption[], setEthnicity, setEthnicityError, validateSelectField, 'ethnicity')}
        options={ethnicitySelectOptions}
        isLoading={ethnicitySelectOptions.length === 0}
        error={ethnicityError}
      />
      <Select
        value={gender}
        label={'Gender'}
        onChange={(params) => onChange(params.value as SelectOption[], setGender, setGenderError, validateSelectField, 'gender')}
        options={genderSelectOptions}
        isLoading={genderSelectOptions.length === 0}
        error={genderError}
      />
      <Select
        value={householdMembers}
        label={'Household Members'}
        onChange={(params) => onChange(params.value as SelectOption[], setHouseholdMembers, setHouseholdMembersError, validateSelectField, 'householdMembers')}
        options={householdMembersSelectOptions}
        isLoading={householdMembersSelectOptions.length === 0}
        error={householdMembersError}
      />
      <Select
        value={householdMinors}
        label={'Household Minors'}
        onChange={(params) => onChange(params.value as SelectOption[], setHouseholdMinors, setHouseholdMinorsError, validateSelectField, 'householdMinors')}
        options={householdMinorsSelectOptions}
        isLoading={householdMinorsSelectOptions.length === 0}
        error={householdMinorsError}
      />
    </Card>
  );
}

type CreateDemographicsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

const CreateDemographicsForm = ({ setIsValidStep }: CreateDemographicsFormProps) => {
  const dispatch = useDispatch();
  const newClient: NewClient = useSelector(state => state.dig_portal.clients.newClient!);
  const saveToGlobalState = <K extends keyof DemographicsFields>(payload: SetFieldNewClientPayloadAction<K>) => dispatch(setFieldOnNewClient(payload));

  const [race, setRace] = useState(newClient.race);
  const [ethnicity, setEthnicity] = useState(newClient.ethnicity);
  const [gender, setGender] = useState(newClient.gender);
  const [householdMembers, setHouseholdMembers] = useState(newClient.householdMembers);
  const [householdMinors, setHouseholdMinors] = useState(newClient.householdMinors);

  useEffect(() => {
    const isValidStep = race.length > 0 && ethnicity.length > 0 && gender.length > 0 && householdMembers.length > 0 && householdMinors.length > 0;
    setIsValidStep(isValidStep);
  }, [setIsValidStep, race, ethnicity, gender, householdMembers, householdMinors])

  return (
    <DemographicsForm 
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      race={race}
      setRace={setRace}
      ethnicity={ethnicity}
      setEthnicity={setEthnicity}
      gender={gender}
      setGender={setGender}
      householdMembers={householdMembers}
      setHouseholdMembers={setHouseholdMembers}
      householdMinors={householdMinors}
      setHouseholdMinors={setHouseholdMinors}
    />
  )
}

export default CreateDemographicsForm;

type UpdateDemographicsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

export const UpdateDemographicsForm = ({ setIsValidStep }: UpdateDemographicsFormProps) => {
  const dispatch = useDispatch();
  const updateClient: StagedUpdateClient = useSelector(state => state.dig_portal.clients.updateClient!);
  const saveToGlobalState = <K extends keyof StagedUpdateClient>(payload: SetFieldUpdateClientPayloadAction<K>) => dispatch(setFieldOnUpdateClient(payload));
  
  const [race, setRace] = useState(updateClient.race.next);
  const [ethnicity, setEthnicity] = useState(updateClient.ethnicity.next);
  const [gender, setGender] = useState(updateClient.gender.next);
  const [householdMembers, setHouseholdMembers] = useState(updateClient.householdMembers.next);
  const [householdMinors, setHouseholdMinors] = useState(updateClient.householdMinors.next);

  useEffect(() => {
    const isValidStep = race.length > 0 && ethnicity.length > 0 && gender.length > 0 && householdMembers.length > 0 && householdMinors.length > 0;
    setIsValidStep(isValidStep);
  }, [setIsValidStep, race, ethnicity, gender, householdMembers, householdMinors])

  return (
    <DemographicsForm 
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      race={race}
      setRace={setRace}
      ethnicity={ethnicity}
      setEthnicity={setEthnicity}
      gender={gender}
      setGender={setGender}
      householdMembers={householdMembers}
      setHouseholdMembers={setHouseholdMembers}
      householdMinors={householdMinors}
      setHouseholdMinors={setHouseholdMinors}
    />
  )
}