import { useEffect, useState } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { find } from 'lodash';
import { clearAllPaymentsByClient, clearViewPayment, clearViewSiblingPayment } from '../../../slices/payment/payment.slice';
import { clearViewClient } from '../../../slices/client/client.slice';
import { font_med_lg, font_lg, font_med } from '../../../../shared/styles';
import { getClientDetailPageUrl } from '../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type Payment, type Client } from 'unity-types';

const ClientLinkCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewPayment: Payment | null = useSelector(state => state.reentry_portal.payments.viewPayment);
  const allClients: Client[] = useSelector(state => state.reentry_portal.clients.allClients);
  const [parentClient, setParentClient] = useState<Client>();
  const [isActive, setIsActive] = useState<boolean>();

  useEffect(() => {
    if(viewPayment && allClients.length > 0) {
      const parentClient = find(allClients, (client) => client.id === viewPayment.clientId);
      if(parentClient) {
        setParentClient(parentClient);
        setIsActive(parentClient.programStatus === 'Active');
      }
    }
  }, [allClients, viewPayment]);

  return (
    parentClient &&
    <Card overrides={{ backgroundColor: $theme.colors.accent, padding: '0px 5px 5px 5px' }}>
      <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.white, width: '100%', textAlign: 'center' }}>Client</FlexGridItem>
      <Card
        overrides={{ 
          padding:'0px 5px 5px 5px', 
          ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
        }} 
        onClick={() => {
          dispatch(clearViewPayment());
          dispatch(clearViewSiblingPayment());
          dispatch(clearViewClient());
          dispatch(clearAllPaymentsByClient());
          navigate(getClientDetailPageUrl(parentClient.id));
          
        }}
      >
        <FlexGrid overrides={{ flexDirection: 'column', justifyContent: 'space-between', rowGap: '0px' }}>
          <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.accent, width: 'auto' }}>{parentClient.fullName}</FlexGridItem>
          <FlexGridItem overrides={{ ...font_med, color: $theme.colors.black }}>
            Program Status: <span className={css({ backgroundColor: isActive ? $theme.colors.positive : $theme.colors.negative, color: $theme.colors.white, padding: '0px 5px', borderRadius: '5px' })}>{parentClient.programStatus}</span>  
          </FlexGridItem>
        </FlexGrid>
      </Card>
    </Card>
  )
}

export default ClientLinkCard;
