import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_lg, font_med } from '../../../shared/styles';
import Card from '../../../shared/components/elements/Card';
import { FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { AsyncStatus } from '../../../shared/constants';
import { MediumSpinner } from '../../../shared/components/elements/Spinner';
import { type DigPortalUser } from 'unity-types';

export const MyUserDashboard = () => {
  const [css, $theme] = useStyletron();
  const myUser: DigPortalUser | null = useSelector(state => state.dig_portal.users.myUser);
  const userStatus: AsyncStatus = useSelector(state => state.dig_portal.users.status);

  return (
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.white, borderColor: $theme.colors.accent }}>
      {
        userStatus === AsyncStatus.Loading ?
        <MediumSpinner /> :
        myUser &&
        <>
        <FlexGridItem overrides={{ ...font_lg }}>
          Welcome, <span className={css({ color: $theme.colors.accent })}>{myUser.firstName} {myUser.lastName}</span>.
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_med }}>
          Username: <span className={css({ color: $theme.colors.accent })}>{myUser.username}</span>
        </FlexGridItem>
        <FlexGridItem overrides={{ ...font_med, marginTop: '10px' }}>
          Role: <span className={css({ ...font_med, backgroundColor: $theme.colors.accent, color: $theme.colors.white, padding: '5px', borderRadius: '10px' })}>{myUser.permissionSet}</span>
        </FlexGridItem>
        </>
      }
    </Card>
  )
}

export default MyUserDashboard;