"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserAgencyDTOSchema = exports.createAgencyDetailsDTOSchema = exports.digAgencyExtendedInfoSchema = exports.reentryAgencyExtendedInfoSchema = exports.assignAgencyDTOSchema = exports.createAgencyDTOSchema = exports.agencySchema = void 0;
const zod_1 = require("zod");
exports.agencySchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    name: zod_1.z.string(),
    community: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    createdByUserId: zod_1.z.coerce.string().nullable(),
}).strict();
exports.createAgencyDTOSchema = zod_1.z.object({
    name: zod_1.z.string(),
    community: zod_1.z.string(),
}).strict();
exports.assignAgencyDTOSchema = zod_1.z.object({
    agencyId: zod_1.z.coerce.string(),
}).strict();
exports.reentryAgencyExtendedInfoSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    name: zod_1.z.string(),
    community: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    createdByUserId: zod_1.z.coerce.string(),
    totalAward: zod_1.z.number(),
    standardAllocation: zod_1.z.number(),
    totalObligatedFunds: zod_1.z.number(),
    totalPaidFunds: zod_1.z.number(),
    fundsRemaining: zod_1.z.number(),
    remainingSlots: zod_1.z.number(),
    clients: zod_1.z.number(),
    payments: zod_1.z.number(),
});
exports.digAgencyExtendedInfoSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    name: zod_1.z.string(),
    community: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    createdByUserId: zod_1.z.coerce.string(),
    totalAward: zod_1.z.number(),
    standardAllocation: zod_1.z.number(),
    totalObligatedFunds: zod_1.z.number(),
    totalPaidFunds: zod_1.z.number(),
    fundsRemaining: zod_1.z.number(),
    remainingSlots: zod_1.z.number(),
    clients: zod_1.z.number(),
    payments: zod_1.z.number(),
});
exports.createAgencyDetailsDTOSchema = zod_1.z.object({
    agencyId: zod_1.z.string(),
    totalAward: zod_1.z.number(),
    standardAllocation: zod_1.z.number(),
}).strict();
exports.updateUserAgencyDTOSchema = zod_1.z.object({
    standardAllocation: zod_1.z.number(),
}).strict();
