import { useEffect } from 'react';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { useNavigate, NavLink } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { submitLogout, clearState as clearAuthState } from '../../../shared/slices/auth/auth.slice';
import { clearState as clearRegisterState } from '../../../shared/slices/register/register.slice';
import { clearState as clearUserState, clearViewUnityUser } from '../../slices/user/user.slice';
import { clearState as clearReportsState } from '../../slices/reports/reports.slice';
import { clearState as clearPermissionsState } from '../../slices/permissions/permissions.slice';
import { navlink, special_font } from '../../../shared/styles';
import { AsyncStatus } from '../../../shared/constants';
import Button from '../../../shared/components/elements/Button';
import { MediumLogo } from '../../../shared/components/elements/Logo';
import { MyUserHeaderCard } from '../cards/users/MyUserHeaderCard';
import { FlexGrid, FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { SmallSpinner } from '../../../shared/components/elements/Spinner';
import Card from '../../../shared/components/elements/Card';
import { type AuthenticatedUser } from 'unity-types';
import { type LinkData } from '../../../shared/types';

const NavBar = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitLogoutStatus: AsyncStatus = useSelector(state => state.shared.auth.submitLogoutStatus);
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);

  useEffect(() => {
    if(submitLogoutStatus === AsyncStatus.Succeeded) {
      dispatch(clearAuthState());
      dispatch(clearRegisterState());
      dispatch(clearUserState());
      navigate('/login');
    }
  }, [submitLogoutStatus, navigate, dispatch]);

  const primaryLinks: LinkData[] = [
    { 
      to: '', 
      label: 'Home',
      onClick: () => {
        dispatch(clearReportsState());
      }
    },
    { 
      to: 'users', 
      label: 'Users',
      onClick: () => {
        dispatch(clearViewUnityUser());
        dispatch(clearUserState());
        dispatch(clearReportsState());
      }
    },
    { 
      to: 'reports', 
      label: 'Reports',
      onClick: () => {
        dispatch(clearReportsState());
      }
    },
    { 
      to: 'permissions', 
      label: 'Permissions',
      onClick: () => {
        dispatch(clearPermissionsState());
      }
    },
  ];

  return (
    <>
      <FlexGrid overrides={{ flexDirection: 'column', marginBottom: '37.5px' }}>
        <FlexGridItem>
          <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white }} >
            <div className={css({...special_font, color: $theme.colors.white, textAlign: 'center' })}>Unity Admin Portal</div>
          </Card>
        </FlexGridItem>
        <FlexGridItem overrides={{ padding: '10px', marginBottom: '-67.5px' }}>
          <FlexGrid overrides={{ justifyContent: 'space-between'}}>
            <FlexGridItem>
              <FlexGrid overrides={{ flexDirection: 'column' }}>
                <FlexGridItem overrides={{ zIndex: 2 }}>
                  <MediumLogo overrides={{ paddingTop: '0px', marginBottom: '-13px' }}/>
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
            <FlexGridItem>
              <FlexGrid overrides={{ flexDirection: 'column', zIndex: 2 }}>
                <FlexGridItem overrides={{ alignItems: 'center' }}>
                  <Button 
                    label='Log Out'
                    onClick={() => dispatch(submitLogout())} 
                    isLoading={submitLogoutStatus === AsyncStatus.Loading} 
                    overrides={{ height: '60px', width: '110px', float: 'right', marginLeft: '5px', backgroundColor: $theme.colors.accent }}
                  />
                  <Button 
                    label='Unity Home'
                    onClick={() => navigate('/')}
                    overrides={{ height: '60px', width: '140px', float: 'right', marginLeft: '5px', backgroundColor: $theme.colors.grey }}
                  />
                </FlexGridItem>
                <FlexGridItem>
                  {(authenticatedUser) ? <MyUserHeaderCard myUser={authenticatedUser} /> : <div className={css({ marginRight: '10px', float: 'right' })}><SmallSpinner /></div>}
                </FlexGridItem>
              </FlexGrid>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
        <FlexGridItem>
          <nav className={css({ display: 'flex', justifyContent: 'center' })}>
            {
              primaryLinks.map((link, i) => {
                const navlinkStyle = { ...navlink, ':hover': { color: $theme.colors.accent }};
                const navlinkActiveStyle = { ...navlinkStyle, color: $theme.colors.accent };
                return (
                <NavLink 
                  key={i} 
                  end={link.to === ''}
                  to={link.to}
                  onClick={link.onClick}
                  className={({ isActive }) => {
                    const linkStyle = isActive ? navlinkActiveStyle : navlinkStyle;
                    return css(linkStyle);
                  }}
                >{link.label}</NavLink>);
              })
            }
          </nav>
        </FlexGridItem>
      </FlexGrid>
    </>
  );
}

export default NavBar;
