import { useStyletron } from 'baseui';
import { logo_sm, logo_med } from '../../../shared/styles';
import { LOGO_WHITE_SM_URL } from '../../constants';

export const SmallWhiteLogo = ({ overrides = {} }) => {
  const [css] = useStyletron();
  return <a href="https://www.ahfc.us/"><img src={LOGO_WHITE_SM_URL} alt='ahfc logo' className={css({ ...logo_sm, ...overrides })} /></a>;
}

export const MediumLogo = ({ overrides = {} }) => {
  const [css, $theme] = useStyletron();
  return <a href="https://www.ahfc.us/"><img src={$theme.colors.logo_url} alt='ahfc logo' className={css({ ...logo_med, ...overrides })} /></a>;
}
