import { useState } from "react";
import { useStyletron } from "baseui";
import { useAppDispatch as useDispatch } from "../../../../../redux/hooks";
import { font_med, font_lg, font_sm } from "../../../../shared/styles";
import { clearViewPortalUserState, getPortalUser } from "../../../slices/user/user.slice";
import Card from "../../../../shared/components/elements/Card";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import Button from "../../../../shared/components/elements/Button";
import { FormModal } from "../../../../shared/components/elements/Modal";
import AssignRoleForm from "../../forms/users/AssignRoleForm";
import { type UnityUser, PortalName } from 'unity-types';

export type UserRolesCardProps = {
  user: UnityUser;
}

const UserRolesCard = ({ user }: UserRolesCardProps) => {
  const [, $theme] = useStyletron();
  const [addRoleModalIsOpen, setAddRoleModalIsOpen] = useState<boolean>(false);

  return (
    <>
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGrid overrides={{ alignItems: 'center' }}>
          <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.white, textAlign: 'center' }}>User Roles</FlexGridItem>
          <Button label={'Add Role'} onClick={() => setAddRoleModalIsOpen(true)} overrides={{ ...font_sm, width: '150px', color: $theme.colors.white }}/>
        </FlexGrid>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 610}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
        <FlexGrid overrides={{ flexDirection: 'column', rowGap: '5px', justifyContent: 'center' }}>
          {
            user.roles.map((userRole: PortalName, i: number) => <UserRoleCardItem key={i} userRole={userRole} userId={user.id} />)
          }
        </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
    <FormModal 
      isOpen={addRoleModalIsOpen} 
      setIsOpen={setAddRoleModalIsOpen}
      content={<AssignRoleForm />}
    />
    </>
  )
}

export default UserRolesCard;

export type UserRoleCardItemProps = {
  userRole: PortalName;
  userId: string;
}

const UserRoleCardItem = ({ userRole, userId }: UserRoleCardItemProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  
  return (
    <Card overrides={{ padding:'5px', ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' } }} onClick={() => {
      dispatch(clearViewPortalUserState());
      dispatch(getPortalUser({ portal: userRole, userId: userId }));
    }}>
      <FlexGrid overrides={{ justifyContent: 'space-between' }}>
        { userRole === PortalName.Reentry_Portal && <FlexGridItem overrides={{...font_med, color: $theme.colors.reentry_accent }}>{userRole}</FlexGridItem> }
        { userRole === PortalName.Dig_Portal && <FlexGridItem overrides={{...font_med, color: $theme.colors.dig_accent }}>{userRole}</FlexGridItem> }
        { userRole === PortalName.Admin_Portal && <FlexGridItem overrides={{...font_med, color: $theme.colors.accent }}>{userRole}</FlexGridItem> }
      </FlexGrid>
    </Card>
  )
}
