import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { setFieldOnNewClient, setFieldOnUpdateClient } from '../../../slices/client/client.slice';
import { validateNameField, validateDateOfBirthField, validateEmailAddressField, validatePhoneNumberField } from './validate';
import { SIZE } from '../../../../shared/styles';
import { capitalize } from '../../../../shared/utils';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import MaskedInput from '../../../../shared/components/elements/MaskedInput';
import Title from '../../../../shared/components/elements/Title';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { type StagedUpdateClient, type NewClient, type SetFieldNewClientPayloadAction, type SetFieldUpdateClientPayloadAction } from '../../../slices/client/types';
import { type FieldValidator } from '../../../../shared/types';

type ContactInfoFields = Pick<NewClient, 'firstName' | 'lastName' | 'dateOfBirth' | 'emailAddress' | 'phoneNumber'>;

type ContactInfoFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>,
  saveToGlobalState: <K extends keyof ContactInfoFields>(payload: SetFieldNewClientPayloadAction<K> | SetFieldUpdateClientPayloadAction<K>) => void,
  firstName: string,
  setFirstName: React.Dispatch<React.SetStateAction<string>>,
  lastName: string,
  setLastName: React.Dispatch<React.SetStateAction<string>>,
  dateOfBirth: string,
  setDateOfBirth: React.Dispatch<React.SetStateAction<string>>,
  emailAddress: string,
  setEmailAddress: React.Dispatch<React.SetStateAction<string>>,
  phoneNumber: string,
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>,
};

export const ContactInfoForm = ({ 
    setIsValidStep,
    saveToGlobalState,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dateOfBirth,
    setDateOfBirth,
    emailAddress,
    setEmailAddress,
    phoneNumber,
    setPhoneNumber, 
  }: ContactInfoFormProps) => {
    const [, $theme] = useStyletron();
    
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [dateOfBirthError, setDateOfBirthError] = useState<string>();
  const [emailAddressError, setEmailAddressError] = useState<string>();
  const [phoneNumberError, setPhoneNumberError] = useState<string>();

  useEffect(() => {
    setIsValidStep(!(firstNameError || lastNameError || dateOfBirthError || emailAddressError || phoneNumberError));
  }, [setIsValidStep, firstNameError, lastNameError, dateOfBirthError, emailAddressError, phoneNumberError])

  const onBlur = <K extends keyof ContactInfoFields>(key: K, value: ContactInfoFields[K], validator: FieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    saveToGlobalState({key, value});
  }

  const onChange = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value);
  }

  return (
    <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
      <Title title={'Contact Info'} size={SIZE.LARGE} overrides={{ marginBottom: '10px' }}/>
      <Input
        value={firstName}
        onChange={(e) => onChange(e.target.value, setFirstName, setFirstNameError)}
        label={'First Name'}
        onBlur={() => onBlur('firstName', capitalize(firstName.trim()), validateNameField, setFirstNameError)}
        error={firstNameError}
      />
      <Input
        value={lastName}
        onChange={(e) => onChange(e.target.value, setLastName, setLastNameError)}
        label={'Last Name'}
        onBlur={() => onBlur('lastName', capitalize(lastName.trim()), validateNameField, setLastNameError)}
        error={lastNameError}
      />
      <MaskedInput
        mask={"99/99/99"}
        value={dateOfBirth}
        onChange={(e) => onChange(e.target.value, setDateOfBirth, setDateOfBirthError)}
        label={'Date of Birth'}
        onBlur={() => onBlur('dateOfBirth', dateOfBirth, validateDateOfBirthField, setDateOfBirthError)}
        error={dateOfBirthError}
      />
      <Input
        value={emailAddress}
        onChange={(e) => onChange(e.target.value, setEmailAddress, setEmailAddressError)}
        label={'Email Address'}
        onBlur={() => onBlur('emailAddress', emailAddress.trim(), validateEmailAddressField, setEmailAddressError)}
        error={emailAddressError}
      />
      <FlexGridItem>
      <MaskedInput
        mask={"(999) 999-9999"}
        value={phoneNumber}
        onChange={(e) => onChange(e.target.value, setPhoneNumber, setPhoneNumberError)}
        label={'Phone Number'}
        onBlur={() => onBlur('phoneNumber', phoneNumber, validatePhoneNumberField, setPhoneNumberError)}
        error={phoneNumberError}
      />
      </FlexGridItem>
    </Card>
  );
}

type CreateContactInfoFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

const CreateContactInfoForm = ({ setIsValidStep }: CreateContactInfoFormProps) => {
  const dispatch = useDispatch();
  const newClient: NewClient = useSelector(state => state.reentry_portal.clients.newClient!);
  const saveToGlobalState = <K extends keyof ContactInfoFields>(payload: SetFieldNewClientPayloadAction<K>) => dispatch(setFieldOnNewClient(payload));

  const [firstName, setFirstName] = useState(newClient.firstName);
  const [lastName, setLastName] = useState(newClient.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(newClient.dateOfBirth);
  const [emailAddress, setEmailAddress] = useState(newClient.emailAddress);
  const [phoneNumber, setPhoneNumber] = useState(newClient.phoneNumber);

  useEffect(() => {
    setIsValidStep(![firstName, lastName, dateOfBirth, emailAddress, phoneNumber].includes(''));
  }, [setIsValidStep, firstName, lastName, dateOfBirth, emailAddress, phoneNumber])

  return (
    <ContactInfoForm 
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      dateOfBirth={dateOfBirth}
      setDateOfBirth={setDateOfBirth}
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
    />
  )
}

export default CreateContactInfoForm;

type UpdateContactInfoFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

export const UpdateContactInfoForm = ({ setIsValidStep }: UpdateContactInfoFormProps) => {
  const dispatch = useDispatch();
  const updateClient: StagedUpdateClient = useSelector(state => state.reentry_portal.clients.updateClient!);
  const saveToGlobalState = <K extends keyof StagedUpdateClient>(payload: SetFieldUpdateClientPayloadAction<K>) => dispatch(setFieldOnUpdateClient(payload));
  
  const [firstName, setFirstName] = useState(updateClient.firstName.next);
  const [lastName, setLastName] = useState(updateClient.lastName.next);
  const [dateOfBirth, setDateOfBirth] = useState(updateClient.dateOfBirth.next);
  const [emailAddress, setEmailAddress] = useState(updateClient.emailAddress.next);
  const [phoneNumber, setPhoneNumber] = useState(updateClient.phoneNumber.next);

  useEffect(() => {
    setIsValidStep(![firstName, lastName, dateOfBirth, emailAddress, phoneNumber].includes(''));
  }, [setIsValidStep, firstName, lastName, dateOfBirth, emailAddress, phoneNumber])

  return (
    <ContactInfoForm 
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      dateOfBirth={dateOfBirth}
      setDateOfBirth={setDateOfBirth}
      emailAddress={emailAddress}
      setEmailAddress={setEmailAddress}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
    />
  )
}
