import { useStyletron } from 'baseui';

export type CardProps = {
  children: React.ReactNode;
  onClick?: () => void;
  overrides?: any;
};

const Card = ({ children, onClick, overrides = {} }: CardProps) => {
  const [css, $theme] = useStyletron();
  return (
    <div className={css({ 
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      borderStyle: 'solid',
      borderWidth: '2px',
      backgroundColor: $theme.colors.white, 
      borderColor: $theme.colors.accent, 
      ...overrides 
    })} onClick={onClick}>
      {children}
    </div>
  );
}

export default Card;

export const InfoCard = ({ children, onClick, overrides = {} }: CardProps) => {
  return <Card children={children} onClick={onClick} overrides={{ width: '800px', ...overrides }} />
}

export const FormCard = ({ children, onClick, overrides = {} }: CardProps) => {
  return <Card children={children} onClick={onClick} overrides={{ minWidth: '500px', ...overrides }} />
}

export const RecordDetailLinkCard = ({ children, onClick, overrides = {} }: CardProps) => {
  const [, $theme] = useStyletron();
  return <Card children={children} onClick={onClick} overrides={{ ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, ...overrides }} />
}
