import PORTAL_INDEX from '.';
import HomePage from './components/pages/HomePage';
import USERS_INDEX from './components/pages/Users_Index';
import UsersHomePage from './components/pages/users/UsersHomePage';
import UserDetailPage from './components/pages/users/UserDetailPage';
import REPORTS_INDEX from './components/pages/Reports_Index';
import ReportsHomePage from './components/pages/reports/ReportsHomePage';
import PERMISSIONS_INDEX from './components/pages/Permissions_Index';
import PermissionsHomePage from './components/pages/permissions/PermissionsHomePage';

const routes = {
  path: 'admin-portal', element: <PORTAL_INDEX />,
  children: [
    { index: true, element: <HomePage /> },
    {
      path: 'users', element: <USERS_INDEX />,
      children: [
        { index: true, element: <UsersHomePage /> },
        { path: 'detail/:recordId', element: <UserDetailPage /> },
      ]
    },
    {
      path: 'reports', element: <REPORTS_INDEX />,
      children: [
        { index: true, element: <ReportsHomePage /> },
      ]
    },
    {
      path: 'permissions', element: <PERMISSIONS_INDEX />,
      children: [
        { index: true, element: <PermissionsHomePage /> },
      ]
    },
  ]
};

export default routes;