import { font_med, font_extra_sm, font_med_lg, font_lg } from './fonts';

export type Button = {
  [key: string]: any,
};

export const base_button: Button = {
  ...font_med,
  borderStyle: 'solid',
  borderWidth: '2px',
  width: '100%',
  transitionDuration: '0ms',
  ':focus': {},
}

export const sm_warning_button: Button = {
  ...base_button,
  ...font_extra_sm,
  width: '100px',
  padding: '5px',
}

export const large_button: Button = {
  ...base_button,
  ...font_med_lg,
  height: '100px',
  padding: '10px',
}

export const medium_button: Button = {
  ...base_button,
  ...font_lg,
  height: '100px',
  padding: '10px',
}

export const clear_default_button: Button = {
  background: 'none',
	color: 'inherit',
	border: 'none',
	padding: 0,
	font: 'inherit',
	cursor: 'pointer',
	outline: 'inherit',
}
