import { useEffect, useState } from "react";
import { useStyletron } from "baseui";
import { useNavigate } from "react-router-dom";
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from "../../../../../redux/hooks";
import { clearViewClient } from "../../../slices/client/client.slice";
import { font_med, font_lg, font_sm } from "../../../../shared/styles";
import Card from "../../../../shared/components/elements/Card";
import { getClientDetailPageUrl } from "../../../utils";
import { FlexGrid, FlexGridItem } from "../../../../shared/components/layout/FlexGrid";
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import { type DigPortalUser, type Client } from 'unity-types';

const UserClientList = () => {
  const [, $theme] = useStyletron();
  const viewUser: DigPortalUser | null = useSelector(state => state.dig_portal.users.viewUser);
  const allClients: Client[] | null = useSelector(state => state.dig_portal.clients.allClients);
  const [visibleClients, setVisibleClients] = useState<Client[]>();

  useEffect(() => {
    if(allClients.length > 0 && viewUser) {
      let userClients = allClients.filter(client => client.navigatorId === viewUser.id);
      setVisibleClients(userClients);
    }
  }, [allClients, viewUser]);

  return (
    <Card overrides={{ padding: '10px 7.5px', backgroundColor: $theme.colors.accent, borderColor: $theme.colors.accent }}>
      <FlexGrid overrides={{ flexDirection: 'column'}}>
        <FlexGridItem overrides={{ ...font_med, color: $theme.colors.white, textAlign: 'center', margin: '-10px' }}>All clients assigned to</FlexGridItem>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          {
            viewUser && <FlexGridItem overrides={{ ...font_lg, backgroundColor: $theme.colors.white, color: $theme.colors.accent, textAlign: 'center', borderRadius: '5px', padding: '0px 5px' }}>{viewUser.fullName}</FlexGridItem>
          }
        </FlexGrid>
        <Card 
          overrides={{ 
            backgroundColor: $theme.colors.white, 
            width: 'auto', 
            borderColor: $theme.colors.white, 
            height: `${window.innerHeight - 390}px`, 
            overflow: 'auto',
            padding: '10px 5px 10px 5px',
            scrollbarColor: `${$theme.colors.accent} ${$theme.colors.white}`,
            scrollbarWidth: 'none'
          }} 
        >
          <FlexGrid overrides={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {
              visibleClients ?
              visibleClients.map((client, i) => <UserClientListItem client={client} key={i} />) :
              <ExtraLargeSpinner />
            }
          </FlexGrid>
        </Card>
      </FlexGrid>
    </Card>
  )
}

export default UserClientList;

export type UserClientListItemProps = {
  client: Client;
}

const UserClientListItem = ({ client }: UserClientListItemProps) => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isActive = client.programStatus === 'Active';

  return (
    <Card 
      overrides={{ 
        padding:'5px', 
        ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
        backgroundColor: $theme.colors.white,
        width: '100%',
        borderColor: `${isActive ? $theme.colors.accent : $theme.colors.negative}`,
      }} 
      onClick={() => {
        dispatch(clearViewClient());
        navigate(getClientDetailPageUrl(client.id));
      }}
    >
      <FlexGrid overrides={{ ...font_sm, justifyContent: 'space-between', alignItems: 'center' }}>
        <FlexGrid overrides={{ justifyContent: 'flex-start' }}>
          <FlexGridItem overrides={{ ...font_sm, color: $theme.colors.black }}>{client.confirmationCode}</FlexGridItem>
          <FlexGridItem overrides={{...font_sm, color: isActive ? $theme.colors.accent : $theme.colors.negative }}>{client.firstName} {client.lastName}</FlexGridItem>
        </FlexGrid>
        <FlexGridItem>
          <FlexGridItem overrides={{...font_sm, color: isActive ? $theme.colors.accent : $theme.colors.negative }}>{client.programStatus}</FlexGridItem>
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}