"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateClientDTOSchema = exports.createClientDTOSchema = exports.clientQueryFilterSchema = exports.clientSchema = void 0;
const zod_1 = require("zod");
exports.clientSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    confirmationCode: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    agencyId: zod_1.z.coerce.string(),
    navigatorId: zod_1.z.coerce.string(),
    dateOfBirth: zod_1.z.coerce.date().transform(d => d.toISOString()),
    emailAddress: zod_1.z.string(),
    phoneNumber: zod_1.z.string(),
    street: zod_1.z.string(),
    city: zod_1.z.string(),
    state: zod_1.z.string(),
    zipCode: zod_1.z.string(),
    race: zod_1.z.string(),
    ethnicity: zod_1.z.string(),
    gender: zod_1.z.string(),
    programStatus: zod_1.z.string(),
    exitStatus: zod_1.z.string().nullable(),
    programStartDate: zod_1.z.coerce.date().transform(d => d.toISOString()),
    programExitDate: zod_1.z.coerce.date().transform(d => d.toISOString()).nullable(),
    householdMembers: zod_1.z.string(),
    householdMinors: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    createdByUserId: zod_1.z.coerce.string(),
    obligatedFunds: zod_1.z.number(),
    fullName: zod_1.z.string(),
    totalPaid: zod_1.z.number(),
    remainingFunds: zod_1.z.number(),
    payments: zod_1.z.number(),
}).strict();
exports.clientQueryFilterSchema = exports.clientSchema.partial();
exports.createClientDTOSchema = zod_1.z.object({
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    dateOfBirth: zod_1.z.coerce.date().transform(d => d.toISOString()),
    emailAddress: zod_1.z.string(),
    phoneNumber: zod_1.z.string(),
    street: zod_1.z.string(),
    city: zod_1.z.string(),
    state: zod_1.z.string(),
    zipCode: zod_1.z.string(),
    race: zod_1.z.string(),
    ethnicity: zod_1.z.string(),
    gender: zod_1.z.string(),
    householdMembers: zod_1.z.string(),
    householdMinors: zod_1.z.string(),
}).strict();
exports.updateClientDTOSchema = zod_1.z.object({
    id: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    dateOfBirth: zod_1.z.coerce.date().transform(d => d.toISOString()),
    emailAddress: zod_1.z.string(),
    phoneNumber: zod_1.z.string(),
    street: zod_1.z.string(),
    city: zod_1.z.string(),
    state: zod_1.z.string(),
    zipCode: zod_1.z.string(),
    race: zod_1.z.string(),
    ethnicity: zod_1.z.string(),
    gender: zod_1.z.string(),
    programStatus: zod_1.z.string(),
    exitStatus: zod_1.z.string(),
    programExitDate: zod_1.z.coerce.date().transform(d => d.toISOString()),
    householdMembers: zod_1.z.string(),
    householdMinors: zod_1.z.string(),
    obligatedFunds: zod_1.z.number(),
}).strict().partial();
