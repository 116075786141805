import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { getVendor, resetGetVendorState } from '../../../slices/vendor/vendor.slice';
import { getVendorDetailPageUrl, escapeCharactersForFormulaRegex } from '../../../utils';
import { AsyncStatus } from '../../../../shared/constants';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Button from '../../../../shared/components/elements/Button';
import Title from '../../../../shared/components/elements/Title';
import { NotificationModal } from '../../../../shared/components/elements/Modal';
import { type Vendor } from 'unity-types';

const SearchVendorPage = () => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewVendor: Vendor | null = useSelector(state => state.dig_portal.vendors.viewVendor);
  const vendorsStatus: AsyncStatus = useSelector(state => state.dig_portal.vendors.status);
  const vendorsError: string | null = useSelector(state => state.dig_portal.vendors.error);
  const [name, setName] = useState<string>('');
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    if(viewVendor || vendorsError) {
      setName('');
      if(viewVendor) navigate(getVendorDetailPageUrl(viewVendor.id));
    }
  }, [viewVendor, vendorsError, navigate]);

  const validateAndSubmit = () => {
    if(name === '') {
      return setValidationError('Provide a vendor name first.');
    }
    dispatch(getVendor({ name: escapeCharactersForFormulaRegex(name) }));
  }

    return (
      <StyledContainer>
        <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
          <FlexGridItem overrides={{ width: '420px'}}>
            <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }} >
              <Title title={'Search Vendors'}/>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                label={'Enter a Vendor Name:'}
                caption={'Will search system for exact matches only'}
              />
              <Button
                onClick={() => validateAndSubmit()}
                label={'Search'}
                isLoading={vendorsStatus === AsyncStatus.Loading}
                disabled={vendorsError !== null}
              />
            </Card>
            <NotificationModal 
              isOpen={!!vendorsError} 
              message={vendorsError} 
              onClose={() => dispatch(resetGetVendorState())}
            />
            <NotificationModal 
              isOpen={!!validationError} 
              message={validationError} 
              onClose={() => setValidationError('')}
            />
          </FlexGridItem>
        </FlexGrid>
      </StyledContainer>
    );
}

export default SearchVendorPage;
