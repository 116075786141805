import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { submitResetPassword, clearState } from '../../../shared/slices/user/user.slice';
import { AsyncStatus, FieldValidationMessage } from '../../constants';
import { font_sm_giant, notification_font } from '../../styles'
import { RotatingImageStyledContainer } from '../layout/StyledContainer';
import Input from '../elements/Input';
import { LargeButton } from '../elements/Button';
import Card from '../elements/Card';
import { SmallWhiteLogo } from '../elements/Logo';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import { NotificationModal } from '../elements/Modal';

const ResetPasswordPage =  () => {
  const [css, $theme] = useStyletron();
  const { username, secret } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetPasswordStatus: AsyncStatus = useSelector(state => state.shared.users.status);
  const resetPasswordError: string | null = useSelector(state => state.shared.users.error);
  const [newPassword, setNewPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<string>();
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (resetPasswordStatus === AsyncStatus.Succeeded) {
      setSuccessModalIsOpen(true);
    }
  }, [resetPasswordStatus]);

  const validateAndSubmit = () => {
    if (!username || !secret) {
      return setValidationError('Missing URL parameters. Please contact support for assistance.');
    }

    if (newPassword === '') {
      return setValidationError(FieldValidationMessage.AllFieldsRequired);
    }
    dispatch(submitResetPassword({ username, newPassword, secret }));
  }

  return (
    <RotatingImageStyledContainer fullscreen={true}>
      <FlexGridItem overrides={{ marginTop: '20px' }}>
        <Card overrides={{ backgroundColor: $theme.colors.black, borderColor: $theme.colors.white, width: '600px', padding: '10px', height: 'auto' }}>
          <FlexGrid>
            <SmallWhiteLogo />
            <FlexGridItem overrides={{ textAlign: 'right', alignItems: 'center' }}>
              <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>reset</div>
              <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Password</div>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem>
            <FlexGridItem overrides={{ marginTop: '40px', width: '500px', ...notification_font, color: $theme.colors.white }}>
              Please enter a new password below to regain access to your account. Remember to choose a strong password that is unique and not easily guessable.
            </FlexGridItem>
            <FlexGridItem overrides={{ marginTop: '40px', width: '500px', ...notification_font, color: $theme.colors.white }}>
            Once you have entered your new password, click the 'Reset Password' button to proceed. If you need any assistance, please don't hesitate to reach out to our support team :)
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '500px', marginTop: '40px' }}>
              <Input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} label={'Password'} type={'password'}/>
            </FlexGridItem>
            <FlexGridItem overrides={{ width: '500px', marginTop: '10px', marginBottom: '40px' }}>
              <LargeButton label={'Reset Password'} onClick={validateAndSubmit}  isLoading={resetPasswordStatus === AsyncStatus.Loading} />
            </FlexGridItem>
            </FlexGridItem>
          </FlexGrid>
        </Card>
        <NotificationModal 
          isOpen={!!validationError} 
          message={validationError} 
          onClose={() => {
            setNewPassword('');
            setValidationError(undefined);
          }}
        />
        <NotificationModal 
          isOpen={!!resetPasswordError} 
          message={resetPasswordError} 
          onClose={() => {
            dispatch(clearState());
            setNewPassword('');
            navigate('/login');
          }}
        />
        <NotificationModal 
          isOpen={!!resetPasswordError} 
          message={resetPasswordError} 
          onClose={() => {
            dispatch(clearState());
            setNewPassword('');
            navigate('/login');
          }}
        />
        <NotificationModal 
          isOpen={successModalIsOpen} 
          message={`Your password has been successfully changed. You can now use your new password to access your account.`} 
          onClose={() => {
            dispatch(clearState());
              navigate('/login');
          }}
        />
      </FlexGridItem>
    </RotatingImageStyledContainer>
  );
}

export default ResetPasswordPage;
