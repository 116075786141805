"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portalNameSchema = void 0;
const zod_1 = require("zod");
const constants_1 = require("../constants");
exports.portalNameSchema = zod_1.z.union([
    zod_1.z.literal(constants_1.PortalName.Admin_Portal),
    zod_1.z.literal(constants_1.PortalName.Reentry_Portal),
    zod_1.z.literal(constants_1.PortalName.Dig_Portal),
]);
