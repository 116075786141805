
import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../../redux/hooks';
import { setFieldOnNewRefund } from '../../../../slices/payment/payment.slice';
import { validateNotesField } from './validateRefund';
import { SIZE } from '../../../../../shared/styles';
import { FormCard as Card } from '../../../../../shared/components/elements/Card';
import TextArea from '../../../../../shared/components/elements/TextArea';
import Title from '../../../../../shared/components/elements/Title';
import { type NewRefund } from '../../../../slices/payment/types';
import { type FieldValidator } from '../../../../../shared/types';

type RefundDetails = Pick<NewRefund, 'notes'>;

type RefundDetailsFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

export const RefundDetailsForm = ({ setIsValidStep }: RefundDetailsFormProps) => {
  const [, $theme] = useStyletron();
  const dispatch = useDispatch();
  const newRefund: NewRefund = useSelector(state => state.dig_portal.payments.newRefund!);
  const [notes, setNotes] = useState(newRefund.notes);
  const [notesError, setNotesError] = useState<string>();

  useEffect(() => {
    setIsValidStep(![notes].includes(''));
  }, [setIsValidStep, notes])

  const onBlur = <K extends keyof RefundDetails>(key: K, value: NewRefund[K], validator: FieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    dispatch(setFieldOnNewRefund({ key, value }));
  }

  const onChange = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value);
  }

  return (
    <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
      <Title title={'Why is this payment being refunded?'} size={SIZE.MEDIUM} overrides={{  marginBottom: '10px' }} />
      <TextArea
        value={notes}
        label={''}
        onChange={(e) => onChange(e.target.value, setNotes, setNotesError)}
        onBlur={() => onBlur('notes', notes, validateNotesField, setNotesError)}
        error={notesError}
      />
    </Card>
  );
}

export default RefundDetailsForm;