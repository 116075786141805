"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginRequestDTOSchema = exports.authenticatedUserSchema = exports.portalSecuritySchema = void 0;
const zod_1 = require("zod");
const permissions_js_1 = require("./entity/permissions.js");
exports.portalSecuritySchema = zod_1.z.object({
    security: permissions_js_1.permissionSetsExtendedInfoSchema,
    isActive: zod_1.z.boolean(),
}).strict();
exports.authenticatedUserSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    username: zod_1.z.string(),
    firstName: zod_1.z.string(),
    lastName: zod_1.z.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    agencyId: zod_1.z.coerce.string(),
    agencyName: zod_1.z.string(),
    reentry_portal: exports.portalSecuritySchema.optional(),
    dig_portal: exports.portalSecuritySchema.optional(),
    admin_portal: exports.portalSecuritySchema.optional(),
}).strict();
exports.loginRequestDTOSchema = zod_1.z.object({
    username: zod_1.z.string(),
    password: zod_1.z.string(),
});
