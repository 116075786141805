import { useStyletron } from 'baseui';
import { flex_grid } from '../../styles';

export type FlexGridProps = {
  children: React.ReactNode;
  overrides?: any;
}

export const FlexGrid = ({ children, overrides }: FlexGridProps) => {
  const [css] = useStyletron();
  return (
    <div className={css({ ...flex_grid, ...overrides })}>
        {children}
    </div>
  );
}

export type FlexGridItemProps = {
  children?: React.ReactNode;
  overrides?: any;
}

export const FlexGridItem = ({ children, overrides }: FlexGridItemProps) => {
  const [css] = useStyletron();
  return (
    <div className={css({ ...overrides })}>
        {children}
    </div>
  );
}
