import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus } from '../../../shared/constants';
import { serverErrorSchema, ServerError } from '../../../shared/schema';

import { Agency } from 'unity-types';
import { agencySchema } from 'unity-types/schema';

interface AgencyState {
  status: AsyncStatus;
  error: string | null;
  userAgency: Agency | null;
}

const initialState: AgencyState = {
  status: AsyncStatus.Idle,
  error: null,
  userAgency: null,
};

export const getUserAgency = createAsyncThunk<Agency, undefined, { rejectValue: ServerError }>(
  'admin_portal/agency/getUserAgency', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/admin_portal/agencies/current');
      const agency: Agency = agencySchema.parse(response.data);
      return agency;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getUserAgency.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(getUserAgency.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        state.userAgency = action.payload;
      })
      .addCase(getUserAgency.rejected, (state) => {
        state.status = AsyncStatus.Failed;
      })
  }
});

export const { 
  clearState,
} = agenciesSlice.actions;

export default agenciesSlice.reducer;
