import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../../redux/hooks';
import { font_med } from '../../../../../shared/styles';
import Card from '../../../../../shared/components/elements/Card';
import ProgressGraph, { type ProgressGraphItem } from '../../../../../shared/components/elements/ProgressGraph';
import { formatCurrencyAmount } from '../../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../../shared/components/layout/FlexGrid';
import { type DigAgencyExtendedInfo } from 'unity-types';

const FinancialStatusCard = () => {
  const [, $theme] = useStyletron();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const [data, setData] = useState<ProgressGraphItem[]>();
  
  useEffect(() => {
    if(userAgency) {
      const totalAward = userAgency.totalAward;
      const totalObligatedFunds = userAgency.totalObligatedFunds;
      const ratio = totalObligatedFunds / totalAward;
      const percentTotalObligated = Math.floor(ratio * 100);
      const percentFundsRemaining = 100 - percentTotalObligated;
      const data: ProgressGraphItem[] = [
        { id: 'totalObligatedFunds', label: 'Total Obligated Funds', percent: percentTotalObligated, color: $theme.colors.negative, amount: `$${formatCurrencyAmount(userAgency.totalObligatedFunds)}` },
        { id: 'fundsRemaining', label: 'Funds Remaining', percent: percentFundsRemaining, color: $theme.colors.positive, amount: `$${formatCurrencyAmount(userAgency.fundsRemaining)}` },
      ]
      setData(data);
    }
  }, [userAgency, $theme.colors.negative, $theme.colors.positive]);

  return (
    userAgency &&
    <Card overrides={{ padding: '10px 10px 20px 10px', justifyContent: 'center' }}>
      { data && <ProgressGraph data={data} font={font_med} opacity={1} labelSpacing={'space-between'} /> }
      <FlexGrid overrides={{ justifyContent: 'center' }}>
        <FlexGridItem overrides={{ ...font_med, backgroundColor: $theme.colors.white, color: $theme.colors.black, padding: '0px 5px', borderRadius: '5px' }}>
          % Obligated of Total Award (${formatCurrencyAmount(userAgency.totalAward)})
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default FinancialStatusCard;
