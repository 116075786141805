import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { setFieldOnNewClient, setFieldOnUpdateClient } from '../../../slices/client/client.slice';
import { validateStreetField, validateCityField, validateStateField, validateZipCodeField } from './validate';
import { SIZE } from '../../../../shared/styles';
import { FormCard as Card } from '../../../../shared/components/elements/Card';
import Input from '../../../../shared/components/elements/Input';
import Title from '../../../../shared/components/elements/Title';
import { type StagedUpdateClient, type NewClient, type SetFieldNewClientPayloadAction, type SetFieldUpdateClientPayloadAction } from '../../../slices/client/types';
import { type FieldValidator } from '../../../../shared/types';

type AddressFields = Pick<NewClient, 'street' | 'city' | 'state' | 'zipCode'>;

type AddressFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>,
  saveToGlobalState: <K extends keyof AddressFields>(payload: SetFieldNewClientPayloadAction<K> | SetFieldUpdateClientPayloadAction<K>) => void,
  street: string,
  setStreet: React.Dispatch<React.SetStateAction<string>>,
  city: string,
  setCity: React.Dispatch<React.SetStateAction<string>>,
  state: string,
  setState: React.Dispatch<React.SetStateAction<string>>,
  zipCode: string,
  setZipCode: React.Dispatch<React.SetStateAction<string>>,
};

export const AddressForm = ({
    setIsValidStep,
    saveToGlobalState,
    street,
    setStreet,
    city,
    setCity,
    state,
    setState,
    zipCode,
    setZipCode
  }: AddressFormProps) => {
    const [, $theme] = useStyletron();

  const [streetError, setStreetError] = useState<string>();
  const [cityError, setCityError] = useState<string>();
  const [stateError, setStateError] = useState<string>();
  const [zipCodeError, setZipCodeError] = useState<string>();

  useEffect(() => {
    setIsValidStep(!(streetError || cityError || stateError || zipCodeError));
  }, [setIsValidStep, streetError, cityError, stateError, zipCodeError])

  const onBlur =  <K extends keyof AddressFields>(key: K, value: AddressFields[K], validator: FieldValidator, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    const [isValid, error] = validator(value);
    if(!isValid) return setError(error);
    setError(undefined);
    saveToGlobalState({key, value});
  }

  const onChange = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>, setError: React.Dispatch<React.SetStateAction<string | undefined>>) => {
    setError(undefined);
    setValue(value);
  }

  return (
    <Card overrides={{ backgroundColor: $theme.colors.accent, borderColor: $theme.colors.white, padding: '10px' }}>
      <Title title={'Address'} size={SIZE.LARGE} overrides={{ marginBottom: '10px' }}/>
      <Input
        value={street}
        onChange={(e) => onChange(e.target.value, setStreet, setStreetError)}
        label={'Street'}
        onBlur={() => onBlur('street', street.trim(), validateStreetField, setStreetError)}
        error={streetError}
      />
      <Input
        value={city}
        onChange={(e) => onChange(e.target.value, setCity, setCityError)}
        label={'City'}
        onBlur={() => onBlur('city', city.trim(), validateCityField, setCityError)}
        error={cityError}
      />
      <Input
        value={state}
        onChange={(e) => onChange(e.target.value, setState, setStateError)}
        label={'State'}
        caption={'Must be capitalized two-letter abbreviation'}
        onBlur={() => onBlur('state', state.trim(), validateStateField, setStateError)}
        error={stateError}
      />
      <Input
        value={zipCode}
        onChange={(e) => onChange(e.target.value, setZipCode, setZipCodeError)}
        label={'Zip Code'}
        caption={'Use 5-digit code'}
        onBlur={() => onBlur('zipCode', zipCode.trim(), validateZipCodeField, setZipCodeError)}
        error={zipCodeError}
      />
    </Card>
  );
}

type CreateAddressFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateAddressForm = ({ setIsValidStep }: CreateAddressFormProps) => {
  const dispatch = useDispatch();
  const newClient: NewClient = useSelector(state => state.reentry_portal.clients.newClient!);
  const saveToGlobalState = <K extends keyof AddressFields>(payload: SetFieldNewClientPayloadAction<K>) => dispatch(setFieldOnNewClient(payload));

  const [street, setStreet] = useState(newClient.street);
  const [city, setCity] = useState(newClient.city);
  const [state, setState] = useState(newClient.state);
  const [zipCode, setZipCode] = useState(newClient.zipCode);

  useEffect(() => {
    setIsValidStep(![street, city, state, zipCode].includes(''));
  }, [setIsValidStep, street, city, state, zipCode])

  return (
    <AddressForm
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      street={street}
      setStreet={setStreet}
      city={city}
      setCity={setCity}
      state={state}
      setState={setState}
      zipCode={zipCode}
      setZipCode={setZipCode}
    />
  )
}

export default CreateAddressForm;

type UpdateAddressFormProps = {
  setIsValidStep: React.Dispatch<React.SetStateAction<boolean>>
};

export const UpdateAddressForm = ({ setIsValidStep }: UpdateAddressFormProps) => {
  const dispatch = useDispatch();
  const updateClient: StagedUpdateClient = useSelector(state => state.reentry_portal.clients.updateClient!);
  const saveToGlobalState = <K extends keyof StagedUpdateClient>(payload: SetFieldUpdateClientPayloadAction<K>) => dispatch(setFieldOnUpdateClient(payload));
  
  const [street, setStreet] = useState(updateClient.street.next);
  const [city, setCity] = useState(updateClient.city.next);
  const [state, setState] = useState(updateClient.state.next);
  const [zipCode, setZipCode] = useState(updateClient.zipCode.next);

  useEffect(() => {
    setIsValidStep(![street, city, state, zipCode].includes(''));
  }, [setIsValidStep, street, city, state, zipCode])

  return (
    <AddressForm 
      setIsValidStep={setIsValidStep}
      saveToGlobalState={saveToGlobalState} 
      street={street}
      setStreet={setStreet}
      city={city}
      setCity={setCity}
      state={state}
      setState={setState}
      zipCode={zipCode}
      setZipCode={setZipCode}
    />
  )
}