import { NavLink } from 'react-router-dom';
import { useAppDispatch as useDispatch } from '../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { sub_navlink } from '../../../shared/styles';
import { clearNewClient, clearViewClient, clearUpdateClient } from '../../slices/client/client.slice';
import { clearNewVendor, clearViewVendor } from '../../slices/vendor/vendor.slice';
import { resetNewPayment, resetNewRefund, clearViewPayment } from '../../slices/payment/payment.slice';
import { type LinkData } from '../../../shared/types';

const SubNavBar = ({ links }: { links: LinkData[] }) => {
  const [css, $theme] = useStyletron();
  return (
    <nav className={css({ display: 'flex', justifyContent: 'center', marginTop: '-37.5px', paddingTop: '10px', backgroundColor: $theme.colors.light_grey })}>
      {links.map((link, i) => {
        const subNavlinkStyle = { ...sub_navlink, ':hover': { color: $theme.colors.accent } };
        const subNavlinkActiveStyle = { ...subNavlinkStyle, color: $theme.colors.accent };
        return <NavLink onClick={link.onClick} key={i} end={link.to === ''} to={link.to} className={({ isActive }) => {
          const linkStyle = isActive ? subNavlinkActiveStyle : subNavlinkStyle;
          return css(linkStyle);
        }}>{link.label}</NavLink>
      })}
    </nav>
  );
}

export const ClientsNavBar = () => {
  const dispatch = useDispatch();
  const clientLinks: LinkData[] = [
    {
      to: '',
      label: 'Add a new Client',
      onClick: () => {
        dispatch(clearNewClient())
      } 
    },
    { 
      to: 'update-client',
      label: `Update Client Details`,
      onClick: () => {
        dispatch(clearViewClient());
        dispatch(clearUpdateClient());
      }
    },
    { 
      to: 'adjust-budget',
      label: `Adjust Client Budget`,
      onClick: () => {
        dispatch(clearViewClient());
        dispatch(clearUpdateClient());
      }
    },
    { 
      to: 'client-status',
      label: `Set Client Status`,
      onClick: () => {
        dispatch(clearViewClient());
        dispatch(clearUpdateClient());
      }
    },
  ];
  return <SubNavBar links={clientLinks} />
}

export const PaymentsNavBar = () => {
  const dispatch = useDispatch();
  const paymentLinks: LinkData[] = [
    { 
      to: '',
      label: 'Input a new Payment',
      onClick: () => {
        dispatch(resetNewPayment());
      }
    },
    { 
      to: 'issue-refund',
      label: 'Issue a Refund',
      onClick: () => {
        dispatch(clearViewPayment());
        dispatch(resetNewRefund());
        dispatch(resetNewPayment());
      }
    },
  ];
  return <SubNavBar links={paymentLinks} />
}

export const VendorsNavBar = () => {
  const dispatch = useDispatch();
  const vendorLinks: LinkData[] = [
    { 
      to: '', 
      label: 'Add a new Vendor', 
      onClick: () => {
        dispatch(clearNewVendor())
      } 
    },
  ];
  return <SubNavBar links={vendorLinks} />
}

export const SearchNavBar = () => {
  const dispatch = useDispatch();
  const vendorLinks: LinkData[] = [
    { 
      to: '', 
      label: 'Search for a Client', 
      onClick: () => {
        dispatch(clearViewClient())
      } 
    },
    { 
      to: 'search-payments', 
      label: 'Search for a Payment', 
      onClick: () => {
        dispatch(clearViewPayment())
      } 
    },
    { 
      to: 'search-vendors', 
      label: 'Search for a Vendor', 
      onClick: () => {
        dispatch(clearViewVendor())
      } 
    },
  ];
  return <SubNavBar links={vendorLinks} />
}