import { useState, useEffect } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import SearchClientForm from '../../forms/clients/SearchClientForm';
import SetClientStatusForm from '../../forms/clients/SetClientStatusForm';
import { type Client } from 'unity-types';

const UpdateClientStatusPage = () => {
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if(viewClient && viewClient.programStatus === 'Exited') {
      setIsActive(false);
    }
  }, [viewClient]);

  if(!viewClient) {
    return (
      <StyledContainer>
        <SearchClientForm title={'Find a client to change their status'} />
      </StyledContainer>
    )
  } else {
    return (
      <StyledContainer>
        <FlexGrid overrides={{ marginTop: '20px', justifyContent: 'center' }}>
          <FlexGridItem>
            {isActive === true && <SetClientStatusForm />}
          </FlexGridItem>
        </FlexGrid>
      </StyledContainer>
    );
  }
}

export default UpdateClientStatusPage;
