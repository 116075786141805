import { useStyletron } from 'baseui';
import { font_med_reg } from '../../styles';
import { RotatingImageStyledContainer } from '../layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import { SmallWhiteLogo } from '../elements/Logo';
import Card from '../elements/Card';

const NotFoundPage =  () => {
  const[css, $theme] = useStyletron();
  return (
    <RotatingImageStyledContainer fullscreen={true}>
    <FlexGridItem overrides={{ marginTop: '20px' }}>
      <Card overrides={{ backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderColor: $theme.colors.white, width: '400px', padding: '10px', height: 'auto' }}>
        <FlexGrid>
          <SmallWhiteLogo />
          <div className={css({ ...font_med_reg, color: $theme.colors.white, textAlign: 'left', alignItems: 'center', display: 'flex' })}>Sorry, this page isn't available.</div>
        </FlexGrid>
      </Card>
    </FlexGridItem>
  </RotatingImageStyledContainer>
  );
}

export default NotFoundPage;
