import { useState, useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { stageClientForUpdate } from '../../../slices/client/client.slice'
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ProgressSteps from '../../../../shared/components/elements/ProgressSteps';
import { UpdateContactInfoForm } from '../../forms/clients/ContactInfoForm';
import { UpdateAddressForm } from '../../forms/clients/AddressForm';
import { UpdateDemographicsForm } from '../../forms/clients/DemographicsForm';
import SearchClientForm from '../../forms/clients/SearchClientForm';
import ReviewUpdateForm from '../../forms/clients/ReviewUpdateForm';
import { type Client } from 'unity-types';
import { type StagedUpdateClient } from '../../../slices/client/types';
import { type ProgressStep } from '../../../../shared/types';

const UpdateClientPage = () => {
  const dispatch = useDispatch();
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);
  const updateClient: StagedUpdateClient | null = useSelector(state => state.dig_portal.clients.updateClient);
  const[activeStep, setActiveStep] = useState(0);
  const [isValidStep, setIsValidStep] = useState(true);

  useEffect(() => {
    if(viewClient) dispatch(stageClientForUpdate())
  }, [viewClient, dispatch]);

  const steps: ProgressStep[] = [{
    title: 'Update Contact Info',
    component: <UpdateContactInfoForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Update Address',
    component: <UpdateAddressForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Update Demographics',
    component: <UpdateDemographicsForm setIsValidStep={setIsValidStep} />
  }, {
    title: "Review Updates",
    component: <ReviewUpdateForm setActiveStep={setActiveStep}/>
  }];

  const setPreviousStep = () => {
    if(activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  }

  const setNextStep = () => {
    if(activeStep >= steps.length - 1) return;
    setActiveStep(activeStep + 1);
  }

  if(!viewClient) {
    return (
      <StyledContainer>
        <SearchClientForm title={'Find a client to update their details'} />
      </StyledContainer>
    )
  } else if (updateClient) {
    return (
      <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '280px' }}>
        <ProgressSteps steps={steps} activeStep={activeStep} setPreviousStep={setPreviousStep} setNextStep={setNextStep} isValidStep={isValidStep}/>
        </FlexGridItem>
        <FlexGridItem overrides={{ width: '30%' }}>
          {steps[activeStep].component}
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
    );
  }
}

export default UpdateClientPage;
