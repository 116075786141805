import { FieldValidationMessage } from "../../../../shared/constants";
import { type FieldValidator, type SelectFieldValidator } from "../../../../shared/types";

export const validateSubmitUpdateClient = (updateClient: any) => [true];

export const validateNameField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[a-zA-Z]+$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateDateOfBirthField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  if(value.length !== 8) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateEmailAddressField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validatePhoneNumberField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  if(value.length !== 14) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateStreetField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[a-zA-Z0-9 #.]+$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateCityField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[a-zA-Z .]+$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateStateField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[A-Z]{2}$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateZipCodeField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[0-9]{5}$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateSelectField: SelectFieldValidator = (value) => {
  if(value.length === 0) return [false, FieldValidationMessage.RequiredField];
  return [true, undefined];
}

export const validateAmountField: FieldValidator = (value) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[0-9]*\.*[0-9]{2}$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}