import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { font_lg, font_sm } from '../../../../shared/styles';
import { formatCurrencyAmount, calculatePaymentBreakdownByCategory, getCategoryColor } from '../../../utils';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { SmallSpinner } from '../../../../shared/components/elements/Spinner';
import ProgressGraph, { type ProgressGraphItem } from '../../../../shared/components/elements/ProgressGraph';
import { type Payment } from 'unity-types';

const FinancialBreakdownCard = () => {
  const [, $theme] = useStyletron();
  const allPaymentsByVendor: Payment[] | null = useSelector(state => state.reentry_portal.payments.allPaymentsByVendor);
  const [data, setData] = useState<ProgressGraphItem[]>();

  useEffect(() => {
    if(allPaymentsByVendor && allPaymentsByVendor.length > 0) {
      const { 
        totalDeposits, 
        percentDeposits, 
        totalRent, 
        percentRent, 
        totalStabilization, 
        percentStabilization,
      } = calculatePaymentBreakdownByCategory(allPaymentsByVendor);
      const data: ProgressGraphItem[] = [
        { id: 'deposits', label: 'Deposits', percent: percentDeposits, color: getCategoryColor('Deposit'), amount: `$${formatCurrencyAmount(totalDeposits)}` },
        { id: 'rent', label: 'Rent', percent: percentRent, color: getCategoryColor('Rent'), amount: `$${formatCurrencyAmount(totalRent)}` },
        { id: 'stabilization', label: 'Stabilization', percent: percentStabilization, color: getCategoryColor('Stabilization'), amount: `$${formatCurrencyAmount(totalStabilization)}` },
      ]
      setData(data);
    }
  }, [allPaymentsByVendor]);

  return (
    (allPaymentsByVendor && allPaymentsByVendor.length > 0) ?
    data ? 
      <Card overrides={{ padding: '10px', justifyContent: 'center', ...font_lg }}>
        <FlexGridItem overrides={{ marginBottom: '10px', textAlign: 'center', backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderRadius: '5px', padding: '0px 5px' }}>Payments By Category</FlexGridItem>
        <ProgressGraph data={data} font={font_sm} opacity={.6} labelSpacing={'flex-start'} />
      </Card>
    : <SmallSpinner/>
    : <></>
  )
}

export default FinancialBreakdownCard;
