import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { getVendorDetailPageUrl } from '../../../utils';
import { font_lg, font_med_lg, font_med } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { type Vendor } from 'unity-types';

const PayeeLinkCard = () => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewVendor: Vendor | null = useSelector(state => state.dig_portal.vendors.viewVendor);

  return (
    viewVendor &&
    <Card overrides={{ padding: '0px 5px 5px 5px', backgroundColor: $theme.colors.accent }}>
      <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.white, width: '100%', textAlign: 'center' }}>Payee (Vendor)</FlexGridItem>
      <Card 
        overrides={{ 
          padding:'0px 5px 5px 5px', 
          ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
          backgroundColor: $theme.colors.white,
        }} 
        onClick={() => {
          navigate(getVendorDetailPageUrl(viewVendor.id));
        }}
      >
        <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.accent }}>{viewVendor.name}</FlexGridItem>
        <FlexGridItem overrides={{ ...font_med, color: $theme.colors.black }}>{viewVendor.category}</FlexGridItem>
      </Card>
    </Card>
  )
}

export default PayeeLinkCard;