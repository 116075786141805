import { useStyletron } from "baseui";
import { FileUploader, DropFilesEventHandler } from "baseui/file-uploader";
import { font_lg, font_med, base_button } from "../../../shared/styles";
import Card from "./Card";

export type CustomFileUploaderProps = {
  onDrop: DropFilesEventHandler,
  accept: string,
  mulitple?: boolean
}

const CustomFileUploader = ({ onDrop, accept, mulitple = false }: CustomFileUploaderProps) => {
  const [, $theme] = useStyletron();
  return (
    <Card 
      overrides={{ 
        ...font_lg, 
        marginTop: '10px', 
        backgroundColor: $theme.colors.white, 
        color: $theme.colors.white,
        borderColor: $theme.colors.white,
        textAlign: 'center',
        width: 'none'
      }}
    >
      <FileUploader
        multiple={mulitple}
        accept={accept}
        onDrop={onDrop}
        overrides={{
          FileDragAndDrop: { style: () => ({ backgroundColor: $theme.colors.white }) },
          ContentMessage: { style: () => ({ ...font_med, color: $theme.colors.black }) },
          ButtonComponent: {
            props: {
              overrides: {
                BaseButton: {
                  style: () => ({ ...base_button, marginTop: '10px' })
                }
              }
            }
          }
        }}
      />
    </Card>
  );
}

export default CustomFileUploader;
