"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateVendorDTOSchema = exports.createVendorDTOSchema = exports.vendorQueryFilterSchema = exports.vendorSchema = void 0;
const zod_1 = require("zod");
exports.vendorSchema = zod_1.z.object({
    id: zod_1.z.coerce.string(),
    name: zod_1.z.string(),
    category: zod_1.z.string(),
    createdByUserId: zod_1.z.coerce.string(),
    createdDt: zod_1.z.coerce.date().transform(d => d.toISOString()),
    totalPaid: zod_1.z.number(),
    payments: zod_1.z.number(),
    w9AttachmentId: zod_1.z.coerce.string().nullable().optional(),
}).strict();
exports.vendorQueryFilterSchema = exports.vendorSchema.partial();
exports.createVendorDTOSchema = zod_1.z.object({
    name: zod_1.z.string(),
    category: zod_1.z.string(),
    w9AttachmentId: zod_1.z.coerce.string().optional(),
}).strict();
exports.updateVendorDTOSchema = zod_1.z.object({
    name: zod_1.z.string(),
    category: zod_1.z.string(),
    w9AttachmentId: zod_1.z.coerce.string(),
}).strict().partial();
