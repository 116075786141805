import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { getUserDetailPageUrl } from '../../../utils';
import { font_lg, font_med, font_med_lg } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type Client, type DigAgencyExtendedInfo, type DigPortalUser } from 'unity-types';

const ClientHeaderCard = () => {
  const [, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewClient: Client | null = useSelector(state => state.dig_portal.clients.viewClient);
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allUsers: DigPortalUser[] | null = useSelector(state => state.dig_portal.users.allUsers);
  const [navigator, setNavigator] = useState<DigPortalUser>();

  useEffect(() => {
    if (viewClient) {
      const navigator = find(allUsers, (user) => user.id === viewClient.navigatorId[0]);
      setNavigator(navigator);
    }
  }, [allUsers, viewClient]);

  return (
    (viewClient && userAgency) &&
    <Card overrides={{ padding: '10px', width: 'auto' }}>
      <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.accent }}>{viewClient.fullName}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_lg }}>{viewClient.confirmationCode}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>{userAgency.name}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_med }}>{userAgency.community}, AK</FlexGridItem>
      <FlexGrid overrides={{ marginTop: '10px', alignItems: 'center', justifyContent: 'flex-start' }}>
        <FlexGridItem overrides={{ ...font_med }}>Navigator:</FlexGridItem>
        {
          navigator &&
          <Card
            overrides={{
              ...font_med,
              padding: '5px',
              ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' },
              backgroundColor: $theme.colors.white,
              color: $theme.colors.accent,
            }}
            onClick={() => {
              navigate(getUserDetailPageUrl(navigator.id));
            }}
          >
            {navigator.fullName}
          </Card>
        }
      </FlexGrid>
    </Card>
  )
}

export default ClientHeaderCard;
