import { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { formatDateString, getUserDetailPageUrl } from '../../../utils';
import { font_med, font_lg, font_sm, font_med_lg } from '../../../../shared/styles';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type Vendor, type DigPortalUser } from 'unity-types';

const VendorHeaderCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewVendor: Vendor | null = useSelector(state => state.dig_portal.vendors.viewVendor);
  const allUsers: DigPortalUser[] | null = useSelector(state => state.dig_portal.users.allUsers);
  const [createdByUser, setCreatedByUser] = useState<DigPortalUser>();

  useEffect(() => {
    if(viewVendor && !createdByUser) {
      const createdByUser = find(allUsers, (user) => user.id === viewVendor.createdByUserId);
      setCreatedByUser(createdByUser); // will be undefined if the vendor was created by a user from another agency
    }
  }, [allUsers, viewVendor, createdByUser]);

  return (
    viewVendor &&
    <Card overrides={{ padding: '10px', width: 'auto' }}>
      <FlexGridItem overrides={{ ...font_med_lg, color: $theme.colors.accent }}>{viewVendor.name}</FlexGridItem>
      <FlexGridItem overrides={{ ...font_lg }}>{viewVendor.category}</FlexGridItem>
      <FlexGrid overrides={{ flexDirection: 'column', rowGap: '0px' }}>
        {
          createdByUser ?
          <FlexGrid overrides={{ ...font_med, marginTop: '10px', alignItems: 'center', justifyContent: 'flex-start' }}>
            <FlexGridItem>Created By:</FlexGridItem>
            <FlexGridItem>
              <Card
                overrides={{ 
                  padding:'5px', 
                  ':hover': { backgroundColor: $theme.colors.light_grey, cursor: 'pointer' }, 
                  backgroundColor: $theme.colors.white,
                  color: $theme.colors.accent
                }} 
                onClick={() => {
                  navigate(getUserDetailPageUrl(createdByUser.id));
                }}
              >{createdByUser.fullName}
              </Card>
            </FlexGridItem>
          </FlexGrid> :
          <FlexGridItem overrides={{ ...font_sm, width: '250px', margin: '5px 0px' }}>
            This vendor was created by a user from another agency
          </FlexGridItem>
        }
        <FlexGridItem overrides={{ ...font_med, marginTop: '5px' }}>
          Created Date:  <span className={css({ ...font_med, color: $theme.colors.accent, padding: '0px 5px 0px 5px', borderRadius: '10px' })}>{formatDateString(viewVendor.createdDt)}</span>
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default VendorHeaderCard;