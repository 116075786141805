import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import GenerateReportForm from '../../forms/reports/GenerateReportForm';

const ReportsHomePage = () => {

  return (
    <StyledContainer>
      <FlexGrid overrides={{ width: '100%', justifyContent: 'center' }}>
        <GenerateReportForm />
      </FlexGrid>
    </StyledContainer>
  );

}

export default ReportsHomePage;
