import { Outlet } from 'react-router-dom';
import { SearchNavBar } from '../navbars/SubNavBar';

const Search_Index = () => {
  return (
    <>
      <SearchNavBar />
      <Outlet/>
    </>
  );
}

export default Search_Index;
