import { configureStore } from '@reduxjs/toolkit';
import sharedReducer from '../modules/shared/slices/reducer';
import reentryPortalReducer from '../modules/reentry_portal/slices/reducer';
import digPortalReducer from '../modules/dig_portal/slices/reducer';
import adminPortalReducer from '../modules/admin_portal/slices/reducer';

const store = configureStore({
  reducer: {
    shared: sharedReducer,
    reentry_portal: reentryPortalReducer,
    dig_portal: digPortalReducer,
    admin_portal: adminPortalReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
