import { useEffect } from 'react';
import { useAppSelector as useSelector, useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { getAllPayments } from '../../../slices/payment/payment.slice';
import { getAllClients } from '../../../slices/client/client.slice';
import { FlexGrid } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import ExtraLargeSpinner from '../../../../shared/components/elements/Spinner';
import BudgetUsed from './progressBars/BudgetUsed';
import ObligatedFundsUsed from './progressBars/ObligatedFundsUsed';
import CategoryBreakdown from './progressBars/CategoryBreakdown';
import ClientBreakdown from './progressBars/ClientBreakdown';
import { type DigAgencyExtendedInfo, type Payment, type Client } from 'unity-types';

const FinancialProgressCard = () => {
  const dispatch = useDispatch();
  const userAgency: DigAgencyExtendedInfo | null = useSelector(state => state.dig_portal.agencies.userAgency);
  const allPayments: Payment[] | null = useSelector(state => state.dig_portal.payments.allPayments);
  const allClients: Client[] | null = useSelector(state => state.dig_portal.clients.allClients);

  useEffect(() => {
    if(!allPayments && userAgency) dispatch(getAllPayments({ agencyId: userAgency.id }))
  }, [allPayments, userAgency, dispatch]);

  useEffect(() => {
    if(!allClients && userAgency) dispatch(getAllClients({ agencyId: userAgency.id }))
  }, [allClients, userAgency, dispatch]);

  return (
    <Card 
      overrides={{ 
        width: 'auto',
        height: `${window.innerHeight - 310}px`, 
        overflow: 'auto',
        padding: '10px',
        scrollbarWidth: 'none'
      }}
    >
      {
        (allPayments && allClients) ?
        <FlexGrid overrides={{ flexDirection: 'column' }}>
          <BudgetUsed />
          <ObligatedFundsUsed />
          <CategoryBreakdown />
          <ClientBreakdown />
        </FlexGrid> : <ExtraLargeSpinner />
      }
    </Card>
  )
}

export default FinancialProgressCard;