import { useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { StyledContainer } from '../../../../shared/components/layout/StyledContainer';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import ProgressSteps from '../../../../shared/components/elements/ProgressSteps';
import RefundDetailsForm from '../../forms/payments/refunds/RefundDetailsForm';
import ReviewForm from '../../forms/payments/refunds/ReviewForm';
import { type Payment } from 'unity-types';
import { type NewRefund } from '../../../slices/payment/types';
import { type ProgressStep } from '../../../../shared/types';

const IssueRefundPage = () => {
  const viewPayment: Payment | null = useSelector(state => state.dig_portal.payments.viewPayment);
  const newRefund: NewRefund | null = useSelector(state => state.dig_portal.payments.newRefund);
  const[activeStep, setActiveStep] = useState<number>(0);
  const [isValidStep, setIsValidStep] = useState<boolean>(true);

  const steps: ProgressStep[] = [{
    title: 'Details',
    component: <RefundDetailsForm setIsValidStep={setIsValidStep} />
  }, {
    title: 'Review',
    component: <ReviewForm setActiveStep={setActiveStep} />
  }];

  const setPreviousStep = () => {
    if(activeStep <= 0) return;
    setActiveStep(activeStep - 1);
  }

  const setNextStep = () => {
    if(activeStep >= steps.length - 1) return;
    setActiveStep(activeStep + 1);
  }

  return (
    viewPayment && newRefund &&
    <StyledContainer>
      <FlexGrid overrides={{ justifyContent: 'center', marginTop: '20px' }}>
        <FlexGridItem overrides={{ width: '280px' }}>
          <ProgressSteps steps={steps} activeStep={activeStep} setPreviousStep={setPreviousStep} setNextStep={setNextStep} isValidStep={isValidStep}/>
        </FlexGridItem>
        <FlexGridItem>
          {steps[activeStep].component}
        </FlexGridItem>
      </FlexGrid>
    </StyledContainer>
  )
}

export default IssueRefundPage;
