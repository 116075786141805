import { FieldValidationMessage } from "../../../../shared/constants";
import { type FieldValidator, type SelectFieldValidator, type SelectOption } from "../../../../shared/types";

export const validateAmountField: FieldValidator = (value: string) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  const re = /^[0-9]*\.*[0-9]{2}$/;
  if(!re.test(value)) return [false, FieldValidationMessage.InvalidInput];
  return [true, undefined];
}

export const validateSelectField: SelectFieldValidator = (value: SelectOption[]) => {
  if(value.length === 0) return [false, FieldValidationMessage.RequiredField];
  return [true, undefined];
}

export const validateNotesField: FieldValidator = (value: string) => {
  if(value === '') return [false, FieldValidationMessage.RequiredField];
  return [true, undefined];
}
