import { font_med, font_med_lg, font_sm } from './fonts';

export type Link = {
  [key: string]: any;
}

export const navlink: Link = {
  ...font_med,
  padding: '15px 15px 15px 15px',
};

export const sub_navlink: Link = {
  ...font_sm,
  padding: '0px 15px 10px 15px',
};

export const link: Link = {
  ...font_sm,
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingBottom: '5px',
  marginTop: '28px',
  float: 'right',
  borderBottom: '2px solid',
};

export const download_link: Link = {
  ...font_med_lg,
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '8px',
  transitionDuration: '0ms',
  height: '100px',
  padding: '10px',
}
