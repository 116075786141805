import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stagePaymentForRefund } from '../../../slices/payment/payment.slice';
import { WarningButton } from '../../../../shared/components/elements/Button';

const PaymentControls = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <WarningButton 
      label={'Issue Refund'}
      onClick={() => {
        dispatch(stagePaymentForRefund());
        navigate('/dig-portal/payments/issue-refund');
      }}
    />
  )
}

export default PaymentControls;