// cross-theme colors
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const GREY = '#808080';
export const LIGHT_GREY = '#D3D3D3';
export const BROWN = '#9c6644';
export const GREEN = '#228B22';
export const RED = '#D2042D';

// accent colors
export const AHFC_BLUE = '#006BB7';
export const PURPLE = '#800080';
export const BARBIE_PINK = '#E021BA';
export const MOCHA = '#6d3b07';