import { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { useStyletron } from 'baseui';
import { font_lg, font_med, font_extra_sm } from '../../../../shared/styles';
import { formatDateString } from '../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import Button from '../../../../shared/components/elements/Button';
import { type Client } from 'unity-types';

const ProgramStatusCard = () => {
  const [css, $theme] = useStyletron();
  const navigate = useNavigate();
  const viewClient: Client | null = useSelector(state => state.reentry_portal.clients.viewClient);
  const [isActive, setIsActive] = useState<boolean>();
  const [programDuration, setProgramDuration] = useState<number>();
  
  useEffect(() => {
    if (!viewClient) return;
    setIsActive(viewClient.programStatus === 'Active');
    if(viewClient.programStartDate && viewClient.programExitDate) {
      const startDateObj = new Date(viewClient.programStartDate);
      const endDateObj = new Date(viewClient.programExitDate);
      const differenceInMilliseconds = endDateObj.getTime() - startDateObj.getTime();
      const durationInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
      setProgramDuration(durationInDays);
    }
  }, [viewClient]);

  const ProgramStatus = ({ children }: { children: React.ReactNode }) => {
    return (
      <span className={css({ backgroundColor: isActive ? $theme.colors.positive : $theme.colors.negative, color: $theme.colors.white, padding: '0px 5px 0px 5px', borderRadius: '5px' })}>
        {children}
      </span>
    )
  }

  return (
    viewClient &&
    <Card overrides={{ padding: '10px', rowGap: '10px' }}>
      <FlexGridItem overrides={{ ...font_lg }}>
        Program Status: <ProgramStatus>{viewClient.programStatus}</ProgramStatus>
      </FlexGridItem>
      {
        !isActive &&
        <FlexGridItem overrides={{ ...font_med }}>
          Exit Status: <ProgramStatus>{viewClient.exitStatus}</ProgramStatus>
        </FlexGridItem>
      }
      
      <FlexGrid overrides={{ justifyContent: 'flex-start' }}>
        <Card overrides={{ ...font_med, color: $theme.colors.white, padding: '10px', backgroundColor: $theme.colors.accent }}>
          <FlexGridItem overrides={{ backgroundColor: $theme.colors.white, color: $theme.colors.accent, padding: '10px', borderRadius: '5px' }}>{formatDateString(viewClient.programStartDate)}</FlexGridItem>
          <FlexGridItem overrides={{ textAlign: 'center', marginTop: '10px' }}>Start Date</FlexGridItem>
        </Card>
        {
          (!isActive && viewClient.programExitDate) &&
          <Card overrides={{ ...font_med, color: $theme.colors.white, padding: '10px', backgroundColor: $theme.colors.accent }}>
            <FlexGridItem overrides={{ backgroundColor: $theme.colors.white, color: $theme.colors.accent, padding: '10px', borderRadius: '5px' }}>{formatDateString(viewClient.programExitDate)}</FlexGridItem>
            <FlexGridItem overrides={{ textAlign: 'center', marginTop: '10px' }}>Exit Date</FlexGridItem>
          </Card>
        }
      </FlexGrid>
      
      {
        !isActive &&
        <FlexGridItem overrides={{ ...font_med }}>
          Program Duration: <span className={css({ color: $theme.colors.accent })}>{programDuration} Day(s)</span>.
        </FlexGridItem>
      }
      {
        isActive &&
        <FlexGridItem overrides={{ textAlign: 'right', ...font_extra_sm, width: 'auto' }}>
          <Button label={'Update Client Status'} onClick={() => navigate('/reentry-portal/clients/client-status')} />
        </FlexGridItem>
      }
          
    </Card>
  )
}

export default ProgramStatusCard;
