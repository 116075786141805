import { useStyletron } from 'baseui';
import { useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { resetFieldOnUpdateClient } from '../../../slices/client/client.slice';
import { font_med } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { DeleteIcon } from '../../../../shared/components/elements/Icon';
import { type StagedUpdateClient, type NewClient } from '../../../slices/client/types';

type ClientReviewCardProps = {
  client: NewClient;
  font?: any;
}

export const ClientReviewCard = ({ client, font = font_med }: ClientReviewCardProps) => {
  const [css, $theme] = useStyletron();
  return (
    <Card overrides={{ padding: '10px' }} >
      <div className={css({...font, textAlign: 'left' })}>
        First Name: <span className={css({ color: $theme.colors.accent })}>{client.firstName}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Last Name: <span className={css({ color: $theme.colors.accent })}>{client.lastName}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Email Address: <span className={css({ color: $theme.colors.accent })}>{client.emailAddress}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Phone Number: <span className={css({ color: $theme.colors.accent })}>{client.phoneNumber}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Date of Birth: <span className={css({ color: $theme.colors.accent })}>{client.dateOfBirth}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Street: <span className={css({ color: $theme.colors.accent })}>{client.street}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        City: <span className={css({ color: $theme.colors.accent })}>{client.city}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        State: <span className={css({ color: $theme.colors.accent })}>{client.state}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Zip Code: <span className={css({ color: $theme.colors.accent })}>{client.zipCode}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Race: <span className={css({ color: $theme.colors.accent })}>{client.race[0] && client.race[0].id}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Ethnicity: <span className={css({ color: $theme.colors.accent })}>{client.ethnicity[0] && client.ethnicity[0].id}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Gender: <span className={css({ color: $theme.colors.accent })}>{client.gender[0] && client.gender[0].id}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Household Members: <span className={css({ color: $theme.colors.accent })}>{client.householdMembers[0] && client.householdMembers[0].id}</span>
      </div>
      <div className={css({...font, textAlign: 'left' })}>
        Household Minors: <span className={css({ color: $theme.colors.accent })}>{client.householdMinors[0] && client.householdMinors[0].id}</span>
      </div>
    </Card>
  )
}

type ClientReviewUpdateCardProps = {
  client: StagedUpdateClient;
  isUpdated: boolean;
  font?: any;
}

export const ClientReviewUpdateCard = ({ client, isUpdated, font = font_med }: ClientReviewUpdateCardProps) => {
  const dispatch = useDispatch();
  const [css, $theme] = useStyletron();

  const onDelete = (fieldName: keyof StagedUpdateClient) => {
    dispatch(resetFieldOnUpdateClient({ key: fieldName }));
  }

  return (
    <Card overrides={{ padding: '10px' }} >
      {
        !isUpdated &&
        <div className={css({...font, textAlign: 'left' })}>No Changes</div>
      }
      {
        (client.firstName && client.firstName.next !== client.firstName.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('firstName')}/>
          First Name: <span className={css({ color: $theme.colors.accent })}>{client.firstName.current ? client.firstName.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.firstName.next}</span>
        </div>
      }
      {
        (client.lastName && client.lastName.next !== client.lastName.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('lastName')}/>
          Last Name: <span className={css({ color: $theme.colors.accent })}>{client.lastName.current ? client.lastName.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.lastName.next}</span>
        </div>
      }
      {
        (client.emailAddress && client.emailAddress.next !== client.emailAddress.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('emailAddress')}/>
          Email Address: <span className={css({ color: $theme.colors.accent })}>{client.emailAddress.current ? client.emailAddress.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.emailAddress.next}</span>
        </div>
      }
      {
        (client.phoneNumber && client.phoneNumber.next !== client.phoneNumber.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('phoneNumber')}/>
          Phone Number: <span className={css({ color: $theme.colors.accent })}>{client.phoneNumber.current ? client.phoneNumber.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.phoneNumber.next}</span>
        </div>
      }
      {
        (client.dateOfBirth && client.dateOfBirth.next !== client.dateOfBirth.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('dateOfBirth')}/>
          Date of Birth: <span className={css({ color: $theme.colors.accent })}>{client.dateOfBirth.current ? client.dateOfBirth.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.dateOfBirth.next}</span>
        </div>
      }
      {
        (client.street && client.street.next !== client.street.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('street')}/>
          Street: <span className={css({ color: $theme.colors.accent })}>{client.street.current ? client.street.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.street.next}</span>
        </div>
      }
      {
        (client.city && client.city.next !== client.city.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('city')}/>
          City: <span className={css({ color: $theme.colors.accent })}>{client.city.current ? client.city.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.city.next}</span>
        </div>
      }
      {
        (client.state && client.state.next !== client.state.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('state')}/>
          State: <span className={css({ color: $theme.colors.accent })}>{client.state.current ? client.state.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.state.next}</span>
        </div>
      }
      {
        (client.zipCode && client.zipCode.next !== client.zipCode.current) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('zipCode')}/>
          Zip Code: <span className={css({ color: $theme.colors.accent })}>{client.zipCode.current ? client.zipCode.current : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.zipCode.next}</span>
        </div>
      }
      {
        (client.race && client.race.next[0].id !== client.race.current[0].id) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('race')}/>
          Race: <span className={css({ color: $theme.colors.accent })}>{client.race.current[0].id ? client.race.current[0].id : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.race.next[0].id}</span>
        </div>
      }
      {
        (client.ethnicity && client.ethnicity.next[0].id !== client.ethnicity.current[0].id) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('ethnicity')}/>
          Ethnicity: <span className={css({ color: $theme.colors.accent })}>{client.ethnicity.current[0].id ? client.ethnicity.current[0].id : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.ethnicity.next[0].id}</span>
        </div>
      }
      {
        (client.gender && client.gender.next[0].id !== client.gender.current[0].id) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('gender')}/>
          Gender: <span className={css({ color: $theme.colors.accent })}>{client.gender.current[0].id ? client.gender.current[0].id : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.gender.next[0].id}</span>
        </div>
      }
      {
        (client.householdMembers && client.householdMembers.next[0].id !== client.householdMembers.current[0].id) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('householdMembers')}/>
          Household Members: <span className={css({ color: $theme.colors.accent })}>{client.householdMembers.current[0].id ? client.householdMembers.current[0].id : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.householdMembers.next[0].id}</span>
        </div>
      }
      {
        (client.householdMinors && client.householdMinors.next[0].id !== client.householdMinors.current[0].id) &&
        <div className={css({...font, textAlign: 'left' })}>
          <DeleteIcon onClick={() => onDelete('householdMinors')}/>
          Household Minors: <span className={css({ color: $theme.colors.accent })}>{client.householdMinors.current[0].id ? client.householdMinors.current[0].id : '<empty>'}</span>
          {' ==> '}
          <span className={css({ color: $theme.colors.negative })}>{client.householdMinors.next[0].id}</span>
        </div>
      }
    </Card>
  )
}