import { Outlet } from 'react-router-dom';
// import { PaymentsNavBar } from '../navbars/SubNavBar';

const Payments_Index = () => {
  return (
    // <>
    //   <PaymentsNavBar />
    //   <Outlet/>
    // </>
    <Outlet/>
  );
}

export default Payments_Index;
