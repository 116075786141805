import { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_lg, font_med } from '../../../../shared/styles';
import { formatCurrencyAmount } from '../../../utils';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { type Payment,  type Vendor } from 'unity-types';

const FinancialOverviewCard = () => {
  const [css, $theme] = useStyletron();
  const viewVendor: Vendor | null = useSelector(state => state.dig_portal.vendors.viewVendor);
  const allPaymentsByVendor: Payment[] | null = useSelector(state => state.dig_portal.payments.allPaymentsByVendor);
  const [totalPaid, setTotalPaid] = useState(0);

  useEffect(() => {
    if(allPaymentsByVendor && allPaymentsByVendor.length > 0) {
      const totalPaid = allPaymentsByVendor.reduce((acc, payment) => acc + payment.amount, 0);
      setTotalPaid(totalPaid);
    }
  }, [allPaymentsByVendor]);

  return (
    viewVendor &&
    <Card overrides={{ padding: '10px', justifyContent: 'center', ...font_lg }}>
      <FlexGridItem overrides={{ marginBottom: '10px', textAlign: 'center', backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderRadius: '5px', padding: '0px 5px' }}>Financial Overview</FlexGridItem>
      <FlexGrid overrides={{ flexDirection: 'column', ...font_med, marginTop: '10px' }}>
        <FlexGridItem overrides={{ textAlign: 'left' }}>
          Total Paid: <span className={css({ ...font_lg, color: $theme.colors.white, backgroundColor: $theme.colors.accent, padding: '0px 5px', borderRadius: '5px' })}>${formatCurrencyAmount(totalPaid)}</span> 
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default FinancialOverviewCard;
