import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { ServerError, serverErrorSchema } from '../../../shared/schema';
import { createDownloadLink } from '../../utils';

import {
  GenerateReportDTO,
  ReportTypes
} from 'unity-types';

interface ReportsState {
  status: AsyncStatus;
  error: string | null;
  link: any | null;
  getReportTypesStatus: AsyncStatus;
  getReportTypesError: string | null;
  reportTypes: ReportTypes | null;
}

const initialState: ReportsState = {
  status: AsyncStatus.Idle,
  error: null,
  link: null,
  getReportTypesStatus: AsyncStatus.Idle,
  getReportTypesError: null,
  reportTypes: null,
};

export const generateReport = createAsyncThunk<any, GenerateReportDTO, { rejectValue: ServerError }>(
  'admin_portal/reports/generateReport', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/shared/reports', payload);
      return response.data;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const getReportTypes = createAsyncThunk<ReportTypes, undefined, { rejectValue: ServerError }>(
  'admin_portal/reports/getReportTypes', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/shared/reports/types');
      return response.data;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialState,
  reducers: {
    clearState: (state) => {
      if (state.link) URL.revokeObjectURL(state.link);
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(generateReport.pending, (state) => {
        state.status = AsyncStatus.Loading;
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.status = AsyncStatus.Succeeded;
        const link = createDownloadLink(action.payload);
        state.link = link;
      })
      .addCase(generateReport.rejected, (state, action) => {
        state.status = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.error = message;
      })
      .addCase(getReportTypes.pending, (state) => {
        state.getReportTypesStatus = AsyncStatus.Loading;
      })
      .addCase(getReportTypes.fulfilled, (state, action) => {
        state.getReportTypesStatus = AsyncStatus.Succeeded;
        state.reportTypes = action.payload;
      })
      .addCase(getReportTypes.rejected, (state, action) => {
        state.getReportTypesStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getReportTypesError = message;
      })
  }
});

export const { 
  clearState,
} = reportsSlice.actions;

export default reportsSlice.reducer;