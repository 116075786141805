import { createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from '../../constants';
import { emptySchema } from '../../schema';
import { createAsyncThunkByType } from '../../thunk';

import { LoginRequestDTO, AuthenticatedUser } from 'unity-types';
import { authenticatedUserSchema, loginRequestDTOSchema } from 'unity-types/schema';

export type AuthState = {
  submitLoginStatus: AsyncStatus,
  getSessionStatus: AsyncStatus,
  submitLogoutStatus: AsyncStatus,
  authenticatedUser: AuthenticatedUser | null,
  submitLoginError: string | null,
}

const initialState: AuthState = {
  submitLoginStatus: AsyncStatus.Idle,
  getSessionStatus: AsyncStatus.Idle,
  submitLogoutStatus: AsyncStatus.Idle,
  authenticatedUser: null,
  submitLoginError: null,
};

export const createSession = createAsyncThunkByType<AuthenticatedUser, LoginRequestDTO>(
  "POST", 'shared/auth/sessions', 
  authenticatedUserSchema, false, 
  loginRequestDTOSchema);
  
export const getSession = createAsyncThunkByType<AuthenticatedUser, undefined>(
  "GET", '/shared/auth/sessions/current', 
  authenticatedUserSchema, false, 
  undefined,
  {headers: { 
    'Cache-Control': 'no store'
  }});

export const submitLogout = createAsyncThunkByType<{}, undefined>(
  "DELETE", 'shared/auth/sessions/current',
  emptySchema, false
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(createSession.pending, (state) => {
        state.submitLoginStatus = AsyncStatus.Loading;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.submitLoginStatus = AsyncStatus.Succeeded;
        state.submitLogoutStatus = AsyncStatus.Idle;
        state.authenticatedUser = action.payload;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.submitLoginStatus = AsyncStatus.Failed;
        state.submitLoginError = action.payload!.message;
      })
      .addCase(getSession.pending, (state) => {
        state.getSessionStatus = AsyncStatus.Loading;
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.getSessionStatus = AsyncStatus.Succeeded;
        state.authenticatedUser = action.payload;
      })
      .addCase(getSession.rejected, (state) => {
        state.getSessionStatus = AsyncStatus.Failed;
      })
      .addCase(submitLogout.pending, (state) => {
        state.submitLogoutStatus = AsyncStatus.Loading;
      })
      .addCase(submitLogout.fulfilled, (state) => {
        state.submitLogoutStatus = AsyncStatus.Succeeded;
        state.submitLoginStatus = AsyncStatus.Idle;
        state.authenticatedUser = null;
      })
      .addCase(submitLogout.rejected, (state, action) => {
        state.submitLogoutStatus = AsyncStatus.Failed;
      })
  }
});

export const { clearState } = authSlice.actions;

export default authSlice.reducer;
