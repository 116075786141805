import { createSlice, createAsyncThunk, PayloadAction, Draft } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus, ERROR_MESSAGES } from '../../../shared/constants';
import { formatDateString } from '../../utils';
import { serverErrorSchema, ServerError } from '../../../shared/schema';
import { createAsyncThunkByType } from '../../../shared/thunk';

import { 
  Client,
  ClientQueryFilter,
  CreateClientDTO,
  UpdateClientDTO,
} from 'unity-types';

import {
  clientSchema,
  clientQueryFilterSchema,
  createClientDTOSchema,
  updateClientDTOSchema
} from 'unity-types/schema';

import {
  NewClient,
  StagedUpdateClient,
  SetFieldNewClientPayloadAction,
  SetFieldUpdateClientPayloadAction
} from './types';


interface ClientState {
  getAllClientsStatus: AsyncStatus;
  getAllClientsError: string | null;
  allClients: Client[];
  getClientStatus: AsyncStatus;
  getClientError: string | null;
  viewClient: Client | null;
  createClientStatus: AsyncStatus;
  createClientError: string | null;
  newClient: NewClient | null;
  updateClientStatus: AsyncStatus;
  updateClientError: string | null;
  updateClient: StagedUpdateClient | null;
  updateRecordId: string | null;
}

const initialState: ClientState = {
  getAllClientsStatus: AsyncStatus.Idle,
  getAllClientsError: null,
  allClients: [],
  getClientStatus: AsyncStatus.Idle,
  getClientError: null,
  viewClient: null,
  createClientStatus: AsyncStatus.Idle,
  createClientError: null,
  newClient: null,
  updateClientStatus: AsyncStatus.Idle,
  updateClientError: null,
  updateClient: null,
  updateRecordId: null,
};


export const getAllClients = createAsyncThunkByType<Client[], ClientQueryFilter>(
  "GET", 'reentry_portal/clients', 
  clientSchema, true, 
  clientQueryFilterSchema
);

export const getClient = createAsyncThunk<Client, { confirmationCode: string, agency: string }, { rejectValue: ServerError }>(
  'reentry_portal/clients/getClient', 
  async (payload, { rejectWithValue }) => {
    try {
      const filter: ClientQueryFilter = clientQueryFilterSchema.parse(payload);
      const query = new URLSearchParams(filter as any).toString();
      const response = await axios.get(`/api/reentry_portal/clients?${query}`);
      const client: Client = clientSchema.parse(response.data[0]);
      return client;
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const createNewClient = createAsyncThunkByType<Client, CreateClientDTO>(
  "POST", 'reentry_portal/clients',
  clientSchema, false,
  createClientDTOSchema
)

export const updateClient = createAsyncThunk<{ id: string }, { id: String, dto: UpdateClientDTO }, { rejectValue: ServerError }>(
  'reentry_portal/clients/updateClient', 
  async (payload, { rejectWithValue }) => {
    try {
      const dto: UpdateClientDTO = updateClientDTOSchema.parse(payload.dto);
      const response = await axios.put(`/api/reentry_portal/clients/${payload.id}`, dto);
      return response.data;
    }
    catch (e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
    resetGetAllClientsAsyncState: (state) => {
      state.getAllClientsStatus = initialState.getAllClientsStatus;
      state.getAllClientsError = initialState.getAllClientsError;
    },
    resetGetClientAsyncState: (state) => {
      state.getClientStatus = initialState.getClientStatus;
      state.getClientError = initialState.getClientError;
    },
    resetCreateClientAsyncState: (state) => {
      state.createClientStatus = initialState.createClientStatus;
      state.createClientError = initialState.createClientError;
    },
    resetUpdateClientAsyncState: (state) => {
      state.updateClientStatus = initialState.updateClientStatus;
      state.updateClientError = initialState.updateClientError;
    },
    clearAllClients: (state) => {
      state.allClients = initialState.allClients;
    },
    clearViewClient: (state) => {
      state.viewClient = initialState.viewClient;
    },
    clearNewClient: (state) => {
      state.newClient = initialState.newClient;
    },
    clearUpdateClient: (state) => {
      state.updateClient = initialState.updateClient;
      state.updateRecordId = initialState.updateRecordId;
    },
    setViewClient: (state, action: PayloadAction<Client>) => {
      state.viewClient = action.payload;
    },
    buildNewClient: (state) => {
      const newClient: NewClient = {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        emailAddress: '',
        phoneNumber: '',
        street: '',
        city: '',
        state: '',
        zipCode: '',
        race: [],
        ethnicity: [],
        gender: [],
        householdMembers: [],
        householdMinors: [],
      };
      state.newClient = newClient;
    },
    setFieldOnNewClient: <K extends keyof NewClient>(state: Draft<ClientState>, action: PayloadAction<SetFieldNewClientPayloadAction<K>>) => {
      const { key, value } = action.payload;
      state.newClient![key] = value;
    },
    stageClientForUpdate: (state) => {
      if (!state.viewClient) return;

      const updateClient: StagedUpdateClient = {
        firstName: { current: state.viewClient.firstName, next: state.viewClient.firstName },
        lastName: { current: state.viewClient.lastName, next: state.viewClient.lastName },
        dateOfBirth: { current: formatDateString(state.viewClient.dateOfBirth), next: formatDateString(state.viewClient.dateOfBirth) },
        emailAddress: { current: state.viewClient.emailAddress, next: state.viewClient.emailAddress },
        phoneNumber: { current: state.viewClient.phoneNumber, next: state.viewClient.phoneNumber },
        street: { current: state.viewClient.street, next: state.viewClient.street },
        city: { current: state.viewClient.city, next: state.viewClient.city },
        state: { current: state.viewClient.state, next: state.viewClient.state },
        zipCode: { current: state.viewClient.zipCode, next: state.viewClient.zipCode },
        race: { 
          current: [{ id: state.viewClient.race, label: state.viewClient.race }], 
          next: [{ id: state.viewClient.race, label: state.viewClient.race }] 
        },
        ethnicity: { 
          current: [{ id: state.viewClient.ethnicity, label: state.viewClient.ethnicity }], 
          next: [{ id: state.viewClient.ethnicity, label: state.viewClient.ethnicity }] 
        },
        gender: { 
          current: [{ id: state.viewClient.gender, label: state.viewClient.gender }], 
          next: [{ id: state.viewClient.gender, label: state.viewClient.gender }] 
        },
        householdMembers: { 
          current: [{ id: state.viewClient.householdMembers, label: state.viewClient.householdMembers }], 
          next: [{ id: state.viewClient.householdMembers, label: state.viewClient.householdMembers }] 
        },
        householdMinors: { 
          current: [{ id: state.viewClient.householdMinors, label: state.viewClient.householdMinors }], 
          next: [{ id: state.viewClient.householdMinors, label: state.viewClient.householdMinors }] 
        },
      }

      state.updateClient = updateClient;
      state.updateRecordId = state.viewClient.id;
    },
    setFieldOnUpdateClient: <K extends keyof StagedUpdateClient>(state: Draft<ClientState>, action: PayloadAction<SetFieldUpdateClientPayloadAction<K>>) => {
      const { key, value } = action.payload;
      state.updateClient![key]!.next = value;
    },
    resetFieldOnUpdateClient: (state, action: PayloadAction<{ key: keyof StagedUpdateClient }>) => {
      const { key } = action.payload;
      state.updateClient![key]!.next = state.updateClient![key]!.current;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllClients.pending, (state, action) => {
        state.getAllClientsStatus = AsyncStatus.Loading;
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.getAllClientsStatus = AsyncStatus.Succeeded;
        state.allClients = action.payload;
      })
      .addCase(getAllClients.rejected, (state, action) => {
        state.getAllClientsStatus = AsyncStatus.Failed;
        state.getAllClientsError = action.payload!.message;
      })
      .addCase(getClient.pending, (state) => {
        state.getClientStatus = AsyncStatus.Loading;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.getClientStatus = AsyncStatus.Succeeded;
        state.viewClient = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.getClientStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.getClientError = message;
      })
      .addCase(createNewClient.pending, (state) => {
        state.createClientStatus = AsyncStatus.Loading;
      })
      .addCase(createNewClient.fulfilled, (state, action) => {
        state.createClientStatus = AsyncStatus.Succeeded;
        state.viewClient = action.payload;
      })
      .addCase(createNewClient.rejected, (state, action) => {
        state.createClientStatus = AsyncStatus.Failed;
        state.createClientError = action.error.message!;
      })
      .addCase(updateClient.pending, (state) => {
        state.updateClientStatus = AsyncStatus.Loading;
      })
      .addCase(updateClient.fulfilled, (state) => {
        state.updateClientStatus = AsyncStatus.Succeeded;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.updateClientStatus = AsyncStatus.Failed;
        const message = ERROR_MESSAGES[action.payload!.type];
        state.updateClientError = message;
      })
  }
});

export const {
  clearState,
  resetGetAllClientsAsyncState,
  resetGetClientAsyncState,
  resetCreateClientAsyncState,
  resetUpdateClientAsyncState,
  clearAllClients,
  clearViewClient,
  clearNewClient,
  clearUpdateClient,
  setViewClient,
  buildNewClient,
  setFieldOnNewClient,
  stageClientForUpdate,
  setFieldOnUpdateClient,
  resetFieldOnUpdateClient,
} = clientsSlice.actions;

export default clientsSlice.reducer;
