import { useAppSelector as useSelector } from '../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_lg, font_med } from '../../../shared/styles';
import Card from '../../../shared/components/elements/Card';
import { FlexGridItem } from '../../../shared/components/layout/FlexGrid';
import { AsyncStatus } from '../../../shared/constants';
import { MediumSpinner } from '../../../shared/components/elements/Spinner';
import { AdminPortalUser, type AuthenticatedUser } from 'unity-types';

export const MyUserDashboard = () => {
  const [, $theme] = useStyletron();
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);
  const getSessionStatus: AsyncStatus = useSelector(state => state.shared.auth.getSessionStatus);
  const myUser: AdminPortalUser | null = useSelector(state => state.admin_portal.users.myUser);

  return (
    <Card overrides={{ padding: '10px', backgroundColor: $theme.colors.white, borderColor: $theme.colors.accent, width: '500px' }}>
      {
        getSessionStatus === AsyncStatus.Loading ?
        <MediumSpinner /> :
        (authenticatedUser && myUser) &&
        <>
          <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.accent, marginTop: '-5px' }}>
            {authenticatedUser.firstName} {authenticatedUser.lastName}
          </FlexGridItem>
          <FlexGridItem overrides={{ ...font_lg, color: $theme.colors.black }}>
            {myUser.agencyName}
          </FlexGridItem>
          <FlexGridItem overrides={{ ...font_med, color: $theme.colors.grey }}>
            {authenticatedUser.username}
          </FlexGridItem>
          <FlexGridItem overrides={{ ...font_med, color: $theme.colors.accent }}>
            {myUser.permissionSet}
          </FlexGridItem>
        </>
      }
    </Card>
  )
}


export default MyUserDashboard;