import { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { submitLogout } from '../../slices/auth/auth.slice';
import { notification_font, font_sm_giant } from '../../styles'
import { RotatingImageStyledContainer } from '../layout/StyledContainer';
import Card from '../elements/Card';
import { SmallWhiteLogo } from '../elements/Logo';
import { FlexGrid, FlexGridItem } from '../layout/FlexGrid';
import Button, { MediumButton } from '../elements/Button';
import { AsyncStatus } from '../../../shared/constants';
import { type AuthenticatedUser } from 'unity-types';
import { hasPortalAccess as hasAdminPortalAccess } from '../../../admin_portal/utils';
import { hasPortalAccess as hasReentryPortalAccess } from '../../../reentry_portal/utils';
import { hasPortalAccess as hasDigPortalAccess } from '../../../dig_portal/utils';

const HomePage =  () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitLogoutStatus: AsyncStatus = useSelector(state => state.shared.auth.submitLogoutStatus);
  const authenticatedUser: AuthenticatedUser | null = useSelector(state => state.shared.auth.authenticatedUser);

  useEffect(() => {
    if(submitLogoutStatus === AsyncStatus.Succeeded) {
      navigate('/login');
    }
  }, [submitLogoutStatus, navigate]);

  return (
    <RotatingImageStyledContainer fullscreen={true}>
      <FlexGridItem overrides={{ marginTop: '20px' }}>
        <Card overrides={{ backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderColor: $theme.colors.white, width: '600px', padding: '10px', height: 'auto' }}>
          <FlexGrid>
            <SmallWhiteLogo />
            <FlexGridItem overrides={{ textAlign: 'right', alignItems: 'center' }}>
              <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Welcome to</div>
              <div className={css({ ...font_sm_giant, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Unity</div>
              <div className={css({ ...notification_font, color: $theme.colors.white, textAlign: 'right', alignItems: 'center' })}>Choose a portal</div>
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px', marginTop: '90px' }}>
              { 
                hasAdminPortalAccess(authenticatedUser) && 
                <MediumButton 
                  onClick={() => navigate('/admin-portal')} 
                  label={'Admin Portal'} 
                  overrides={{ backgroundColor: $theme.colors.admin_accent, ':hover': { color: $theme.colors.admin_accent, backgroundColor: $theme.colors.white } }}
                />
              }
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px', marginTop: '10px' }}>
              { hasReentryPortalAccess(authenticatedUser) && <MediumButton onClick={() => navigate('/reentry-portal')} label={'Re-entry Portal'} overrides={{ backgroundColor: $theme.colors.reentry_accent }}/>}
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '400px', marginTop: '10px', marginBottom: '60px' }}>
              { hasDigPortalAccess(authenticatedUser) && <MediumButton onClick={() => navigate('/dig-portal')} label={'DIG Portal'} overrides={{ backgroundColor: $theme.colors.dig_accent }}/>}
            </FlexGridItem>
          </FlexGrid>
          <FlexGrid overrides={{ justifyContent: 'center' }}>
            <FlexGridItem overrides={{ width: '250px', marginTop: '10px' }}>
              <Button 
                label='Log Out'
                onClick={() => {
                  dispatch(submitLogout());
                }} 
                isLoading={submitLogoutStatus === AsyncStatus.Loading}
              />
            </FlexGridItem>
          </FlexGrid>
        </Card>
      </FlexGridItem>
    </RotatingImageStyledContainer>
  );
}

export default HomePage;
