import { useState } from 'react';
import { useStyletron } from 'baseui';
import { useAppDispatch as useDispatch } from '../../../../../redux/hooks';
import { setViewPortal } from '../../../slices/permissions/permissions.slice';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Select from '../../../../shared/components/elements/Select';
import { SelectOption } from '../../../../shared/types';
import Card from '../../../../shared/components/elements/Card';
import { font_sm_giant, notification_font } from '../../../../shared/styles';
import { PortalName } from 'unity-types';

const SelectSchemaForm = () => {
  const [css, $theme] = useStyletron();
  const dispatch = useDispatch();
  const [selectedSchema, setSelectedSchema] = useState<SelectOption[]>([]);

  return (
    <FlexGrid overrides={{ width: 'auto', height: '300px'}}>
      <Card overrides={{ backgroundColor: 'transparent', border: 'none' }}>
        <FlexGrid overrides={{ marginBottom: '20px' }}>
          <FlexGridItem overrides={{ textAlign: 'left', alignItems: 'center' }}>
            <div className={css({ ...notification_font, color: $theme.colors.accent, textAlign: 'left', alignItems: 'center' })}>Select a</div>
            <div className={css({ ...font_sm_giant, color: $theme.colors.accent, textAlign: 'left', alignItems: 'center' })}>Portal</div>
          </FlexGridItem>
        </FlexGrid>
        <FlexGrid overrides={{ justifyContent: 'center' }}>
          <FlexGridItem overrides={{ width: '400px' }}>
            <FlexGridItem>
              <Select
                label={'Select Portal:'}
                labelOverrides={{ ...notification_font, color: $theme.colors.accent }}
                value={selectedSchema}
                onChange={params => {
                  const value = params.value as SelectOption[];
                  setSelectedSchema(value);
                  dispatch(setViewPortal(value[0].id as PortalName));
                }}
                options={[
                  { id: PortalName.Admin_Portal, label: PortalName.Admin_Portal },
                  { id: PortalName.Reentry_Portal, label: PortalName.Reentry_Portal },
                  { id: PortalName.Dig_Portal, label: PortalName.Dig_Portal },
                ]}
                rootOverrides={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderRadius: '8px',
                  borderColor: $theme.colors.accent,
                }}
              />
            </FlexGridItem>
          </FlexGridItem>
        </FlexGrid>
      </Card>
    </FlexGrid>
  );
}

export default SelectSchemaForm;