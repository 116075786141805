import { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { useStyletron } from 'baseui';
import { font_lg, font_med, font_sm } from '../../../../shared/styles';
import { formatCurrencyAmount, calculatePaymentBreakdownByCategory, getCategoryColor } from '../../../utils';
import { FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import Card from '../../../../shared/components/elements/Card';
import { SmallSpinner } from '../../../../shared/components/elements/Spinner';
import ProgressGraph, { type ProgressGraphItem } from '../../../../shared/components/elements/ProgressGraph';
import { type Client, type Payment } from 'unity-types';

const FinancialBreakdownCard = () => {
  const [css, $theme] = useStyletron();
  const viewClient: Client | null = useSelector(state => state.reentry_portal.clients.viewClient);
  const allPaymentsByClient: Payment[] | null = useSelector(state => state.reentry_portal.payments.allPaymentsByClient);
  const [data, setData] = useState<ProgressGraphItem[]>();

  useEffect(() => {
    if(allPaymentsByClient && viewClient) {
      if(viewClient.totalPaid !== 0) {
        const { 
          totalDeposits, 
          percentDeposits, 
          totalRent, 
          percentRent, 
          totalStabilization, 
          percentStabilization 
        } = calculatePaymentBreakdownByCategory(allPaymentsByClient);
        const data: ProgressGraphItem[] = [
          { id: 'deposits', label: 'Deposits', percent: percentDeposits, color: getCategoryColor('Deposit'), amount: `$${formatCurrencyAmount(totalDeposits)}` },
          { id: 'rent', label: 'Rent', percent: percentRent, color: getCategoryColor('Rent'), amount: `$${formatCurrencyAmount(totalRent)}` },
          { id: 'stabilization', label: 'Stabilization', percent: percentStabilization, color: getCategoryColor('Stabilization'), amount: `$${formatCurrencyAmount(totalStabilization)}` },
        ]
        setData(data);
      }
    }
  }, [viewClient, allPaymentsByClient]);

  return (
    viewClient && viewClient.totalPaid !== 0 ?
    data ? 
      <Card overrides={{ padding: '10px', justifyContent: 'center', ...font_lg }}>
        <FlexGridItem overrides={{ marginBottom: '10px', textAlign: 'center', backgroundColor: $theme.colors.accent, color: $theme.colors.white, borderRadius: '5px', padding: '0px 5px' }}>Budget Allocation By Category</FlexGridItem>
        <FlexGridItem overrides={{ textAlign: 'left', marginBottom: '10px', ...font_med }}>
          Total Paid: <span className={css({ ...font_lg, color: $theme.colors.white, backgroundColor: $theme.colors.accent, padding: '0px 5px', borderRadius: '5px' })}>${formatCurrencyAmount(viewClient.totalPaid)}</span>
        </FlexGridItem>
        <ProgressGraph data={data} font={font_sm} opacity={.6} labelSpacing={'flex-start'} />
      </Card>
    : <SmallSpinner/>
    : <></>
  )
}

export default FinancialBreakdownCard;
