import { useState, useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useAppSelector as useSelector } from '../../../../../redux/hooks';
import { formatCurrencyAmount } from '../../../utils';
import { font_giant, font_med_lg } from '../../../../shared/styles';
import Card from '../../../../shared/components/elements/Card';
import { FlexGrid, FlexGridItem } from '../../../../shared/components/layout/FlexGrid';
import { type Payment } from 'unity-types';

const PaymentAmountCard = () => {
  const [, $theme] = useStyletron();
  const viewPayment: Payment | null = useSelector(state => state.reentry_portal.payments.viewPayment);
  const [isRefund, setIsRefund] = useState<boolean>();

  useEffect(() => {
    if(viewPayment) {
      if(viewPayment.type === 'Refund') setIsRefund(true);
    }
  }, [viewPayment, setIsRefund]);

  return (
    viewPayment &&
    <Card overrides={{ margin: '10px 0px', border: 'none' }} >
      <FlexGrid overrides={{ ...font_med_lg, alignItems: 'center', justifyContent: 'flex-start' }}>
        <FlexGridItem overrides={{ 
          ...font_giant,
          justifyContent: 'flex-start',
          backgroundColor: isRefund ? $theme.colors.positive : $theme.colors.negative,
          color: $theme.colors.white,
          padding: '0px 5px',
          lineHeight: '110px',
          borderRadius: '5px',
        }}>
          ${formatCurrencyAmount(viewPayment.amount)}
        </FlexGridItem>
      </FlexGrid>
    </Card>
  )
}

export default PaymentAmountCard;