import { useStyletron } from "baseui";
import { OnChangeParams, Select, SIZE } from "baseui/select";
import { FormControl } from 'baseui/form-control';
import { input_font, font_med, font_extra_sm } from '../../../shared/styles';
import { FlexGrid, FlexGridItem } from "../layout/FlexGrid";
import { SelectOption } from "../../types";

type CustomSelectProps = {
  value: SelectOption[],
  onChange: (params: OnChangeParams) => unknown,
  onBlur?: (e: React.FocusEvent<Element, Element> | MouseEvent) => unknown,
  options: SelectOption[],
  label: string,
  caption?: string | null,
  isLoading?: boolean,
  disabled?: boolean,
  error?: string | null
  labelOverrides?: any,
  rootOverrides?: any
};

const CustomSelect = ({ value, onChange, onBlur = () => {}, options, label, caption = null, isLoading = false, disabled = false, error = null, labelOverrides = {}, rootOverrides = {} }: CustomSelectProps): React.ReactNode => {
  const [, $theme] = useStyletron();
  return (
    // @ts-ignore can remove once baseui supports React 18
    <FormControl 
      label={label}
      caption={
        <FlexGrid overrides={{ justifyContent: 'space-between' }}>
          { (caption || error) && <FlexGridItem>{caption}</FlexGridItem> }
          { error && <FlexGridItem overrides={{ backgroundColor: $theme.colors.negative, color: $theme.colors.white, padding: '2px', borderRadius: '10px' }}>{error}</FlexGridItem> }
        </FlexGrid>
      }
      overrides={{
        Label: { style: () => ({ ...font_med, color: $theme.colors.white, ...labelOverrides }) },
        Caption: { style: () => ({ ...font_extra_sm, color: $theme.colors.white }) },
        ControlContainer: { style: () => ({ marginBottom: '0px' }) }
      }}
    >
    <Select
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      size={SIZE.compact}
      placeholder={''}
      isLoading={isLoading}
      disabled={disabled}
      error={!!error}
      overrides={{
        ControlContainer: { style: ({ $error }) => $error ? { borderColor: $theme.colors.negative, borderWidth: '3px' } : {} },
        Input: { style: () => input_font },
        DropdownListItem: { style: () => input_font },
        DropdownOption: { style: () => input_font },
        OptionContent: { style: () => input_font },
        InputContainer: { style: () => input_font },
        ValueContainer: { style: () => input_font },
        Root: { style: () => rootOverrides },
      }}
    />
    </FormControl>
  );
}

export default CustomSelect;
