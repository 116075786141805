import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AsyncStatus } from '../../constants';
import { serverErrorSchema, ServerError } from '../../schema';

import { Role } from 'unity-types';
import { roleSchema } from 'unity-types/schema';

interface RoleState {
  allRolesStatus: AsyncStatus;
  allRolesError: string | null;
  allRoles: Role[];
}

const initialState: RoleState = {
  allRolesStatus: AsyncStatus.Idle,
  allRolesError: null,
  allRoles: [],
};

export const getAllRoles = createAsyncThunk<Role[], undefined, { rejectValue: ServerError }>(
  'shared/roles/getAllRoles', 
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/shared/roles/');
      const roles: Role[] = response.data.map((role: Role) => roleSchema.parse(role));
      return roles;
    }
    catch(e: any) {
      console.log(e);
      const serverError: ServerError = serverErrorSchema.parse(e.response.data);
      return rejectWithValue(serverError);
    }
  }
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getAllRoles.pending, (state) => {
        state.allRolesStatus = AsyncStatus.Loading;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.allRolesStatus = AsyncStatus.Succeeded;
        state.allRoles = action.payload;
      })
      .addCase(getAllRoles.rejected, (state) => {
        state.allRolesStatus = AsyncStatus.Failed;
      })
  }
});

export const { 
  clearState,
} = rolesSlice.actions;

export default rolesSlice.reducer;
